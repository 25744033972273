import FleetService from "@/services/FleetService.js";

export const namespaced = true;

export const state = {
  fleet: null,
  fleets: [],
  fleetOperators: [],
  fleetsByPartner: []
};

export const mutations = {
  SET_FLEET(state, fleet) {
    state.fleet = fleet;
  },
  SET_FLEETS(state, fleets) {
    state.fleets = fleets;
  },
  ADD_FLEET(state, fleet) {
    state.fleets.push(fleet);
  },
  EDIT_FLEET(state, fleet) {
    let i = 0;
    state.fleets.forEach(f => {
      i++;
      if (f.id === fleet.id) {
        state.fleets[i] = { ...state.fleets[i], ...fleet };
      }
    });
  },
  SET_FLEET_OPERATORS(state, fleetOperators) {
    state.fleetOperators = fleetOperators;
  },
  ASSIGN_FLEET_OPERATOR(state, operator) {
    state.fleetOperators.push(operator);
  },
  REMOVE_FLEET_OPERATOR(state, operatorId) {
    state.fleetOperators = state.fleetOperators.filter(fO => {
      return fO.id != operatorId;
    });
  },
  SET_FLEETS_BY_PARTNER(state, fleets) {
    state.fleetsByPartner = fleets;
  }
};

export const actions = {
  getFleet({ commit, rootState, dispatch }, id) {
    return FleetService.getFleet(rootState.user.token, id)
      .then(response => {
        commit("SET_FLEET", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getFleets({ commit, rootState, dispatch }) {
    return FleetService.getFleets(rootState.user.token)
      .then(response => {
        commit("SET_FLEETS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addFleet({ commit, rootState, dispatch }, fleet) {
    return FleetService.addFleet(rootState.user.token, fleet)
      .then(response => {
        commit("ADD_FLEET", response.data);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Add Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editFleet({ commit, rootState, dispatch }, fleet) {
    return FleetService.editFleet(rootState.user.token, fleet.id, fleet)
      .then(response => {
        commit("EDIT_FLEET", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getFleetOperators({ commit, rootState, dispatch }, id) {
    return FleetService.getFleetOperators(rootState.user.token, id)
      .then(response => {
        commit("SET_FLEET_OPERATORS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignFleetOperator(
    { commit, rootState, dispatch },
    { fleetId, operatorId }
  ) {
    return FleetService.assignFleetOperator(
      rootState.user.token,
      fleetId,
      operatorId
    )
      .then(response => {
        let getOperator = rootState.operator.operators.find(o => {
          return o.id === response.data.operatorId;
        });
        commit("ASSIGN_FLEET_OPERATOR", getOperator);
        const notification = {
          type: "success",
          message: "Assign Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Assign Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  removeFleetOperator(
    { commit, rootState, dispatch },
    { fleetId, operatorId }
  ) {
    return FleetService.removeFleetOperator(
      rootState.user.token,
      fleetId,
      operatorId
    )
      .then(response => {
        commit("REMOVE_FLEET_OPERATOR", operatorId);
        const notification = {
          type: "success",
          message: "Remove Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  setFleetsByPartner({ commit, rootState }, id) {
    FleetService.getFleetsByPartner(rootState.user.token, id)
      .then(response => {
        commit("SET_FLEETS_BY_PARTNER", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
};
