import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getColors(token) {
    return apiClient.get("/api/colors", {
      headers: {
        Authorization: token
      }
    });
  }
};
