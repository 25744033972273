import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getBarangays(token) {
    return apiClient.get("/api/barangays", {
      headers: {
        Authorization: token
      }
    });
  },
  getBarangaysByCity(token, cId) {
    return apiClient.get("/api/barangays?filter[where][cityCode]=" + cId, {
      headers: {
        Authorization: token
      }
    });
  }
};
