<template>
  <div class="box box-primary">
    <div class="box-body box-profile">
      <h4 style="text-align:center; margin-top:0;">Activation Checklist</h4>
      <ul class="checklist">
        <div class="list-partition">
          <strong>Required</strong>
          <li :class="prangkisa ? 'sucess' : 'error'">
            <span>PA/CPC</span>
            <i class="fa" :class="prangkisa ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="vehicleTitle ? 'sucess' : 'error'">
            <span>OR/CR</span>
            <i class="fa" :class="vehicleTitle ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="licence ? 'sucess' : 'error'">
            <span>Driver's Licence</span>
            <i class="fa" :class="licence ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="nbi ? 'sucess' : 'error'">
            <span>NBI</span>
            <i class="fa" :class="nbi ? 'fa-check' : 'fa-times'"></i>
          </li>
        </div>
        <div class="list-partition">
          <strong>Non Required</strong>
          <li :class="oid ? 'sucess' : 'error'">
            <span>Operator's ID</span>
            <i class="fa" :class="oid ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="tpl ? 'sucess' : 'error'">
            <span>Insurance Tpl</span>
            <i class="fa" :class="tpl ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="comprehensive ? 'sucess' : 'error'">
            <span>Insurance Comprehensive</span>
            <i class="fa" :class="comprehensive ? 'fa-check' : 'fa-times'"></i>
          </li>
          <li :class="pami ? 'sucess' : 'error'">
            <span>Insurance PAMI</span>
            <i class="fa" :class="pami ? 'fa-check' : 'fa-times'"></i>
          </li>
        </div>
      </ul>

      <!-- <span class="act-req" :class="canActivate ? 'sucess' : 'error'">{{
        canActivate ? "CAN ACTIVATE" : "CANNOT ACTIVATE"
      }}</span>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
// import EventBus from "@/event-bus.js";

export default {
  computed: {
    ...mapState("driver", ["driver", "driverVehicles", "driverOperators"]),
    assignedVehicle() {
      if (this.driverVehicles.length) {
        return this.driverVehicles.find(dv => {
          return dv.isDefault;
        });
      }
      return false;
    },
    assignedVehicleOwner() {
      if (this.driverOperators.length && this.assignedVehicle) {
        return this.driverOperators.find(dop => {
          return dop.id == this.assignedVehicle.operatorId;
        });
      }
      return false;
    },
    prangkisa() {
      if (!this.assignedVehicle) return this.assignedVehicle;
      if (!this.assignedVehicle.prangkisa) return false;

        const {
          paFile,
          cpcFile,
          certType,
          paIssuedDate,
          cpcIssuedDate,
          paValidity
        } = this.assignedVehicle.prangkisa;

      if (certType == "PA" && paFile && paIssuedDate) {
        let d = paValidity;
        if (!d) d = 90;
        const mPaIssuedDate = moment(paIssuedDate).add(d, "days");
        if (!moment().isAfter(mPaIssuedDate)) return true;
      }

      if (certType == "CPC" && cpcFile && cpcIssuedDate) {
        const mCpcIssuedDate = moment(cpcIssuedDate).add(2, "years");
        if (!moment().isAfter(mCpcIssuedDate)) return true;
      }

      return false;
    },
    vehicleTitle() {
      if (!this.assignedVehicle) return this.assignedVehicle;
      return this.assignedVehicle.vehicleTitle;
    },
    licence() {
      return this.driver.licenseId;
    },
    nbi() {
      return this.driver.nbiClearance;
    },
    oid() {
      if (!this.assignedVehicleOwner) return this.assignedVehicleOwner;
      return this.assignedVehicleOwner.idFile;
    },
    tpl() {
      if (!this.assignedVehicle) return this.assignedVehicle;
      return this.assignedVehicle.insuranceTpl;
    },
    comprehensive() {
      if (!this.assignedVehicle) return this.assignedVehicle;
      return this.assignedVehicle.insuranceComprehensive;
    },
    pami() {
      if (!this.assignedVehicle) return this.assignedVehicle;
      if (!this.assignedVehicle.pami) return false;
      return this.assignedVehicle.pami;
    },
    canActivate() {
      let canActivate =
        this.prangkisa && this.vehicleTitle && this.licence && this.nbi;
      // EventBus.$emit("can-activate", canActivate);
      return canActivate;
    }
  }
};
</script>

<style>
.checklist {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 740px;
  min-width: 360px;
}

ul.checklist::before,
ul.checklist::after {
  content: "";
  display: table;
  clear: both;
}

.list-partition {
  width: 50%;
  float: left;
  padding: 0 10px;
}

.checklist li {
  margin-bottom: 1px;
  border-radius: 0.25em;
  position: relative;
  padding-left: 10px;
  font-weight: 600;
  color: #fff;
  height: 20px;
}

.checklist li span {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding-right: 30px;
  height: inherit;
}

.checklist li span:hover {
  overflow: visible;
}

.checklist li i {
  position: absolute;
  right: 0;
  width: 20px;
  padding: 3px;
  height: 100%;
  color: #fff;
  border-radius: 0 0.24em 0.24em 0;
  background: inherit;
}

.checklist li i::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

span.act-req {
  display: block;
  max-width: 140px;
  margin: 5px auto 0;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}

.sucess {
  background: rgba(28, 154, 28, 0.5) !important;
}

.error {
  background: rgba(233, 62, 62, 0.5) !important;
}
</style>
