<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <h1>
          Tags
          <!-- <router-link class="btn btn-primary btn-sm" :to="{ name: 'tags-new' }"
          >Add</router-link
          >-->
          <button class="btn btn-primary btn-sm" @click="manage(null)">
            Add
          </button>
        </h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="col-md-6">
            <div class="box box-primary">
              <div class="box-body">
                <table id="tags_table" class="table table-hover" width="100%">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th></th>
                    </tr>
                    <!-- <tr v-for="(tag, i) in tags" :key="i">
                    <td>{{ tag.name }}</td>
                    <td>{{ tag.description }}</td>
                    <td>
                      <a
                        class="btn btn-primary"
                        :class="$can('update', 'Tag') ? 'visible' : 'hidden'"
                      >
                        <i
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                          style="pointer-events: none"
                        ></i>
                      </a>
                    </td>
                    </tr>-->
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="managing">
            <div class="box box-primary">
              <div class="box-body">
                <form id="tags_form" @submit.prevent="submit">
                  <fieldset>
                    <legend>
                      Details
                      <span
                        @click="managing = !managing"
                        class="tag-details-close"
                        >&times;</span
                      >
                    </legend>
                    <table class="table table-settings">
                      <tbody>
                        <tr>
                          <th>
                            <a
                              id="tag-driver-btn"
                              class="btn btn-primary btn-sm"
                              data-toggle="modal"
                              data-target="#tagDriver"
                              v-show="id"
                              >Partners</a
                            >
                          </th>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model="myTag.name"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Description</th>
                          <td>
                            <textarea
                              rows="5"
                              class="form-control"
                              name="description"
                              v-model="myTag.description"
                            ></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>

                  <div class="form-group">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="tagDriver"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Assign Driver</h4>
          </div>
          <div class="modal-body">
            <table
              id="tag_driver_table"
              :data-operator-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/store";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("driver/getDrivers");
    store
      .dispatch("tag/getTags")
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  },
  data() {
    return {
      dataTable: null,
      dataTableD: null,
      myTag: this.createTag(),
      managing: false,
      id: null,
      isSubmitting: false,
      isFetching: false,
      isAssigning: false
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapState("tag", ["tag", "tags", "tagDrivers"]),
    ...mapState("driver", ["drivers"])
  },
  methods: {
    manage(id) {
      if (this.isFetching) return;
      this.isFetching = true;
      this.id = id;
      this.managing = false;
      if (this.id) {
        store
          .dispatch("tag/getTagDrivers", this.id)
          .then(() => {
            return store.dispatch("tag/getTag", this.id).then(() => {
              this.myTag = { ...this.tag };
              this.dataTableD.DataTable().ajax.reload(() => {
                this.managing = true;
                this.isFetching = false;
              });
            });
          })
          .catch(() => {
            this.isFetching = false;
          });
      } else {
        this.myTag = { ...this.createTag() };
        this.managing = true;
        this.isFetching = false;
      }
    },
    submit() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      for (let t in this.myTag) {
        if (this.myTag[t] === "") {
          this.myTag[t] = null;
        }
      }
      if (this.id) {
        store
          .dispatch("tag/editTag", this.myTag)
          .then(() => {
            this.dataTable.DataTable().ajax.reload(() => {
              this.managing = false;
              this.isSubmitting = false;
            });
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      } else {
        store
          .dispatch("tag/addTag", this.myTag)
          .then(() => {
            delete this.myTag.id;
            this.dataTable.DataTable().ajax.reload(() => {
              this.managing = false;
              this.isSubmitting = false;
            });
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      }
    },
    createTag() {
      return {
        name: "",
        description: ""
      };
    },
    setupDtTable(dataTable, url) {
      let dtColumns = ["name", "description", ""];
      var vm = this; // passing this
      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);
            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };
            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "name" }, { data: "description" }, { data: null }],
        columnDefs: [
          {
            targets: [2],
            width: "100px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "Tag") ? "visible" : "hidden";
              let visibilityD = vm.$can("delete", "Tag") ? "visible" : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-tag-edit-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              // button += ` <button class="btn btn-primary ${visibilityD}"
              //              data-tag-delete-id="${row.id}">
              //             <i class="fa fa-trash"
              //                aria-hidden="true"
              //                style="pointer-events: none">
              //             </i>
              //           </button>`;
              return button;
            }
          }
        ]
      });

      $("#tags_table").on("click", "[data-tag-edit-id]", e => {
        let { tagEditId } = e.target.dataset;
        vm.manage(tagEditId);
        e.preventDefault();
      });

      $("#tags_table").on("click", "[data-tag-delete-id]", e => {
        let { tagDeleteId } = e.target.dataset;
        const answer = confirm("Are you sure?");
        if (!answer) return;

        store.dispatch("tag/deleteTag", tagDeleteId).then(() => {
          vm.dataTable.DataTable().ajax.reload();
        });
      });
    },

    setupDTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let selected = vm.tagDrivers.find(tD => {
                return tD.userId === row.userId;
              })
                ? true
                : false;
              let icon = selected ? "fa-check" : "fa-ban";
              let btn = selected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-is-selected="${selected}"
                              data-tag-driver-id="${row.userId}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#tag_driver_table").on("click", "[data-tag-driver-id]", e => {
        if (vm.isAssigning) return;
        let { isSelected, tagDriverId } = e.target.dataset;
        isSelected = JSON.parse(isSelected);
        const answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isAssigning = true;
        if (!isSelected) {
          store
            .dispatch("tag/addTagDriver", {
              id: vm.id,
              driverId: tagDriverId
            })
            .then(() => {
              vm.dataTableD.DataTable().ajax.reload(() => {
                vm.isAssigning = false;
              });
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        } else {
          store
            .dispatch("tag/removeTagDriver", {
              id: vm.id,
              driverId: tagDriverId
            })
            .then(() => {
              vm.dataTableD.DataTable().ajax.reload(() => {
                vm.isAssigning = false;
              });
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        }
      });
    }
  },
  mounted() {
    this.dataTable = $("#tags_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/tags`;
    this.setupDtTable(this.dataTable, url);

    this.dataTableD = $("#tag_driver_table");
    const driverUrl = `${process.env.VUE_APP_BE_API_URL}/api/drivers`;
    this.setupDTable(this.dataTableD, driverUrl);
  }
};
</script>

<style>
.tag-details-close {
  font-size: 1em;
  font-weight: 600;
  font-variant: small-caps;
  padding: 0 10px 0 5px;
  float: right;
  cursor: pointer;
}

.tag-details-close:hover {
  color: #dd4b39;
}
</style>
