<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <h1>
          LiveMap
        </h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              <div class="box box-primary driver-metrics">
                <div class="box-body">
                  <h2>{{ totalDriversCount }}</h2>
                  <p>Total Drivers</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box box-primary driver-metrics driver-metrics--online">
                <div class="box-body">
                  <h2>{{ onlineDrivers }}</h2>
                  <p>Online Drivers</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box box-primary driver-metrics driver-metrics--engaged">
                <div class="box-body">
                  <h2>{{ engagedDrivers }}</h2>
                  <p>Engaged Drivers</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="box box-primary driver-metrics driver-metrics--offline">
                <div class="box-body">
                  <h2>{{ offlineDrivers }}</h2>
                  <p>Offline Drivers</p>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="box box-primary">
                <div class="box-body">
                  <div id="livemap"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div ref="driver-details" class="driver-box" v-show="selectedDriverId">
          <div class="driver-img">
            <img
              :src="selectedDriver.displayPhoto ? selectedDriver.displayPhoto : dummyimg"
              :alt="selectedDriver.firstName"
            />

            <span
              class="driver-indicator"
              :class="{ 'driver-indicator--active': selectedDriver.driverStatus.isOnline }"
            >
            </span>
          </div>

          <div class="driver-info">
            <h4>
              {{ selectedDriver.firstName }} {{ selectedDriver.lastName }}
              <span>{{ selectedDriver.engageStatus }}</span>
            </h4>
            <p>{{ selectedDriver.driverStatus.status.type }}</p>
            <p>
              Lng: {{ selectedDriver.driverLocation.coordinates.x }},
              Lat: {{ selectedDriver.driverLocation.coordinates.y }}
            </p>
            <p>{{ selectedDriver.dateSend }}</p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Map, View, Feature, Overlay } from "ol";
import { XYZ, Vector as VectorSource } from "ol/source";
import { Tile, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { Style, Icon } from "ol/style";

import owtocar from "@/assets/images/owto-car.png";
import dummyimg from "@/assets/images/dummy.jpg";
import store from "@/store/store";

export default {
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch("location/getDriversLocation")
      .then(() => {
        console.log("initial driversLocation fetch")
        next();
      })
      .catch(err => console.log(err))
  },
  data() {
    return {
      dummyimg,
      // owto-office coordinates in EPSG:4326 projection
      center: [121.02583829102969, 14.637974417692504],
      carsVectorSource: new VectorSource(),
      timeInterval: null,
      selectedDriverId: ""
    };
  },
  methods: {
    getDriverIndicator({ isOnline, status }) {
      if (status.type != "IDLE" && status.type != "REQUESTING_RIDE")
        return "#3b3bde"; // engaged

      if (isOnline) return "#de3b3b"; // online
      if (!isOnline) return "gray"; // offline
    },
    refreshDriversLocation() {
      const carFeatures = this.driversLocation.map(dl => {
        const loc = dl.driverLocation;
        const carFeature = new Feature(
          new Point(fromLonLat([loc.coordinates.x, loc.coordinates.y]))
        );
        const carStyle = new Style({
          image: new Icon({
            src: owtocar,
            rotation: loc.bearing,
            scale: 0.8,
            rotateWithView: true,
            color: this.getDriverIndicator(dl.driverStatus)
          })
        });

        carFeature.setId(dl.userId);
        carFeature.setStyle(carStyle);

        return carFeature;
      });

      this.carsVectorSource.clear();
      this.carsVectorSource.addFeatures(carFeatures);
    }
  },
  mounted() {
    const map = new Map({ target: "livemap" });
    const view = new View({
      prjection: "EPSG:3857", // default projection
      // zoom: 19,
      zoom: 10,
      // fromLonLat transforms EPSG:4326 to EPSG:3857(default 2nd parameter)
      // center coordinates order: [lon, lat]
      center: fromLonLat(this.center),
      maxZoom: 22, // 23>= causes error in google map source
      minZoom: 6
    });

    const baseLayer = new Tile({
      // Google Map Roadmap tiles source
      source: new XYZ({ url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}" })
    });

    const carsVectorLayer = new VectorLayer({ source: this.carsVectorSource });

    const overlay = new Overlay({
      element: this.$refs["driver-details"],
      offset: [10, 0],
      positioning: 'bottom-left'
    });

    map.addLayer(baseLayer);
    map.addLayer(carsVectorLayer);
    map.addOverlay(overlay);
    map.setView(view);

    this.refreshDriversLocation();

    this.timeInterval = setInterval(
      () => store.dispatch("location/getDriversLocation"),
      10000
    );

    const vm = this;
    map.on('click', function(evt) {
      const driver = map.forEachFeatureAtPixel(evt.pixel, feature => feature);

      if (driver) {
        const routeData = vm.$router.resolve({
          name: "partners-edit",
          params: { id: driver.getId() }
        });

        window.open(routeData.href, '_blank');
      }
    });

    map.on("pointermove", function(evt) {
      const driver = map.forEachFeatureAtPixel(evt.pixel, feature => feature);

      if (driver) {
        overlay.setPosition(evt.coordinate);
        vm.selectedDriverId = driver.getId();
      } else {
        vm.selectedDriverId = "";
      }
    });
  },
  computed: {
    ...mapState("location", ["driversLocation"]),
    totalDriversCount() {
      return this.driversLocation.length;
    },
    onlineDrivers() {
      return this.driversLocation.filter(dl => {
        if (dl.driverStatus.isOnline) return true;
      }).length
    },
    engagedDrivers() {
      return this.driversLocation.filter(dl => {
        const { isOnline, status } = dl.driverStatus;
        if (
          (status.type != "IDLE" && status.type != "REQUESTING_RIDE")
        ) return true
      }).length;
    },
    offlineDrivers() {
      return this.driversLocation.filter(dl => {
        if (!dl.driverStatus.isOnline) return true;
      }).length
    },

    selectedDriver() {
      const driverLocationModel = {
        engageStatus: "",
        dateSend: "",
        driverLocation: {
          coordinates: {},
          sentAt: ""
        },
        driverStatus: {
          status: { type: "" },
          isOnline: false
        }
      }

      if (!this.selectedDriverId) return driverLocationModel;

      let driver = this.driversLocation.find(dl => {
        if (dl.userId == this.selectedDriverId) return dl;
      });
      const statusType = driver.driverStatus.status.type;
      let engageStatus = "";
      const sentAt = new Date(driver.driverLocation.sentAt);

      if (statusType != "IDLE" && statusType != "REQUESTING_RIDE")
        engageStatus = "(engaged)";

      driver.engageStatus = engageStatus;
      driver.dateSend = sentAt.toLocaleString(["en-US"], {
        dateStyle: "full",
        timeStyle: "medium"
      });

      return driver || driverLocationModel;
    }
  },
  watch: {
    driversLocation(newReq, oldReq) {
      this.refreshDriversLocation();
    }
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  }
};
</script>

<style>
.driver-metrics {
  text-align: center;
  border-top-color: #000 !important;
  color: #000;
}

.driver-metrics--online {
  border-top-color: #de3b3b !important;
  color: #de3b3b;
}

.driver-metrics--engaged {
  border-top-color: #3b3bde !important;
  color: #3b3bde;
}

.driver-metrics--offline {
  border-top-color: gray !important;
  color: gray;
}

.driver-metrics h2 {
  font-size: 32px;
  font-weight: 900;
  margin: 10px 0 5px;
}

.driver-metrics p {
  font-size: 16px;
  letter-spacing: 1px;
  color: initial;
}

#livemap { height: 700px; }

.fms-btn { margin-right: 2em; }

.driver-box {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background: #282828;
  color: #fff;
}

.driver-img { position: relative; margin-right: 1em; }

.driver-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.driver-indicator {
  display: inline-block;
  border-radius: 50%;
  background: gray;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 0px;
  bottom: 0px;
  border: #282828 2px solid;
}

.driver-indicator--active { background: #5bd539; }

.driver-info * {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: monospace;
  letter-spacing: 0.5px;
}

.driver-info h4 { margin-bottom: 2px; }

.driver-info p { font-size: 10px; }
</style>
