<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li
        v-for="(rTab, index) in riderTabs"
        :key="index"
        @click="selectedRiderTab = rTab[0]"
        :class="{ active: selectedRiderTab === rTab[0] }"
        data-toggle="tab"
      >
        <a v-if="$can('read', rTab[1])">{{ rTab[0] }}</a>
      </li>
    </ul>

    <div class="tab-content">
      <ProfileTab
        :class="{ active: selectedRiderTab == 'Profile' }"
        view="Rider"
        :id="id"
      />

      <Employer :class="{ active: selectedRiderTab == 'Employers' }" />

      <TripHistory
        :class="{ active: selectedRiderTab == 'Trip History' }"
        :id="id"
        view="Rider"
      />

      <Card
        :class="{ active: selectedRiderTab == 'Card' }"
        :id="id"
        view="Riders"
      />
    </div>
  </div>
</template>

<script>
import ProfileTab from "./tabs/ProfileTab.vue";
import Employer from "./tabs/Employer.vue";
import TripHistory from "./tabs/TripHistory.vue";
import Card from "./tabs/Card.vue";

export default {
  components: {
    ProfileTab,
    Employer,
    TripHistory,
    Card
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      riderTabs: [
        ["Profile", "Rider"],
        ["Employers", "RiderEmployer"],
        ["Trip History", "RiderTripHistory"],
        ["Card", "RiderCard"]
      ],
      selectedRiderTab: "Profile"
    };
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
