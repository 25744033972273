import ColorService from "@/services/ColorService.js";

export const namespaced = true;

export const state = {
  color: {},
  colors: []
};

export const mutations = {
  SET_COLORS(state, colors) {
    state.colors = colors;
  }
};

export const actions = {
  getColors({ commit, rootState }) {
    ColorService.getColors(rootState.user.token).then(response => {
      commit("SET_COLORS", response.data);
    });
  }
};
