<template>
  <div class="tab-pane" id="accounts">
    <form id="topup_form" method="POST" @submit.prevent="submit">
      <fieldset>
        <legend>TopUp Details</legend>
        <table class="table table-settings">
          <tbody>
            <tr>
              <th>Top-Up Balance</th>
              <th>{{ topUp.balance }}</th>
            </tr>
            <tr>
              <th>Top-Up Denominations</th>
              <td class="input-group">
                <select class="form-control" v-model="selectedValue">
                  <option disabled value>Please select denomination</option>
                  <option v-for="(d, i) in denominations" :key="i" :value="d">
                    {{ d }}
                  </option>
                </select>
                <span class="input-group-btn">
                  <button @click="add" type="button" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                    Add TopUp
                  </button>
                </span>
              </td>
            </tr>
            <tr>
              <th>Additional Top-Up</th>
              <th>{{ newTopUp.amount.toFixed(2) }}</th>
            </tr>
            <tr>
              <th>Reference Number</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="newTopUp.refNo"
                />
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <select
                  class="form-control"
                  v-model="newTopUp.description"
                  required
                >
                  <option disabled value>Please select description</option>
                  <option v-for="(d, i) in descriptions" :key="i" :value="d">
                    {{ d }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <button
        v-if="$can('update', 'TopUp')"
        type="submit"
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selectedValue: "",
      newTopUp: this.createNewTopUp(),
      denominations: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
      descriptions: ["OWTO Office Top-ups"],
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("balance", ["topUp"])
  },
  methods: {
    createNewTopUp() {
      return {
        amount: 0,
        refNo: "",
        description: ""
      };
    },
    add() {
      if (!this.selectedValue) return;
      this.newTopUp.amount += this.selectedValue;
      this.selectedValue = "";
    },
    submit() {
      if (this.isSubmitting) return; // spam prevention
      if (!this.newTopUp.amount) return; // validation

      const answer = window.confirm("are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      let note = {
        entity: "partner",
        userId: this.id,
        note: `${this.newTopUp.amount.toFixed(
          2
        )} PHP is sucessfully added to top-up`
      };

      this.$store
        .dispatch("balance/addTopUp", {
          id: this.id,
          topup: this.newTopUp,
          note: note
        })
        .then(() => {
          console.log("reload notes");
          $("#notes_table")
            .DataTable()
            .ajax.reload(() => {
              $("#partner_transaction_table")
                .DataTable()
                .ajax.reload(() => {
                  this.isSubmitting = false;
                  this.newTopUp = this.createNewTopUp();
                });
            });
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style></style>
