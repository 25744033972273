<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <router-link :to="{ name: 'riders' }">
          <i class="fa fa-caret-left"></i>
          Back
        </router-link>
        <h1>Rider</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div
            v-if="messages && messages.success"
            class="callout callout-success"
          >
            <p>{{ messages.success }}</p>
          </div>

          <div
            v-else-if="messages && messages.error"
            class="callout callout-danger"
          >
            <p>{{ messages.error }}</p>
          </div>

          <form
            id="schedule_form"
            method="POST"
            :data-rider-id="id"
            @submit.prevent
          >
            <div class="row">
              <div class="col-md-3">
                <ProfileBox view="Rider" :module="rider" />
              </div>

              <div class="col-md-9">
                <RiderTab :id="id" />

                <Note view="Rider" :id="id" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="assignEmployerModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Assign Employer</h4>
          </div>
          <div class="modal-body">
            <table
              id="rider_assign_table"
              :data-rider-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileBox from "@/components/ProfileBox.vue";
import RiderTab from "@/components/RiderTab.vue";
import Note from "@/components/Note.vue";
import store from "@/store/store";
import { mapState } from "vuex";

export default {
  components: {
    ProfileBox,
    RiderTab,
    Note
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      messages: {
        success: null,
        error: null
      },
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("rider", ["rider", "riderEmployers"]),
    ...mapState("user", ["token"]),
    ...mapState("employer", ["employers"])
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    console.log("loading...");
    store.dispatch("rider/getRiderEmployers", routeTo.params.id);
    store.dispatch("employer/getEmployers");
    store
      .dispatch("rider/getRider", routeTo.params.id)
      .then(response => {
        return store.dispatch("region/getRegions").then(() => {
          if (!response.state) return next();
          return store
            .dispatch(
              "province/getProvincesByRegion",
              response.state.slice(0, 2)
            )
            .then(() => {
              if (!response.province) return next();
              return store
                .dispatch(
                  "city/getCitiesByProvince",
                  response.province.slice(0, 4)
                )
                .then(() => {
                  if (!response.city) return next();
                  return store
                    .dispatch(
                      "barangay/getBarangaysByCity",
                      response.city.slice(0, 6)
                    )
                    .then(() => {
                      next();
                      console.log("done loading...");
                    });
                });
            });
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["repLastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.length,
              recordsFiltered: response.length,
              data: response
            };
            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "repLastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.repLastName}, ${
                row.repFirstName
              } ${row.repMiddleName || ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let isSelected = vm.riderEmployers.find(rE => rE.id === row.id)
                ? true
                : false;
              let icon = isSelected ? "fa-check" : "fa-ban";
              let btn = isSelected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-is-selected="${isSelected}"
                              data-employer-id="${row.id}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#rider_assign_table").on("click", "[data-employer-id]", e => {
        if (vm.isSubmitting) return;
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        let { isSelected, employerId } = e.target.dataset;
        isSelected = JSON.parse(isSelected);

        if (!isSelected) {
          store
            .dispatch("rider/assignRiderEmployer", {
              riderId: vm.id,
              employerId: employerId
            })
            .then(() => {
              vm.dataTable.DataTable().ajax.reload(() => {
                vm.isSubmitting = false;
              });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        } else {
          store
            .dispatch("rider/deleteRiderEmployer", {
              riderId: vm.id,
              employerId: employerId
            })
            .then(() => {
              vm.dataTable.DataTable().ajax.reload(() => {
                vm.isSubmitting = false;
              });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      });
    }
  },
  mounted() {
    this.dataTable = $("#rider_assign_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/employers`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
