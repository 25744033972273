import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getPois(token) {
    return apiClient.get("/api/poi", {
      headers: {
        Authorization: token
      }
    });
  },

  getPoi(token, id) {
    return apiClient.get("/api/poi/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  editPoi(token, id, poi) {
    return apiClient.patch("/api/poi/" + id, poi, {
      headers: {
        Authorization: token
      }
    });
  },

  addPoi(token, poi) {
    return apiClient.post("/api/poi", poi, {
      headers: {
        Authorization: token
      }
    });
  }
};
