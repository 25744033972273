<template>
  <div class="notification-bar callout" :class="typeTextClass">
    <p>
      {{ notification.message }}
      <span title="close notification" @click="remove(notification)"
        >&times;</span
      >
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  computed: {
    typeTextClass() {
      return this.notification.type === "success"
        ? `callout-${this.notification.type}`
        : "callout-danger";
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000);
  },
  methods: mapActions("notification", ["remove"])
};
</script>

<style scoped>
.notification-bar {
  margin: 1em 0 0;
  padding-right: 15px;
}

.notification-bar span {
  margin-left: 15px;
  cursor: pointer;
}
</style>
