<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'disbursements' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Disbursement Reports</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table
              id="disbursement_report_table"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Booking ID</th>
                  <th>Booking Date</th>
                  <th>Mode of Payment</th>
                  <th>Rider Credits</th>
                  <th>Rider Credits Status</th>
                  <th>Promo</th>
                  <th>Promo Status</th>
                  <th>Card Fare</th>
                  <th>Card Fare Status</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>

    <select
      @change="loadUrl"
      id="type"
      class="form-control input-sm hide"
      v-model="selectedType"
    >
      <option value>Select Type</option>
      <option v-for="(t, i) in types" :key="i" :value="t">{{ t }}</option>
    </select>

    <!-- <select
      @change="loadUrl"
      id="mode"
      class="form-control input-sm"
      v-model="selectedMode"
    >
      <option value>Select Mode of Payment</option>
      <option v-for="(m, i) in modes" :key="i" :value="m">{{ m }}</option>
    </select>
    <select
      @change="loadUrl"
      id="credit"
      class="form-control input-sm"
      v-model="selectedCredit"
    >
      <option value>Select Credit</option>
      <option v-for="(c, i) in credits" :key="i" :value="c">{{ c }}</option>
    </select>
    <select
      @change="loadUrl"
      id="promo"
      class="form-control input-sm"
      v-model="selectedPromo"
    >
      <option value>Select Promo</option>
      <option v-for="(p, i) in promos" :key="i" :value="p">{{ p }}</option>
    </select>-->
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      isFetching: false,
      selectedMode: "",
      selectedCredit: "",
      selectedPromo: "",
      selectedType: "",
      modes: ["CASH", "CARD"],
      credits: ["0", "10", "100"],
      promos: ["0", "10", "100"],
      types: ["CARD", "RIDER_CREDIT", "PROMO"],
      url: `${process.env.VUE_APP_BE_API_URL}/api/disbursement-tx/reports/${
        this.id
      }`
    };
  },
  computed: {
    oUrl() {
      let q = 0;
      q =
        Boolean(this.selectedMode) +
        Boolean(this.selectedCredit) +
        Boolean(this.selectedPromo);
      let m = this.selectedMode
        ? `filter[where][mode_of_payment]=${this.selectedMode}`
        : "";
      let c = this.selectedCredit
        ? `filter[where][rider_credits_to_disburse]=${this.selectedCredit}`
        : "";
      let p = this.selectedPromo
        ? `filter[where][promo_to_disburse]=${this.selectedPromo}`
        : "";

      return this.url.concat(
        q ? "?" : "",
        m,
        q == 2 ? "&" : "",
        c,
        q == 3 ? "&" : "",
        p
      );
    },
    ...mapState("user", ["token"])
  },
  methods: {
    loadUrl() {
      let t = this.selectedType
        ? `?filter[where][type]=${this.selectedType}`
        : "";
      this.url = `${
        process.env.VUE_APP_BE_API_URL
      }/api/disbursement-tx/reports/${this.id}${t}`;

      this.dataTable
        .DataTable()
        .ajax.url(this.url)
        .load();
    },
    setupDtTable(dataTable) {
      let dtColumns = [
        "",
        "booking_id",
        "booking_date",
        "mode_of_payment",
        "rider_credits_to_disburse",
        "credits_disbursement_status",
        "promo_to_disburse",
        "promo_disbursement_status",
        "card_fare_to_disburse",
        "card_disbursement_status"
      ];
      var vm = this; // passing this

      $(dataTable).DataTable({
        dom:
          "<'row'<'col-sm-4'l><'col-sm-8'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-4'i><'col-sm-8'p>>",
        initComplete: function() {
          $("#type").removeClass("hide");
          $("#disbursement_report_table_wrapper .dataTables_filter").append(
            type
          );
        },
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: vm.url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[2, "asc"]],

        columns: [
          {
            className: "details-control text-center",
            orderable: false,
            data: null,
            defaultContent: ""
          },
          { data: "booking_id" },
          { data: "booking_date" },
          { data: "mode_of_payment" },
          { data: "rider_credits_to_disburse" },
          { data: "credits_disbursement_status" },
          { data: "promo_to_disburse" },
          { data: "promo_disbursement_status" },
          { data: "card_fare_to_disburse" },
          { data: "card_disbursement_status" }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: false,
            width: "20",
            render: (type, data, row, meta) => {
              return `<i class="fa fa-plus" style="pointer-events:none"></i>`;
            }
          },
          {
            targets: [1],
            orderable: false
          },
          {
            targets: [2],
            orderable: true,
            render: (type, data, row, meta) => {
              return moment(row.booking_date).format("LLL");
            }
          },
          {
            targets: [3],
            orderable: false
          },
          {
            targets: [4],
            orderable: false
          },
          {
            targets: [5],
            orderable: false
          },
          {
            targets: [6],
            orderable: false
          },
          {
            targets: [7],
            orderable: false
          },
          {
            targets: [8],
            orderable: false
          },
          {
            targets: [9],
            orderable: false
          }
        ]
      });

      $(dataTable)
        .find("tbody")
        .on("click", "td.details-control", e => {
          let tr = $(e.target).closest("tr");
          let row = vm.dataTable.DataTable().row(tr);
          let child = $(e.target).children();

          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass("shown");
            child.attr("class", "fa fa-plus");
          } else {
            // Open this row
            //row.child(format(scheduleId)[0]).show();
            row.child(format(row.data())).show();
            tr.addClass("shown");
            child.attr("class", "fa fa-minus");
          }
        });

      function format(d) {
        let div = $("<div/>")
          .addClass("loading")
          .text("Loading...");
        div.html(`
          <div class="row">
            <div class="col-md-6">
              <p>Booking ID: <strong>${d.booking_id}</strong></strong></p>
              <p>Booking Date: <strong>${
                d.booking_date ? moment(d.booking_date).format("LLLL") : ""
              }</strong></p>
              <p>Rider ID: <strong>${d.rider_id}</strong></p>
              <p>Mode of Payment: <strong>${d.mode_of_payment}</strong></p>
              <p>Estimated Fare: <strong>${d.estimated_fare}</strong></p>
              <p>Gov Discount: <strong>${d.gov_discount}</strong></p>
              <p>Fare after Gov Discount: <strong>${
                d.fare_after_gov_dc
              }</strong></p>
              <p>Rider Credits: <strong>${d.rider_credit}</strong></p>
              <p>Promo Code: <strong>${d.promo_code}</strong></p>
              <p>Promo Discount: <strong>${d.promo_discount}</strong></p>
              <p>Rider Credits for Disbursement: <strong>${
                d.rider_credits_to_disburse
              }</strong></p>
              <p>Rider Credits Disbursement Status: <strong>${
                d.credits_disbursement_status
              }</strong></p>
            </div>

            <div class="col-md-6">
              <p>Rider Credits Disbursement Date: <strong>${
                d.credits_disbursement_date
                  ? moment(d.credits_disbursement_date).format("LLLL")
                  : ""
              }</strong></p>
              <p>Rider Credits Processed By: <strong>${
                d.credits_processed_by
              }</strong></p>
              <p>Promo for Disbursement: <strong>${
                d.promo_to_disburse
              }</strong></p>
              <p>Promo Disbursement Status: <strong>${
                d.promo_disbursement_status
              }</strong></p>
              <p>Promo Processed By: <strong>${
                d.promo_processed_by
              }</strong></p>
              <p>Promo Disbursement Date: <strong>${
                d.promo_disbursement_date
                  ? moment(d.promo_disbursement_date).format("LLLL")
                  : ""
              }</strong></p>
              <p>Card Fare for Disbursement: <strong>${
                d.card_fare_to_disburse
              }</strong></p>
              <p>Card Disbursement Status: <strong>${
                d.card_disbursement_status
              }</strong></p>
              <p>Card Disbursement Date: <strong>${
                d.card_disbursement_date
                  ? moment(d.card_disbursement_date).format("LLLL")
                  : ""
              }</strong></p>
              <p>Card Processed By: <strong>${d.card_processed_by}</strong></p>
              <p>Date Created: <strong>${
                d.date_created ? moment(d.date_created).format("LLLL") : ""
              }</strong></p>
            </div>
          </div>
        `);
        return div;
      }
    }
  },
  mounted() {
    this.dataTable = $("#disbursement_report_table");
    this.setupDtTable(this.dataTable);
  }
};
</script>

<style>
#disbursement_report_table tr {
  height: 45px !important;
}

.hide {
  visibility: hidden;
}
</style>
