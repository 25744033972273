import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getOperators(token) {
    // fetch all operators
    return apiClient.get("/api/operators", {
      headers: {
        Authorization: token
      }
    });
  },

  getOperator(token, id) {
    // fetch operator by id
    return apiClient.get("/api/operators/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  getOperatorDrivers(token, id) {
    // fetch operator by id
    return apiClient.get(
      "/api/operators/" +
        id +
        "/drivers?start=0&length=10&filter[order]=lastName ASC",
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  assignOperatorDriver(token, operatorId, driverId) {
    // assign operator driver
    return apiClient.post(
      `/api/operators/${operatorId}/drivers`,
      {
        driverId: driverId
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  removeOperatorDriver(token, operatorId, driverId) {
    // remove operator driver
    return apiClient.delete(
      `/api/operators/${operatorId}/drivers/${driverId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  addOperator(token, username, operator) {
    // add operator
    return apiClient.post("/api/operators", operator, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  editOperator(token, username, id, operator) {
    // update operator
    console.log(id);
    return apiClient.patch("/api/operators/" + id, operator, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  addOperatorVehicle(token, username, operatorId, vehicleId) {
    // add operator vehicle
    return apiClient.post(
      "/api/operators/" + operatorId + "/vehicles",
      { vehicleId: vehicleId },
      {
        headers: {
          Authorization: token,
          "X-Owto-Request-By": username
        }
      }
    );
  },

  getOperatorFleets(token, id) {
    // fetch operator fleets
    return apiClient.get(`/api/operators/${id}/fleets`, {
      headers: {
        Authorization: token
      }
    });
  },

  removeOperatorFleet(token, operatorId, fleetId) {
    // remove operator fleet
    return apiClient.delete(`/api/operators/${operatorId}/fleets/${fleetId}`, {
      headers: {
        Authorization: token
      }
    });
  },

  assignOperatorFleet(token, operatorId, fleetId) {
    // assign operator fleet
    return apiClient.post(
      `/api/operators/${operatorId}/fleets`,
      { fleetId: fleetId },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
};
