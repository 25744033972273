import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_USER_API_URL, // dedicated url for users-api
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getDriversLocation(date) {
    return apiClient.get("api/locations");
  }
};
