import GcashService from "@/services/GcashService.js";

export const namespaced = true;

export const state = {
  gcashCards: []
};

export const mutations = {
  SET_GCASH_CARDS(state, gcashCards) {
    state.gcashCards = gcashCards;
  },
  ASSIGN_GCASH_CARD(state, gcashCard) {
    console.log(gcashCard.id);
    let ctr = 0;
    state.gcashCards.forEach(gc => {
      if (gc.id === gcashCard.id) {
        state.gcashCards[ctr].driverId = gcashCard.driverId;
      }
      ctr++;
    });
  }
};

export const actions = {
  getGcashCards({ commit, rootState, dispatch }) {
    return GcashService.getGcashCards(rootState.user.token)
      .then(response => {
        commit("SET_GCASH_CARDS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignGcashCard({ commit, rootState, dispatch }, { gcashId, driverId }) {
    return GcashService.assignGcashCard(
      rootState.user.token,
      gcashId,
      driverId,
      rootState.user.user.name
    )
      .then(response => {
        console.log(response.data);
        commit("ASSIGN_GCASH_CARD", response.data);
        const notification = {
          type: "success",
          message: "Assigning Success"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Assign Failed, "
        };

        notification.message += error.message;

        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
