<template>
  <div class="tab-pane" id="personal">
    <fieldset>
      <legend>Personal Details</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>First Name</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="firstName"
                v-model.trim="module.firstName"
                required="required"
              />
            </td>
          </tr>
          <tr>
            <th>Middle Name</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="middleName"
                v-model.trim="module.middleName"
              />
            </td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="lastName"
                v-model.trim="module.lastName"
                required="required"
              />
            </td>
          </tr>
          <template v-if="view != 'Rider'">
            <tr>
              <th>Birth Date</th>
              <td>
                <input
                  type="date"
                  class="form-control"
                  name="dob"
                  max="9999-12-31"
                  v-model="module.dob"
                />
              </td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>
                <select
                  class="form-control"
                  name="gender"
                  v-model="module.gender"
                >
                  <option
                    v-for="(gender, index) in genders"
                    :key="index"
                    :value="gender"
                    >{{ gender }}</option
                  >
                </select>
              </td>
            </tr>
            <tr>
              <th>Civil Status</th>
              <td>
                <select
                  class="form-control"
                  name="civilStatus"
                  v-model="module.civilStatus"
                >
                  <option
                    v-for="(civilStatus, index) in civilStatuses"
                    :key="index"
                    :value="civilStatus"
                    >{{ civilStatus }}</option
                  >
                </select>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    view: String,
    permission: Boolean
  },
  computed: {
    ...mapState(["genders", "civilStatuses"])
  },
  updated() {
    console.log(this.module.firstName);
  },
  mounted() {
    $("#personal :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
