<template>
  <div class="tab-pane" id="employers">
    <fieldset class="form-group">
      <legend>
        Employer(s)
        <button
          v-if="$can('create', 'PartnerOperator')"
          type="button"
          class="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#assignEmployerModal"
        >
          <i class="fa fa-plus"></i>
        </button>
      </legend>

      <table id="rider_employer_table" class="table table-hover" width="100%">
        <tbody>
          <tr v-for="(rE, index) in riderEmployers" :key="index">
            <th>
              {{ rE.repLastName }}, {{ rE.repFirstName }}
              {{ rE.repMiddleName }}
            </th>
            <th></th>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("rider", ["riderEmployers"])
  }
};
</script>

<style></style>
