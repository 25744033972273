<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Schedules
        <a
          @click.prevent="routeGo"
          v-if="$can('create', 'Schedule')"
          class="btn btn-primary btn-sm"
          >Add New</a
        >
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="form-group">
          <ul class="list-inline">
            <li>
              <a
                href="#"
                @click.prevent="filter('PENDING')"
                :class="selectedTab !== 'PENDING' ? 'text-muted' : ''"
                >PENDING</a
              >
            </li>
            <li>
              <a
                href="#"
                @click.prevent="filter('OTHERS')"
                :class="selectedTab !== 'OTHERS' ? 'text-muted' : ''"
                >OTHERS</a
              >
            </li>
          </ul>
        </div>

        <div class="box box-primary">
          <div class="box-body">
            <table id="schedule_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th></th>
                  <th>Schedule Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedTab: "PENDING",
      dataTable: null,
      baseUrl: process.env.VUE_APP_BE_API_URL,
      url: `${
        process.env.VUE_APP_BE_API_URL
      }/api/schedules?filter[where][status]=PENDING`,
      isFetching: false,
      isSubmitting: false,
      isRouting: false
    };
  },
  methods: {
    filter(field) {
      this.selectedTab = field;
      if (field === "PENDING") {
        this.url = this.baseUrl.concat(
          "/api/schedules?filter[where][status]=PENDING"
        );
        this.dataTable
          .DataTable()
          .ajax.url(this.url)
          .load();
      } else {
        this.url = this.baseUrl.concat(
          "/api/schedules?filter[where][status][neq]=PENDING"
        );
        this.dataTable
          .DataTable()
          .ajax.url(this.url)
          .load();
      }
    },
    setupDtTable(dataTable) {
      let dtColumns = ["", "scheduleDate", "status", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: false,
        processing: true,
        serverSide: true,
        ajax: {
          url: vm.url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[1, "asc"]],

        columns: [
          {
            className: "details-control text-center",
            orderable: false,
            data: null,
            defaultContent: ""
          },
          { data: "scheduleDate" },
          { data: "status" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: false,
            width: "20",
            render: (type, data, row, meta) => {
              return `<i class="fa fa-plus" style="pointer-events:none"></i>`;
            }
          },
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `<p>${row.name}</p>
                <p class="small text-muted">${row.description}</p>`;
            }
          },
          {
            targets: [1],
            orderable: true,
            render: (type, data, row, meta) => {
              return moment(row.scheduleDate).format("LLL");
            }
          },
          {
            targets: [2],
            width: "100px",
            orderable: false
          },
          {
            targets: [3],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "Schedule")
                ? "visible"
                : "hidden";
              let dVisibility = vm.$can("delete", "Schedule")
                ? "visible"
                : "hidden";

              if (row.status == "PENDING") {
                button = `<a class="btn btn-primary ${visibility}"
                             data-schedule-edit-id="${row.id}">
                            <i class="fa
                               fa-pencil-square-o"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </a>`;

                button += `<button class="btn btn-primary ${dVisibility}"
                                   type="button"
                                   data-toggle="modal"
                                   data-target="#myModal"
                                   data-schedule-cancel-id="${row.id}">
                              <i class="fa fa-ban"
                                 aria-hidden="true"
                                 style="pointer-events: none">
                              </i>
                            </button>`;

                button += `<button class="btn btn-primary"
                                   type="button"
                                   data-toggle="modal"
                                   data-target="#myModal"
                                   data-schedule-delete-id="${row.id}">
                             <i class="fa fa-trash-o"
                                aria-hidden="true"
                                style="pointer-events: none">
                             </i>
                           </button>`;
              } else {
                button = `<button class="btn btn-primary" style="visibility:hidden">
                            <i class="fa fa-pencil-square-o"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;
              }
              return button;
            }
          }
        ]
      });

      $("#schedule_table").on("click", "[data-schedule-edit-id]", e => {
        if (vm.isSubmitting) return;
        vm.isSubmitting = true;
        let { scheduleEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "schedules-edit",
            params: { id: scheduleEditId }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });

      $("#schedule_table").on("click", "[data-schedule-cancel-id]", e => {
        if (vm.isSubmitting) return;

        let { scheduleCancelId } = e.target.dataset;
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        vm.$store
          .dispatch("schedule/cancelSchedule", scheduleCancelId)
          .then(() => {
            $(dataTable)
              .DataTable()
              .ajax.reload(() => {
                vm.isSubmitting = false;
              });
          })
          .catch(() => {
            vm.isSubmitting = false;
          });
      });

      $("#schedule_table").on("click", "[data-schedule-delete-id]", e => {
        if (vm.isSubmitting) return;
        let { scheduleDeleteId } = e.target.dataset;
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        vm.$store
          .dispatch("schedule/deleteSchedule", scheduleDeleteId)
          .then(() => {
            $(dataTable)
              .DataTable()
              .ajax.reload(() => {
                vm.isSubmitting = false;
              });
          })
          .catch(() => {
            vm.isSubmitting = false;
          });
      });

      $(dataTable)
        .find("tbody")
        .on("click", "td.details-control", e => {
          if (vm.isFetching) return;
          let tr = $(e.target).closest("tr");
          let row = vm.dataTable.DataTable().row(tr);
          let child = $(e.target).children();
          let { scheduleId } = event.target.dataset;

          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass("shown");
            child.attr("class", "fa fa-plus");
          } else {
            // Open this row
            //row.child(format(scheduleId)[0]).show();
            vm.isFetching = true;
            row.child(format(row.data())).show();
            tr.addClass("shown");
            child.attr("class", "fa fa-minus");
          }
        });

      function format(d) {
        let div = $("<div/>")
          .addClass("loading")
          .text("Loading...");
        axios
          .get(
            `${vm.baseUrl}/api/schedules/${
              d.id
            }?filter[include]=riders&filter[include]=routes`
          )
          .then(response => {
            let routeName = response.data.routes
              ? response.data.routes.name
              : "";
            let routeDesc = response.data.routes
              ? response.data.routes.description
              : "";
            return axios
              .get(`${vm.baseUrl}/api/drivers/${d.driverId}`, {
                headers: {
                  Authorization: vm.token
                }
              })
              .then(driver => {
                div.html(`<div style="max-width:750px;">
                          <p>Driver: <strong>${
                            driver.data.lastName
                              ? [
                                  driver.data.firstName,
                                  driver.data.middleName,
                                  driver.data.lastName,
                                  driver.data.suffix
                                ].join(" ")
                              : ""
                          }</strong></p>
                          <p>Ensured Passengers: <span><strong>${
                            response.data.riders.length
                          }</strong> / 6</span></p>
                        </div>

                        <div>
                          <p>Price per head: 
                            <strong>PHP ${response.data.fixedFare}</strong>
                            <span class="small">/ head</span>
                          </p>
                          <p>Total fare: 
                            <strong>PHP ${response.data.totalFare}</strong>
                          </p>
                        </div>

                        <div>
                          <p>Route: 
                            <strong>${routeName}</strong>
                            <span class="small text-muted">
                              ${routeDesc}
                            </span>
                          </p>
                          
                          <p>Drop Offs: </p>
                          <ul class="list-unstyled">
                            ${
                              response.data.dropOffs.length > 0
                                ? response.data.dropOffs
                                    .map(
                                      dropOff =>
                                        `<li>
                                          <i class="fa fa-map-pin fa-fw"
                                             aria-hidden="true">
                                          </i>${dropOff.label}
                                         </li>`
                                    )
                                    .join("")
                                : `<li class="text-muted">No Routes</li>`
                            }
                          </ul>
                        </div>`);
                vm.isFetching = false;
              });
          })
          .catch(() => {
            vm.isFetching = false;
          });
        return div;
      }
    },
    routeGo() {
      if (this.isRouting) return;
      this.isRouting = true;
      this.$router.push({ name: "schedules-new" }, null, () => {
        this.isRouting = false;
      });
    }
  },
  mounted() {
    this.dataTable = $("#schedule_table");
    this.setupDtTable(this.dataTable);
  },
  computed: {
    ...mapState("user", ["token", "user"])
  }
};
</script>

<style></style>
