import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_USER_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getPrangkisasByOperator(token, id) {
    // get prangkisas by operator
    return apiClient.get(`${process.env.VUE_APP_BE_API_URL}/api/prangkisa?filter[where][operatorId]=${id}`, {
      headers: {
        Authorization: token
      }
    });
  },

  getPrangkisa(token, id) {
    // get prangkisa
    return apiClient.get("/api/prangkisa/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  addPrangkisa(token, username, prangkisa) {
    // add prangkisa
    return apiClient.post("/api/prangkisa", prangkisa, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  editPrangkisa(token, username, id, prangkisa) {
    // edit prangkisa
    return apiClient.patch("/api/prangkisa/" + id, prangkisa, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  getCpcs(token, prangkisaId) {
    // get prangkisa's cpc
    return apiClient.get(`/api/prangkisa/${prangkisaId}/cpc`, {
      headers: { Authorization: token }
    });
  },

  addCpc(token, prangkisaId, cpc) {
    // add prangkisa's cpc
    return apiClient.post(`/api/prangkisa/${prangkisaId}/cpc`, cpc, {
      headers: { Authorization: token }
    });
  },
};
