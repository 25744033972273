<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'routes' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>New Route</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form @submit.prevent="addRoute" method="POST">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Name</th>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    name="name"
                    v-model.trim="route.name"
                    required="required"
                  />
                </td>
              </tr>
              <tr>
                <th>Description</th>
                <td>
                  <textarea
                    required="required"
                    class="form-control"
                    name="description"
                    v-model.trim="route.description"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="isActive"
                        v-model="route.isActive"
                      />
                      Active
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Distance</th>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    name="distance"
                    v-model.number="route.distance"
                  />
                </td>
              </tr>
              <tr>
                <th>Duration</th>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    name="duration"
                    v-model.number="route.duration"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: this.createNewRoute(),
      isSubmitting: false
    };
  },
  methods: {
    createNewRoute() {
      return {
        description: null,
        distance: null,
        duration: null,
        isActive: false,
        name: null
      };
    },
    addRoute() {
      if (this.isSubmitting) return;
      let answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("route/addRoute", this.route)
        .then(() => {
          this.$router.push(
            { name: "routes" },
            () => {
              this.route = this.createNewRoute();
            },
            () => {
              this.isSubmitting = false;
            }
          );
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style></style>
