import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_USER_API_URL, // dedicated url for users-api
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getToken() {
    // getting token from main api-server
    return apiClient.post(`${process.env.VUE_APP_BE_API_URL}/api/users/login`, {
      email: process.env.VUE_APP_BE_API_EMAIL,
      password: process.env.VUE_APP_BE_API_PASSWORD
    });
  },

  getUsers() {
    return apiClient.get("/api/users");
  },

  getUser(oid) {
    return apiClient.get("/api/users/" + oid);
  },

  updateUser(oid, user) {
    return apiClient.patch(`/api/users/${oid}`, { user });
  },

  addUser(user) {
    return apiClient.post("api/users", user);
  }
};
