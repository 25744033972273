import RouteService from "@/services/RouteService.js";

export const namespaced = true;

export const state = {
  route: null,
  routes: [],
  routePois: []
};

export const mutations = {
  SET_ROUTE(state, route) {
    state.route = route;
  },
  SET_ROUTE_POIS(state, routePois) {
    state.routePois = routePois;
  },
  SET_ROUTES(state, routes) {
    state.routes = routes;
  },
  ADD_ROUTE(state, route) {
    state.routes.push(route);
  },
  DELETE_ROUTE(state, route) {
    state.routes = state.routes.filter(r => r.id !== route.id);
  },
  EDIT_ROUTE(state, route) {
    state.route = { ...state.route, ...route };
  }
};

export const actions = {
  getRoute({ commit, rootState, dispatch }, id) {
    return RouteService.getRoute(rootState.user.token, id)
      .then(response => {
        let route = response.data;
        commit("SET_ROUTE", route);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getRoutePois({ commit, rootState, dispatch }, id) {
    return RouteService.getRoutePois(rootState.user.token, id)
      .then(response => {
        commit("SET_ROUTE_POIS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getRoutes({ commit, rootState, dispatch }) {
    return RouteService.getRoutes(rootState.user.token)
      .then(response => {
        commit("SET_ROUTES", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addRoute({ commit, rootState, dispatch }, route) {
    return RouteService.addRoute(
      rootState.user.token,
      rootState.user.user.name,
      route
    )
      .then(response => {
        commit("ADD_ROUTE", response.data);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Add Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  deleteRoute({ commit, rootState, dispatch }, id) {
    return RouteService.deleteRoute(rootState.user.token, id)
      .then(response => {
        //commit("DELETE_ROUTE", response.data);
        const notification = {
          type: "success",
          message: "Deleted Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Deletion Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editRoute({ commit, rootState, dispatch }, route) {
    return RouteService.editRoute(
      rootState.user.token,
      rootState.user.user.name,
      route.id,
      route
    )
      .then(response => {
        commit("EDIT_ROUTE", response.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};

export const getters = {
  // sortRoutePoisByWeight: (state, getters) => {
  //   return route => {
  //     return route.pois.sort((a, b) => {
  //       return (
  //         getters.findRouteWeight(route, a.id) -
  //         getters.findRouteWeight(route, b.id)
  //       );
  //     });
  //   };
  // },
  findRouteWeight: state => {
    return (route, id) => {
      return route.poiMeta.find(poi => {
        return poi.poiId === id;
      }).weight;
    };
  }
};
