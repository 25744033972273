import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getSchedule(token, id) {
    return apiClient.get("/api/schedules/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  assignDriverSchedule(token, username, sid, did) {
    return apiClient.put(
      "/api/schedules/" + sid + "/driver/" + did + "/assign",
      {
        headers: {
          Authorization: token,
          "X-Owto-Request-By": username
        }
      }
    );
  },

  addSchedule(token, username, schedule) {
    return apiClient.post("/api/schedules", schedule, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  cancelSchedule(token, username, id) {
    return apiClient.put("/api/schedules/" + id + "/cancel", {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  deleteSchedule(token, username, id) {
    return apiClient.delete("/api/schedules/" + id, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  editSchedule(token, username, id, schedule) {
    return apiClient.patch("/api/schedules/" + id, schedule, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  }
};
