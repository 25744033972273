import BarangayService from "@/services/BarangayService.js";

export const namespaced = true;

export const state = {
  barangays: [],
  barangaysByCity: []
};

export const mutations = {
  SET_BARANGAYS(state, barangays) {
    state.barangays = barangays;
  },
  SET_BARANGAYS_BY_CITY(state, { barangays, cId }) {
    state.barangaysByCity = barangays;
    if (cId) {
      if (state.barangays.length) {
        let isExist = state.barangays.find(b => {
          return b.cityCode == cId;
        });
        if (isExist) return;
        state.barangays = state.barangays.concat(barangays);
      } else {
        state.barangays = barangays;
      }
    }
  },
  CLEAR_BARANGAYS_BY_CITY(state) {
    state.barangaysByCity = [];
  }
};

export const actions = {
  getBarangays({ commit, rootState, getters }) {
    if (getters.checkBarangays) {
      return;
    } else {
      return BarangayService.getBarangays(rootState.user.token).then(data => {
        commit("SET_BARANGAYS", data.data);
      });
    }
  },
  getBarangaysByCity({ commit, rootState, getters }, cId) {
    const barangays = getters.getBarangaysByCity(cId);
    if (barangays.length) {
      commit("SET_BARANGAYS_BY_CITY", {
        barangays: barangays,
        cId: null
      });
    } else {
      commit("CLEAR_BARANGAYS_BY_CITY");
      BarangayService.getBarangaysByCity(rootState.user.token, cId).then(
        response => {
          commit("SET_BARANGAYS_BY_CITY", {
            barangays: response.data,
            cId: cId
          });
        }
      );
    }
  }
};

export const getters = {
  checkBarangays: function(state) {
    return state.barangays.length;
  },
  getBarangaysByCity: state => cId => {
    return state.barangays.filter(barangay => barangay.cityCode === cId);
  }
};
