import EmployerService from "@/services/EmployerService.js";

export const namespaced = true;

export const state = {
  employer: null,
  employers: []
};

export const mutations = {
  SET_EMPLOYERS(state, employers) {
    state.employers = employers;
  },
  SET_EMPLOYER(state, employer) {
    state.employer = employer;
  },
  ADD_EMPLOYER(state, employer) {
    state.employers.push(employer);
  },
  EDIT_EMPLOYER(state, employer) {
    let ctr = 0;
    state.employers.forEach(e => {
      ctr++;
      if (e.id == employer.id) {
        state.employers[ctr] = employer;
      }
    });
  },
  DELETE_EMPLOYER(state, employerId) {
    state.employers = state.employers.filter(employer => {
      return employer.id != employerId;
    });
  }
};

export const actions = {
  getEmployers({ commit, rootState, dispatch }) {
    return EmployerService.getEmployers(rootState.user.token)
      .then(response => {
        commit("SET_EMPLOYERS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getEmployer({ commit, rootState, dispatch }, id) {
    return EmployerService.getEmployer(rootState.user.token, id)
      .then(response => {
        commit("SET_EMPLOYER", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addEmployer({ commit, rootState, dispatch }, employer) {
    return EmployerService.addEmployer(rootState.user.token, employer)
      .then(response => {
        commit("ADD_EMPLOYER", response.data);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Add Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editEmployer({ commit, rootState, dispatch }, employer) {
    return EmployerService.editEmployer(
      rootState.user.token,
      employer.id,
      employer
    )
      .then(response => {
        commit("EDIT_EMPLOYER", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  deleteEmployer({ commit, rootState, dispatch }, id) {
    return EmployerService.deleteEmployer(rootState.user.token, id)
      .then(() => {
        commit("DELETE_EMPLOYER", id);
        const notification = {
          type: "success",
          message: "Delete Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Delete Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
