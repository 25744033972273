import IdService from "@/services/IdService.js";

export const namespaced = true;

export const state = {
  ids: []
};

export const mutations = {
  SET_IDS(state, ids) {
    state.ids = ids;
  }
};

export const actions = {
  getIds({ commit, rootState }) {
    IdService.getIds(rootState.user.token).then(data => {
      commit("SET_IDS", data.data);
    });
  }
};
