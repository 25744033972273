<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li class="active">
        <a data-toggle="tab">Notes</a>
      </li>
    </ul>

    <div class="tab-content">
      <div class="active tab-pane" id="notes">
        <div class="form-group" id="notes_form">
          <input type="hidden" name="userId" :value="id" />
          <input type="hidden" name="entity" :value="view" />
          <table
            id="notes_table"
            class="table table-hover"
            width="100%"
            :data-user-id="id"
            :data-entity="view"
          >
            <thead>
              <tr>
                <th>Note</th>
                <th>Date</th>
              </tr>
            </thead>
          </table>
          <fieldset class="form-group">
            <textarea
              v-if="
                $can('create', 'Operator') &&
                  $can('create', 'Partner') &&
                  $can('create', 'Rider')
              "
              class="form-control"
              name="note"
              placeholder="Note here..."
              v-model.trim="note.note"
            ></textarea>
          </fieldset>
          <button
            v-if="
              $can('create', 'Operator') &&
                $can('create', 'Partner') &&
                $can('create', 'Rider')
            "
            for="notes_table"
            type="button"
            class="btn btn-sm btn-primary"
            @click="addNote"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      datatable: null,
      note: this.createNewNote(),
      isSubmitting: false
    };
  },
  methods: {
    addNote() {
      if (this.isSubmitting) return;
      const answer = window.confirm("are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("note/addNote", this.note)
        .then(() => {
          $("#notes_table")
            .DataTable()
            .ajax.reload(() => {
              this.isSubmitting = false;
            });
          this.note = this.createNewNote();
          console.log("success");
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    createNewNote() {
      return {
        entity: this.view.toLowerCase(),
        userId: this.id,
        note: ""
      };
    },
    setupDtTable(el) {
      let dtColumns = ["notes", "createdAt"];
      const vm = this; // passing this
      const entity = el[0].dataset.entity.toLowerCase();
      const userId = el[0].dataset.userId;

      const url = `${
        process.env.VUE_APP_BE_API_URL
      }/api/notes?filter[where][entity]=${entity}&filter[where][userId]=${userId}`;

      return $(el).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[1, "DESC"]],
        columns: [{ data: "note" }, { data: "createdAt" }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `
                <span>${row.note}</span>
                <p class="small text-muted" style="display:block;margin-bottom:0px;">By: ${
                  row.username
                }</p>
              `;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: true,
            render: (type, data, row, meta) => {
              return moment(row.createdAt).format("LLLL");
            }
          }
        ]
      });
    }
  },
  mounted() {
    const el = $("#notes_form"); // root element
    const table = $("#notes_table");
    /* const dtTable = */ this.setupDtTable(table);
    // this.setupForm(table, dtTable, el);
  },
  computed: {
    ...mapState("user", ["user", "token"])
  }
};
</script>

<style></style>
