<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <router-link :to="{ name: 'schedules' }">
          <i class="fa fa-caret-left"></i>
          Back
        </router-link>
        <h1>Edit Schedule</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form
            id="schedule_form"
            method="POST"
            @submit.prevent="editSchedule"
            :data-schedule-id="schedule.id"
          >
            <fieldset>
              <table class="table table-settings">
                <tbody>
                  <tr>
                    <th>Schedule Date</th>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="scheduleDate"
                          name="scheduleDate"
                          v-model="schedule.scheduleDate"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Fare per Rider</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          class="form-control"
                          name="fixedFare"
                          required="required"
                          v-model.number="schedule.fixedFare"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Total Fare</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          class="form-control"
                          name="totalFare"
                          required="required"
                          v-model.number="schedule.totalFare"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Toll Fee</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          class="form-control"
                          name="tollFee"
                          required="required"
                          v-model.number="schedule.tollFee"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Driver</th>
                    <td>
                      <div class="form-group">
                        <input
                          type="hidden"
                          class="form-control"
                          name="driverId"
                          v-model="schedule.driverId"
                        />
                      </div>

                      <div class="media form-group">
                        <div class="media-left">
                          <a href="#">
                            <img
                              class="media-object img-circle"
                              data-profile-picture
                              style="width:100px;height:auto;"
                              :src="driver.displayPhoto"
                            />
                          </a>
                        </div>
                        <div class="media-body">
                          <h4 class="media-heading" data-profile-name></h4>

                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            data-toggle="modal"
                            data-target="#showQrModal"
                          >
                            QR Code
                          </button>
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#enrollDriverModal"
                      >
                        Change
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th>Route</th>
                    <td>
                      <select
                        class="form-control"
                        name="routeId"
                        v-model="schedule.routeId"
                        required="required"
                      >
                        <option
                          v-for="(route, index) in routes"
                          :key="index"
                          :value="route.id"
                          >{{ route.name }}</option
                        >
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Variant Applied</th>
                    <td>
                      <select
                        class="form-control"
                        name="variantId"
                        v-model="schedule.variantId"
                        required="required"
                      >
                        <option
                          v-for="(variant, index) in variants"
                          :key="index"
                          :value="variant.id"
                          >{{ variant.variantName }}</option
                        >
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button type="submit" class="btn btn-primary">
                        Submit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </form>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="showQrModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      style="z-index:10500"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">QR</h4>
          </div>
          <div class="modal-body">
            <div id="qrcode" :data-driver-id="schedule.driverId">
              <img
                style="margin:0 auto;"
                class="img-responsive"
                :src="
                  'http://api.qrserver.com/v1/create-qr-code/?data=' +
                    schedule.driverId +
                    '&size=300x300'
                "
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="enrollDriverModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Enroll Driver</h4>
          </div>
          <div class="modal-body">
            <table
              id="driver_assign_table"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Driver</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store.js";
import { mapState } from "vuex";
import moment from "moment";

export default {
  beforeRouteEnter(to, from, next) {
    store.dispatch("route/getRoutes");
    store.dispatch("variant/getVariants");
    store
      .dispatch("schedule/getSchedule", to.params.id)
      .then(response => {
        store.dispatch("driver/getDriver", response.driverId).then(() => {
          next();
        });
      })
      .catch(() => {
        if (navigator.onLine) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      isAssigning: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("schedule", ["schedule"]),
    ...mapState("route", ["routes"]),
    ...mapState("variant", ["variants"]),
    ...mapState("driver", ["driver"]),
    ...mapState("user", ["token"])
  },
  methods: {
    editSchedule() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;

      store
        .dispatch("schedule/editSchedule", this.schedule)
        .then(() => {
          this.$router.push({ name: "schedules" });
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let selected = vm.schedule.driverId === row.userId ? true : false;
              let icon = selected ? "fa-check" : "fa-ban";
              let btn = selected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-schedule-driver-id="${row.userId}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#driver_assign_table").on("click", "[data-schedule-driver-id]", e => {
        if (vm.isAssigning) return;
        let { scheduleDriverId } = e.target.dataset;
        let confirmed = confirm("Are you sure?");
        if (confirmed == true) {
          vm.isAssigning = true;
          store
            .dispatch("schedule/assignDriverSchedule", {
              sid: vm.schedule.id,
              did: scheduleDriverId
            })
            .then(() => {
              return store
                .dispatch("driver/getDriver", scheduleDriverId)
                .then(() => {
                  vm.schedule.driverId = scheduleDriverId;

                  $("#driver_assign_table")
                    .DataTable()
                    .ajax.reload(() => {
                      vm.isAssigning = false;
                    });
                });
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        }
      });
    }
  },
  mounted() {
    let vm = this;

    $.datetimepicker.setDateFormatter("moment");

    let times = [...Array(24).keys()]
      .map(hour => hour.toString().padStart(2, "0"))
      .map(hour => [`${hour}:00`, `${hour}:15`, `${hour}:30`, `${hour}:45`]);

    let allowTimes = [].concat.apply([], times);

    $("#scheduleDate").datetimepicker({
      inline: true,
      format: "YYYY-MM-DD HH:mm",
      formatTime: "HH:mm",
      formatDate: "YYYY-MM-DD",
      defaultDate: moment(vm.schedule.scheduleDate).format("YYYY-MM-DD"),
      defaultTime: moment(vm.schedule.scheduleDate).format("HH:mm"),
      allowTimes,
      onSelectDate: function() {
        vm.schedule.scheduleDate = moment
          .utc($("#scheduleDate").datetimepicker("getValue"))
          .format();
      },
      onSelectTime: function() {
        vm.schedule.scheduleDate = moment
          .utc($("#scheduleDate").datetimepicker("getValue"))
          .format();
      }
    });
    this.schedule.scheduleDate = moment
      .utc($("#scheduleDate").datetimepicker("getValue"))
      .format();

    this.dataTable = $("#driver_assign_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/drivers`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
