import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getEmployers(token) {
    // get employers
    return apiClient.get(`/api/employers`, {
      headers: {
        Authorization: token
      }
    });
  },

  getEmployer(token, id) {
    // get employer
    return apiClient.get(`/api/employers/${id}`, {
      headers: {
        Authorization: token
      }
    });
  },

  addEmployer(token, employer) {
    // add employer
    return apiClient.post(`/api/employers`, employer, {
      headers: {
        Authorization: token
      }
    });
  },

  editEmployer(token, id, employer) {
    // edit employer
    return apiClient.patch(`/api/employers/${id}`, employer, {
      headers: {
        Authorization: token
      }
    });
  },

  deleteEmployer(token, id) {
    // delete employer
    return apiClient.delete(`/api/employers/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
};
