<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>Admin Panel</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box-body">
          <form id="admin_form" method="POST" @submit.prevent>
            <div class="row">
              <div class="col-md-7">
                <div class="box box-primary">
                  <table id="user_table" class="table table-hover" width="100%">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="user in myUsers" :key="user.oid">
                        <template v-if="user.role !== 'ADMIN'">
                          <td>{{ user.name }}</td>
                          <td>{{ user.email }}</td>
                          <td>
                            <button
                              @click="selectUser(user.oid)"
                              class="btn btn-primary"
                              type="button"
                            >
                              <i
                                class="fa fa-plus"
                                aria-hidden="true"
                                style="pointer-events: none"
                              ></i>
                            </button>
                          </td>
                        </template>
                        <td
                          colspan="2"
                          v-if="myUsers.length < 2 && user.role === 'ADMIN'"
                          class="userless"
                        >
                          No users yet...
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <template v-if="selectedUser">
                <div class="col-md-5">
                  <div class="box box-primary">
                    <div class="box-body box-profile">
                      <h4 class="name">
                        <strong>{{ selectedUser.name }}</strong>
                      </h4>
                      <hr />
                      <h4>
                        <strong>Permission Level</strong>
                      </h4>

                      <div class="level-box-container">
                        <div
                          class="level-box"
                          v-for="(l, i) in levels"
                          :key="i"
                        >
                          <button
                            type="button"
                            class="level-btn"
                            :class="selectedUser.role === l ? 'isActive' : ''"
                            @click="selectLevel(l)"
                          >
                            {{ l }}
                          </button>
                        </div>
                      </div>
                      <hr />
                      <h4>
                        <strong>Entities</strong>
                      </h4>
                      <div class="entity-box-container">
                        <div class="entity-box">
                          <select
                            class="form-control entity-input"
                            v-model="entity"
                          >
                            <option disabled value>Please select entity</option>
                            <option
                              v-for="(e, i) in remainingEntities"
                              :key="i"
                              :value="e"
                              >{{ e }}</option
                            >
                          </select>
                          <button
                            class="btn btn-sm btn-primary entity-button"
                            type="button"
                            @click="addEntity"
                          >
                            Add Entity
                          </button>
                        </div>
                        <ul class="entity-list">
                          <li v-for="(e, i) in selectedUser.entities" :key="i">
                            {{ e }}
                            <span @click="removeEntity(e)">&times;</span>
                          </li>
                        </ul>
                        <h5 v-show="!selectedUser.entities.length">
                          No entities yet...
                        </h5>
                      </div>

                      <button
                        for="notes_table"
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="updateUser"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UserService from "@/services/UserService.js";

export default {
  data() {
    return {
      levels: ["LEVEL_1", "LEVEL_2", "LEVEL_3"],
      myUsers: null,
      selectedUserId: null,
      entities: [
        "Operator",
        "OperatorVehicle",
        "OperatorDriver",
        "OperatorPrangkisa",
        "Partner",
        "PartnerOperator",
        "PartnerVehicle",
        "PartnerTripHistory",
        "PartnerLocationHistory",
        "PartnerCard",
        "Rider",
        "RiderEmployer",
        "RiderTripHistory",
        "RiderCard",
        "Route",
        "Schedule",
        "VehicleModel",
        "Tag",
        "Variant",
        "Poi",
        "Fleet",
        "TopUp",
        "WalletTransaction",
        "Employer",
        "Disbursement",
        "Livemap"
      ],
      entity: ""
    };
  },
  created() {
    UserService.getUsers().then(response => {
      // this.myUsers = this.response.data.users;
      this.myUsers = response.data.users;
      console.table(this.myUsers);
    });
  },
  methods: {
    selectUser(oid) {
      // getting id, use for selecting user that defined in computed property ( selectedUser() )
      this.selectedUserId = oid;
      UserService.getUsers().then(response => {
        // this.myUsers = JSON.parse(localStorage.getItem("owto-users"));
        this.myUsers = response.data.users;
      });
    },
    removeEntity(entity) {
      // remove entity to current selected user entities
      let index = this.selectedUser.entities.indexOf(entity);
      if (index > -1) {
        this.selectedUser.entities.splice(index, 1);
      }
    },
    selectLevel(level) {
      // assign permission level to the selected user
      this.selectedUser.role = level;
    },
    updateUser() {
      // submission mimic/commit changes to local storage
      UserService.updateUser(this.selectedUserId, this.selectedUser)
        .then(() => {
          // deselect userid
          this.selectedUserId = null;
          // raise notification, signaled user that changes happens
          const notification = {
            type: "success",
            message: "Edit Success"
          };
          this.$store.dispatch("notification/add", notification, {
            root: true
          });
        })
        .catch(error => {
          const notification = {
            type: "error",
            message: `Edit Failed, ${error.message}`
          };
          this.$store.dispatch("notification/add", notification, {
            root: true
          });
        });
    },
    addEntity() {
      // add entity to current selected user entities
      if (this.entity) {
        this.selectedUser.entities.push(this.entity);
        this.entity = "";
      }
    }
  },
  computed: {
    selectedUser() {
      let currentUser = null;
      if (this.myUsers) {
        currentUser = this.myUsers.find(
          user => user.oid === this.selectedUserId
        );
      }
      return this.selectedUserId ? currentUser : null;
    },
    remainingEntities() {
      // remove entities to entities collection that the current selected user has
      return this.entities.filter(e => {
        return this.selectedUser.entities.indexOf(e) == -1;
      });
    },
    users() {
      // fetch array of users of users object, our unbound base array (commit changes during submission)
      let users = localStorage.getItem("owto-users");
      if (users) {
        users = JSON.parse(users);
      }
      return users;
    }
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.name {
  text-align: center;
}

.level-box-container {
  width: 100%;
  padding: 0 10px 0;
  margin: 5px 0 5px;
}

.level-box-container > .level-box {
  width: 33.3333%;
  height: 35px;
  display: inline-block;
  padding: 0 10px 0;
}

.level-btn {
  display: block;
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  outline: none;
  box-shadow: 0 0 20px 2px rgba(51, 122, 183, 0.5) inset;
  letter-spacing: 2px;
}

.level-btn + label {
  position: absolute;
}

.level-box .level-btn:hover {
  box-shadow: 0 0 20px 2px rgba(51, 122, 183, 0.5) inset,
    0 0 5px 2px rgba(51, 122, 255, 1);
}

.level-box .level-btn.isActive {
  box-shadow: 0 0 20px 2px rgba(51, 122, 255, 1) inset;
}

.entity-box-container {
  width: 90%;
  margin: 5px auto 5px;
}

.entity-box-container h5 {
  font-weight: 600;
  font-style: italic;
  letter-spacing: 2px;
}

.entity-box {
  height: 35px;
  margin-bottom: 15px;
}

.entity-box-container::before,
.entity-box-container::after,
.entity-list li::before,
.entity-list li::after {
  content: "";
  display: table;
  clear: both;
}

.entity-input,
.entity-button {
  float: left;
}

.entity-input {
  width: 70%;
  height: 100%;
}

.entity-button {
  width: 30%;
  height: 100%;
  border-radius: 0;
}

.entity-list {
  padding: 0;
  margin: 0 auto 15px;
  list-style: none;
  width: 90%;
}

.entity-list li {
  border-bottom: #ddd 1px solid;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 3px 5px;
}

.entity-list li:hover {
  background: linear-gradient(
    to left,
    rgba(51, 122, 183, 0),
    rgba(51, 122, 255, 0.4),
    rgba(51, 122, 183, 0)
  );
}

.entity-list li span {
  float: right;
  cursor: pointer;
  font-weight: 900;
  color: rgba(215, 57, 37, 0);
  visibility: hidden;
  transition: 0.5s 0.2s color;
}

.entity-list li:hover span {
  visibility: visible;
  color: rgba(215, 57, 37, 1);
}

.userless {
  text-align: center;
  font-style: italic;
}
</style>
