import ProvinceService from "@/services/ProvinceService.js";
import { stat } from "fs";

export const namespaced = true;

export const state = {
  provinces: [],
  provincesByRegion: []
};

export const mutations = {
  SET_PROVINCES(state, provinces) {
    state.provinces = provinces;
  },
  SET_PROVINCES_BY_REGION(state, { provinces, rId }) {
    state.provincesByRegion = provinces;
    if (rId) {
      if (state.provinces.length) {
        let isExist = state.provinces.find(p => {
          return p.regionCode == rId;
        });
        if (isExist) return;
        state.provinces = state.provinces.concat(provinces);
      } else {
        state.provinces = provinces;
      }
    }
  },
  CLEAR_PROVINCES_BY_REGION(state) {
    state.provincesByRegion = [];
  }
};

export const actions = {
  getProvinces({ commit, rootState, getters }) {
    if (getters.checkProvinces) {
      return;
    } else {
      return ProvinceService.getProvinces(rootState.user.token).then(
        response => {
          commit("SET_PROVINCES", response.data);
        }
      );
    }
  },
  getProvincesByRegion({ commit, rootState, getters }, rId) {
    const provinces = getters.getProvincesByRegion(rId);
    if (provinces.length) {
      commit("SET_PROVINCES_BY_REGION", {
        provinces: provinces,
        rId: null
      });
    } else {
      commit("CLEAR_PROVINCES_BY_REGION");
      ProvinceService.getProvincesByRegion(rootState.user.token, rId).then(
        response => {
          commit("SET_PROVINCES_BY_REGION", {
            provinces: response.data,
            rId: rId
          });
        }
      );
    }
  }
};

export const getters = {
  checkProvinces: function(state) {
    return state.provinces.length;
  },
  getProvincesByRegion: state => rId => {
    return state.provinces.filter(province => province.regionCode === rId);
  }
};
