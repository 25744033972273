<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <router-link :to="{ name: 'schedules' }">
          <i class="fa fa-caret-left"></i>
          Back
        </router-link>
        <h1>New Schedule</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form id="schedule_form" method="POST" @submit.prevent="addSchedule">
            <fieldset>
              <table class="table table-settings">
                <tbody>
                  <!-- <tr>
                  <th>Route Group</th>
                  <td>
                    <div class="input-group">
                      <select class="form-control" name="routeGroup">
                         <% routeGroups.forEach(routeGroup => {%>
                      <option value="<%= routeGroup %>" <%= routeGroup === form.routeGroup ? 'selected="selected"' : '' %>><%= routeGroup %></option>
                        <% }) %>
                      </select>
                    </div>
                  </td>
                  </tr>-->
                  <tr>
                    <th>Schedule Date</th>
                    <td>
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="bulkOrNot"
                            v-model="bulkSchedule"
                            @change="isBulk"
                          />
                          Bulk Schedule
                        </label>
                      </div>
                      <div class="form-group" id="bulk" v-show="bulkSchedule">
                        <div class="form-group input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-calendar fa-fw"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            name="daterange"
                          />
                        </div>
                        <div class="form-group input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-clock-o fa-fw"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            name="ftime"
                          />
                          <input
                            type="text"
                            class="form-control"
                            name="ttime"
                          />
                        </div>
                      </div>
                      <div
                        class="form-group"
                        id="notBulk"
                        v-show="!bulkSchedule"
                      >
                        <input
                          type="text"
                          class="form-control"
                          id="scheduleDate"
                          v-model="schedule.scheduleDate"
                          name="scheduleDate"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Fare per Rider</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          v-model.number="schedule.fixedFare"
                          class="form-control"
                          name="fixedFare"
                          required="required"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Total Fare</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          class="form-control"
                          name="totalFare"
                          required="required"
                          v-model.number="schedule.totalFare"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Toll Fee</th>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">P</span>
                        <input
                          type="number"
                          class="form-control"
                          name="tollFee"
                          required="required"
                          v-model.number="schedule.tollFee"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Driver</th>
                    <td>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          name="driverId"
                          readonly="readonly"
                          id="selDriver"
                          v-model="schedule.driverId"
                        />
                        <span
                          class="input-group-addon populate-btn"
                          data-toggle="modal"
                          data-target="#scheduleDriver"
                        >
                          <i class="fa fa-plus"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Route</th>
                    <td>
                      <select
                        class="form-control"
                        name="routeId"
                        required="required"
                        v-model="schedule.routeId"
                      >
                        <option disabled value>Please select route</option>
                        <option
                          v-for="(route, index) in routes"
                          :key="index"
                          :value="route.id"
                          >{{ route.name }}</option
                        >
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>Variant Applied</th>
                    <td>
                      <select
                        class="form-control"
                        name="variantId"
                        required="required"
                        v-model="schedule.variantId"
                      >
                        <option disabled value>Please select variant</option>
                        <option
                          v-for="(variant, index) in variants"
                          :key="index"
                          :value="variant.id"
                          >{{ variant.variantName }}</option
                        >
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button type="submit" class="btn btn-primary">
                        Submit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </form>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="scheduleDriver"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Enroll Driver</h4>
          </div>
          <div class="modal-body">
            <table
              id="schedule_bind_driver"
              :data-operator-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapState } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("route/getRoutes")
      .then(() => {
        store.dispatch("variant/getVariants").then(() => {
          next();
        });
      })
      .catch(() => {
        next(false);
      });
  },
  data() {
    return {
      schedule: this.createNewSchedule(),
      dataTable: null,
      bulkSchedule: false,
      dateRange: [],
      timeRange: [],
      isSubmitting: false,
      isAssigning: false
    };
  },
  computed: {
    ...mapState("variant", ["variants"]),
    ...mapState("route", ["routes"]),
    ...mapState("user", ["token"])
  },
  methods: {
    createNewSchedule() {
      return {
        scheduleDate: null,
        fixedFare: null,
        totalFare: null,
        tollFee: null,
        driverId: null,
        routeId: "",
        variantId: ""
      };
    },
    isBulk() {
      if (this.bulkSchedule) {
        this.schedule.scheduleDate = null;
        this.dateRange = [];
        this.dateRange.push(
          moment(
            $('[name="daterange"]').data("daterangepicker").startDate
          ).format("YYYY-MM-DD")
        );
      } else {
        this.schedule.scheduleDate = moment
          .utc($("#scheduleDate").datetimepicker("getValue"))
          .format();
      }
    },
    addSchedule() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      if (!this.schedule.driverId) {
        const notification = {
          type: "error",
          message: "Driver Required!"
        };
        store.dispatch("notification/add", notification, { root: true });
        this.isSubmitting = false;
        return;
      }
      if (this.bulkSchedule) {
        var ctr = 0;
        var len = this.dateRange.length * this.timeRange.length;
        if (!this.timeRange.length) {
          const notification = {
            type: "error",
            message: "Invalid Time Range"
          };
          store.dispatch("notification/add", notification, { root: true });
          this.isSubmitting = false;
        } else {
          this.dateRange.forEach(d => {
            this.timeRange.forEach(t => {
              this.schedule = {
                ...this.schedule,
                scheduleDate: moment(d + " " + t)
              };
              store
                .dispatch("schedule/addSchedule", this.schedule)
                .then(() => {
                  ctr++;
                  if (ctr === len) {
                    this.$router.push({ name: "schedules" });
                    this.schedule = this.createNewSchedule();
                  }
                })
                .catch(() => {
                  this.isSubmitting = false;
                });
            });
          });
        }
      } else {
        store
          .dispatch("schedule/addSchedule", this.schedule)
          .then(() => {
            this.$router.push({ name: "schedules" });
            this.schedule = this.createNewSchedule();
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      }
    },
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let selected = vm.schedule.driverId === row.userId ? true : false;
              let icon = selected ? "fa-check" : "fa-ban";
              let btn = selected ? "btn-success" : "btn-primary";
              let dName = `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;

              button += `<button class="btn ${btn}"
                              type="button"
                              data-schedule-driver-name="${dName}"
                              data-is-selected="${selected}"
                              data-schedule-driver-id="${row.userId}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#schedule_bind_driver").on("click", "[data-schedule-driver-id]", e => {
        if (vm.isAssigning) return;
        let {
          scheduleDriverId,
          scheduleDriverName,
          isSelected
        } = e.target.dataset;
        isSelected = JSON.parse(isSelected);

        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isAssigning = true;

        if (!isSelected) {
          axios
            .get(
              `${
                process.env.VUE_APP_BE_API_URL
              }/api/drivers/${scheduleDriverId}/vehicles?filter[limit]=1`,
              {
                headers: {
                  Authorization: vm.token
                }
              }
            )
            .then(response => {
              if (!response.data.length) {
                vm.isAssigning = false;
                const notification = {
                  type: "error",
                  message: "Cannot assign a driver without a vehicle."
                };
                vm.$store.dispatch("notification/add", notification, {
                  root: true
                });
              } else {
                document.querySelector("#selDriver").value = scheduleDriverName;
                vm.schedule.driverId = scheduleDriverId;
                $("#schedule_bind_driver")
                  .DataTable()
                  .ajax.reload(() => {
                    vm.isAssigning = false;
                  });
              }
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        } else {
          if (navigator.onLine) {
            document.querySelector("#selDriver").value = "";
            vm.schedule.driverId = "";
            $("#schedule_bind_driver")
              .DataTable()
              .ajax.reload(() => {
                vm.isAssigning = false;
              });
          } else {
            vm.isAssigning = false;
          }
        }
      });
    }
  },
  mounted() {
    let vm = this;
    // $.datetimepicker.setDateFormatter({
    //   parseDate: function(date, format) {
    //     var d = moment(date, format);
    //     return d.isValid() ? d.toDate() : false;
    //   },
    //   formatDate: function(date, format) {
    //     return moment(date).format(format);
    //   }
    // });
    $.datetimepicker.setDateFormatter("moment");

    let times = [...Array(24).keys()]
      .map(hour => hour.toString().padStart(2, "0"))
      .map(hour => [`${hour}:00`, `${hour}:15`, `${hour}:30`, `${hour}:45`]);

    let allowTimes = [].concat.apply([], times);

    $("#scheduleDate").datetimepicker({
      inline: true,
      format: "YYYY-MM-DD HH:mm",
      formatTime: "HH:mm",
      formatDate: "YYYY-MM-DD",
      defaultTime: "00:00",
      allowTimes,
      onSelectDate: function() {
        vm.schedule.scheduleDate = moment
          .utc($("#scheduleDate").datetimepicker("getValue"))
          .format();
      },
      onSelectTime: function() {
        vm.schedule.scheduleDate = moment
          .utc($("#scheduleDate").datetimepicker("getValue"))
          .format();
      }
    });
    this.schedule.scheduleDate = moment
      .utc($("#scheduleDate").datetimepicker("getValue"))
      .format();

    // bulk schedule
    let daterange = document.querySelector('[name="daterange"]');
    let ftime = document.querySelector('[name="ftime"]');
    let ttime = document.querySelector('[name="ttime"]');
    $(daterange).daterangepicker({
      locale: {
        format: "YYYY-MM-DD"
      }
    });

    $(daterange).on("apply.daterangepicker", function(ev, picker) {
      vm.dateRange = [];
      let sDate = moment(picker.startDate);
      let eDate = moment(picker.endDate);
      var d = moment(eDate).diff(moment(sDate), "days");

      for (let i = 0; i <= d; i++) {
        var add = moment(sDate).add(i, "days");
        vm.dateRange.push(add.format("YYYY-MM-DD"));
      }
    });

    $(ftime).clockTimePicker({
      precision: 15,
      maximum: "23:45",
      onChange: function(n, o) {
        $(ttime).clockTimePicker("value", n);
        //$(ttime).clockTimePicker({ precision: 15, minimum: n });
        //$(ttime).attr("data-minimum", n);
      }
    });

    $(ttime).clockTimePicker({
      precision: 15,
      minimum: "00:00",
      onChange: function(n, o) {
        console.log("break");
        vm.timeRange = [];
        //$(ftime).attr("data-maximum", n);
        //$(ftime).clockTimePicker({ precision: 15, maximum: n });
        let timeStart = moment(
          document.querySelector('[name="ftime"]').value,
          "HH:mm"
        );
        n = moment(n, "HH:mm");
        let diff = n.diff(timeStart, "minutes");
        diff = diff / 15;
        if (timeStart) {
          for (let i = 0; i <= diff; i++) {
            if (!i) {
              timeStart.add(i, "minutes");
            } else {
              timeStart.add(15, "minutes");
            }
            vm.timeRange.push(timeStart.format("HH:mm"));
          }
        }
      }
    });

    this.dataTable = $("#schedule_bind_driver");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/drivers`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style>
.xdsoft_calendar th {
  min-width: auto !important;
}

.populate-btn {
  background-color: #dd4b39 !important;
  border-color: #d73925 !important;
  cursor: pointer;
}

.populate-btn:hover {
  background-color: #d73925 !important;
}

.populate-btn > i {
  color: #fff;
}

input:read-only,
input:disabled {
  cursor: auto;
}

/* .xdsoft_calendar table > th {
  box-shadow: 5px 5px 10px #000 !important;
  width: auto;
  min-width: 0;
} */
</style>
