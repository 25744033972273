import DisbursementService from "@/services/DisbursementService.js";

export const namespaced = true;

export const state = {
  disbursementReports: []
};

export const mutations = {
  SET_DISBURSEMENT_REPORTS(state, disbursementReports) {
    state.disbursementReports = disbursementReports;
  }
};

export const actions = {
  getDisbursementReports({ commit, rootState, dispatch }, driverId) {
    return DisbursementService.getDisbursementReports(
      rootState.user.token,
      driverId
    )
      .then(response => {
        commit("SET_DISBURSEMENT_REPORTS", response.data);
        return;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
