<template>
  <div class="tab-pane" id="profile">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li
          v-for="(pTab, index) in profileTabs"
          :key="index"
          @click="selectedProfileTab = pTab.tab"
          :class="{ active: selectedProfileTab === pTab.tab }"
          data-toggle="tab"
        >
          <a v-if="getView(pTab.tab, view)">{{ pTab.tab }}</a>
          <!-- <a>{{ pTab.tab }}</a> -->
        </li>
      </ul>

      <div class="tab-content">
        <Personal
          :class="{ active: selectedProfileTab == 'Personal' }"
          :module="getModule"
          :permission="permission"
          :view="view"
        />

        <Address
          :class="{ active: selectedProfileTab == 'Address' }"
          :module="getModule"
          :permission="permission"
          :view="view"
        />

        <Contact
          :class="{ active: selectedProfileTab == 'Contact' }"
          :module="getModule"
          :permission="permission"
          :view="view"
        />

        <Paymaya
          :class="{ active: selectedProfileTab == 'Paymaya' }"
          :module="getModule"
          :permission="permission"
          :view="view"
        />

        <Requirement
          :class="{ active: selectedProfileTab == 'Requirements' }"
          :module="getModule"
          :permission="permission"
          :view="view"
        />

        <Owto
          :class="{ active: selectedProfileTab == 'Owto' }"
          :module="getModule"
          :permission="permission"
        />

        <File
          :class="{ active: selectedProfileTab == 'Files' }"
          :module="getModule"
          :permission="permission"
        />

        <Account
          :class="{ active: selectedProfileTab == 'Accounts' }"
          :module="getModule"
          :permission="permission"
        />

        <GCash
          :class="{ active: selectedProfileTab == 'GCash' }"
          :module="getModule"
          :permission="permission"
        />
      </div>
    </div>

    <div class="form-group">
      <button
        v-if="$can('update', view)"
        type="submit"
        class="btn btn-primary"
        @click="editProfile"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import Personal from "./Personal.vue";
import Address from "./Address.vue";
import Contact from "./Contact.vue";
import Paymaya from "./Paymaya.vue";
import Requirement from "./Requirement.vue";
import Owto from "./Owto.vue";
import File from "./File.vue";
import Account from "./Account.vue";
import GCash from "./GCash.vue";
import moment from "moment";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import EventBus from "@/event-bus.js";

export default {
  components: {
    Personal,
    Address,
    Contact,
    Paymaya,
    Requirement,
    Owto,
    File,
    Account,
    GCash
  },
  props: {
    view: {
      type: String,
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      profileTabs: [
        {
          tab: "Personal",
          views: ["Operator", "Partner", "Rider"]
        },
        {
          tab: "Address",
          views: ["Operator", "Partner", "Rider"]
        },
        {
          tab: "Contact",
          views: ["Operator", "Partner", "Rider"]
        },
        {
          tab: "Paymaya",
          views: ["Operator", "Partner"]
        },
        {
          tab: "Requirements",
          views: ["Operator", "Partner", "Rider"]
        },
        {
          tab: "Owto",
          views: ["Partner"]
        },
        {
          tab: "Files",
          views: ["Partner"]
        },
        {
          tab: "Accounts",
          views: ["Rider"]
        },
        {
          tab: "GCash",
          views: ["Partner"]
        }
      ],
      selectedProfileTab: "Personal",
      profile: null,
      isSubmitting: false
    };
  },
  methods: {
    getView: function(t, v) {
      return this.profileTabs.find(pt => {
        if (pt.tab === t) {
          return pt.views.find(view => {
            return view === v;
          });
        }
      });
    },
    editProfile() {
      if (this.isSubmitting) return;
      this.getModule.dob = !(this.getModule.dob == null)
        ? moment.utc(this.getModule.dob).format()
        : this.getModule.dob;

      this.getModule.onboardingDate = !(this.getModule.dob == null)
        ? moment.utc(this.getModule.onboardingDate).format()
        : this.getModule.onboardingDate;

      this.getModule.idValidity = !(this.getModule.dob == null)
        ? moment.utc(this.getModule.idValidity).format()
        : this.getModule.idValidity;
      this.profile = {
        ...this.getModule
      };
      console.log("submit testing");
      for (let p in this.profile) {
        if (this.profile[p] === "" || this.profile[p] === "Invalid date") {
          this.profile[p] = null;
        }
      }
      const answer = window.confirm("are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      const mobileNumber = this.profile.mobileNumber
        ? parsePhoneNumberFromString(this.profile.mobileNumber, "PH")
        : this.profile.mobileNumber;

      const paymayaNumber = this.profile.paymayaNumber
        ? parsePhoneNumberFromString(this.profile.paymayaNumber, "PH")
        : this.profile.paymayaNumber;

      const loginNumber = this.profile.loginNumber
        ? parsePhoneNumberFromString(this.profile.loginNumber, "PH")
        : this.profile.loginNumber;

      const gcashNumber = this.profile.gcashNumber
        ? parsePhoneNumberFromString(this.profile.gcashNumber, "PH")
        : this.profile.gcashNumber;

      if (
        (this.view === "Operator" &&
          (!this.profile.mobileNumber ||
            (this.profile.mobileNumber.length >= 5 &&
              this.profile.mobileNumber.length <= 13 &&
              mobileNumber.isValid())) &&
          (!this.profile.paymayaNumber ||
            (this.profile.paymayaNumber.length >= 5 &&
              this.profile.paymayaNumber.length <= 13 &&
              paymayaNumber.isValid()))) ||
        (this.view === "Partner" &&
          (!this.profile.mobileNumber ||
            (this.profile.mobileNumber.length >= 5 &&
              this.profile.mobileNumber.length <= 13 &&
              mobileNumber.isValid())) &&
          (!this.profile.paymayaNumber ||
            (this.profile.paymayaNumber.length >= 5 &&
              this.profile.paymayaNumber.length <= 13 &&
              paymayaNumber.isValid())) &&
          (!this.profile.loginNumber ||
            (this.profile.loginNumber.length >= 5 &&
              this.profile.loginNumber.length <= 13 &&
              loginNumber.isValid())) &&
          (!this.profile.gcashNumber ||
            (this.profile.gcashNumber.length >= 5 &&
              this.profile.gcashNumber.length <= 13 &&
              gcashNumber.isValid()))) ||
        (this.view === "Rider" &&
          (!this.profile.mobileNumber ||
            (this.profile.mobileNumber.length >= 5 &&
              this.profile.mobileNumber.length <= 13 &&
              mobileNumber.isValid())))
      ) {
        if (this.view === "Operator") {
          this.$store
            .dispatch("operator/editOperator", this.profile)
            .then(() => {
              this.isSubmitting = false;
              EventBus.$emit("update-operator", this.profile);
            })
            .catch(() => {
              this.isSubmitting = false;
            });
        } else if (this.view === "Partner") {
          this.$store
            .dispatch("driver/editDriver", this.profile)
            .then(() => {
              this.isSubmitting = false;
              EventBus.$emit("update-module", this.profile);
            })
            .catch(() => {
              this.isSubmitting = false;
            });
        } else if (this.view === "Rider") {
          this.$store
            .dispatch("rider/editRider", this.profile)
            .then(() => {
              this.isSubmitting = false;
              EventBus.$emit("update-module", this.profile);
            })
            .catch(() => {
              this.isSubmitting = false;
            });
        }
      } else {
        this.isSubmitting = false;
        const notification = {
          type: "error",
          message: "Invalid phone format"
        };
        this.$store.dispatch("notification/add", notification);
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  },
  computed: {
    getModule() {
      if (this.view === "Operator") {
        return this.$store.state.operator.operator;
      } else if (this.view === "Partner") {
        return this.$store.state.driver.driver;
      } else if (this.view === "Rider") {
        return this.$store.state.rider.rider;
      } else {
        return null;
      }
    },
    permission() {
      return (
        this.$can("update", "Operator") ||
        this.$can("update", "Partner") ||
        this.$can("update", "Rider")
      );
    }
  },
  updated() {
    this.getModule.dob = !(this.getModule.dob == null)
      ? moment(this.getModule.dob).format("YYYY-MM-DD")
      : this.getModule.dob;
    this.getModule.onboardingDate = !(this.getModule.onboardingDate == null)
      ? moment(this.getModule.onboardingDate).format("YYYY-MM-DD")
      : this.getModule.onboardingDate;
    this.getModule.idValidity = !(this.getModule.idValidity == null)
      ? moment(this.getModule.idValidity).format("YYYY-MM-DD")
      : this.getModule.idValidity;
  },
  created() {
    this.getModule.dob = !(this.getModule.dob == null)
      ? moment(this.getModule.dob).format("YYYY-MM-DD")
      : this.getModule.dob;
    this.getModule.onboardingDate = !(this.getModule.onboardingDate == null)
      ? moment(this.getModule.onboardingDate).format("YYYY-MM-DD")
      : this.getModule.onboardingDate;
    this.getModule.idValidity = !(this.getModule.idValidity == null)
      ? moment(this.getModule.idValidity).format("YYYY-MM-DD")
      : this.getModule.idValidity;
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
