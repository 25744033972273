<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <a @click.prevent="routeBack">
          <i class="fa fa-caret-left"></i>
          Back
        </a>
        <h1>Operator</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form id="operator_form" method="POST" @submit.prevent>
            <div class="row">
              <div class="col-md-3">
                <AboutBox :id="parseInt(id)" />
              </div>

              <div class="col-md-9">
                <OperatorTab :id="parseInt(id)" />

                <Note view="Operator" :id="parseInt(id)" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="enrollDriverModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Enroll Driver</h4>
          </div>
          <div class="modal-body">
            <table
              id="partners_enroll_table"
              :data-operator-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutBox from "@/components/AboutBox.vue";
import OperatorTab from "@/components/OperatorTab.vue";
import Note from "@/components/Note.vue";
import store from "@/store/store";
import { mapState } from "vuex";

let lastRoute = {
  name: null,
  fullPath: null
};
export default {
  components: {
    AboutBox,
    Note,
    OperatorTab
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false
    };
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    lastRoute.name = routeFrom.name;
    lastRoute.fullPath = routeFrom.fullPath;
    console.log("loading...");
    store.dispatch("fleet/getFleets");
    store.dispatch("operator/getOperatorFleets", routeTo.params.id);
    store.dispatch("driver/getDrivers");
    store.dispatch("operator/getOperatorDrivers", routeTo.params.id);
    store
      .dispatch("operator/getOperator", routeTo.params.id)
      .then(response => {
        return store.dispatch("region/getRegions").then(() => {
          if (!response.state) return next();
          return store
            .dispatch(
              "province/getProvincesByRegion",
              response.state.slice(0, 2)
            )
            .then(() => {
              if (!response.province) return next();
              return store
                .dispatch(
                  "city/getCitiesByProvince",
                  response.province.slice(0, 4)
                )
                .then(() => {
                  if (!response.city) return next();
                  return store
                    .dispatch(
                      "barangay/getBarangaysByCity",
                      response.city.slice(0, 6)
                    )
                    .then(() => {
                      next();
                      console.log("done loading...");
                    });
                });
            });
        });
      })
      .catch(() => {
        if (navigator.onLine) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  methods: {
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;

      if (lastRoute.name) {
        if (this.canBack) {
          this.$router.go(-1);
          if (!navigator.onLine) {
            this.isRouting = false;
          } else {
            this.$store.dispatch("cannotBack");
          }
        } else {
          this.$router.replace({ name: "operators" });
        }
      } else {
        this.$router.replace({ name: "operators" });
      }
    },
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let isSelected = vm.operatorDrivers.find(
                oD => oD.userId === row.userId
              )
                ? true
                : false;
              let icon = isSelected ? "fa-check" : "fa-ban";
              let btn = isSelected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-is-selected="${isSelected}"
                              data-assign-driver-id="${row.userId}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#partners_enroll_table").on("click", "[data-assign-driver-id]", e => {
        if (vm.isSubmitting) return;
        let { isSelected, assignDriverId } = e.target.dataset;
        isSelected = JSON.parse(isSelected);
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        if (!isSelected) {
          store
            .dispatch("operator/assignOperatorDriver", {
              operatorId: vm.id,
              driverId: assignDriverId
            })
            .then(() => {
              $("#operator_driver_table")
                .DataTable()
                .ajax.reload(() => {
                  vm.dataTable.DataTable().ajax.reload(() => {
                    vm.isSubmitting = false;
                  }, false);
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        } else {
          store
            .dispatch("operator/removeOperatorDriver", {
              operatorId: vm.id,
              driverId: assignDriverId
            })
            .then(() => {
              $("#operator_driver_table")
                .DataTable()
                .ajax.reload(() => {
                  vm.dataTable.DataTable().ajax.reload(() => {
                    vm.isSubmitting = false;
                  }, false);
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      });
    }
  },
  mounted() {
    this.dataTable = $("#partners_enroll_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/drivers`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["user", "token"]),
    ...mapState("operator", ["operatorDrivers"]),
    ...mapState(["canBack"])
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
