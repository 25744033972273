import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getTopUp(token, id) {
    // get balance by partner
    return apiClient.get(`/api/drivers/${id}/balance`, {
      headers: {
        Authorization: token
      }
    });
  },

  addTopUp(token, id, topup) {
    // add top up
    return apiClient.put(`/api/drivers/${id}/topUp`, topup, {
      headers: {
        Authorization: token
      }
    });
  }
};
