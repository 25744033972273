<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'operators' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Operator</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div v-if="messages && messages.error" class="callout callout-danger">
          <p>{{ messages.error }}</p>
        </div>
        <form id="operator_form" method="POST" @submit.prevent="addOperator">
          <fieldset>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>First Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="firstName"
                      required="required"
                      v-model.trim="operator.firstName"
                    />
                    <small v-if="false">firstname is required!</small>
                  </td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="lastName"
                      required="required"
                      v-model.trim="operator.lastName"
                    />
                    <small v-if="false"></small>
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      required="required"
                      v-model.trim="operator.email"
                    />
                    <small v-if="false"></small>
                  </td>
                </tr>
                <tr>
                  <th>Mobile Number</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="mobileNumber"
                      required="required"
                      v-model="operator.mobileNumber"
                      @input="format($event)"
                      autocomplete="off"
                    />
                    <small v-if="false"></small>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";

export default {
  data() {
    return {
      operator: this.createNewOperator(),
      isSubmitting: false
    };
  },
  methods: {
    format(ev) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.operator.mobileNumber)) {
          this.operator.mobileNumber = this.operator.mobileNumber.substr(
            0,
            this.operator.mobileNumber.lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.operator.mobileNumber) &&
              this.operator.mobileNumber.length >= 4) ||
            (!plusBegin.test(this.operator.mobileNumber) && ev.data != 0) ||
            (!plusBegin.test(this.operator.mobileNumber) &&
              ev.data == 0 &&
              this.operator.mobileNumber.length >= 2)
          ) {
            if (this.operator.mobileNumber.indexOf("+0") == 0) return;
            asYouType.input(this.operator.mobileNumber);
            this.operator.mobileNumber = asYouType.getNumber().number;
          }
        }
      }
    },
    addOperator() {
      // "2019-05-16T07:42:52.401Z" date format
      if (this.isSubmitting) return;

      const answer = window.confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;

      const phoneNumber = parsePhoneNumberFromString(
        this.operator.mobileNumber,
        "PH"
      );

      if (
        this.operator.mobileNumber.length >= 5 &&
        this.operator.mobileNumber.length <= 13 &&
        phoneNumber.isValid()
      ) {
        this.$store
          .dispatch("operator/addOperator", this.operator)
          .then(operator => {
            this.$router.push(
              {
                name: "operators-edit",
                params: {
                  id: operator.id
                }
              },
              () => {
                this.operator = this.createNewOperator();
              },
              () => {
                this.isSubmitting = false;
              }
            );
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = false;
        const notification = {
          type: "error",
          message: "Invalid phone format"
        };
        this.$store.dispatch("notification/add", notification);
      }
    },
    createNewOperator() {
      const username = this.$store.state.user.username;

      return {
        // operator data structure
        lastName: null,
        firstName: null,
        email: null,
        mobileNumber: null,
        username: username
      };
    }
  },
  validations: {
    operator: {
      lastName: { required },
      firstName: { required },
      email: { required, email },
      mobileNumber: { required }
    }
  }
};
</script>

<style></style>
