import CardService from "@/services/CardService.js";

export const namespaced = true;

export const state = {
  cards: []
};

export const mutations = {
  SET_CARDS(state, cards) {
    state.cards = cards;
  },
  DELETE_CARD(state, cardToRemove) {
    state.cards = state.cards.filter(
      card => card.cardTokenId != cardToRemove.cardTokenId
    );
  }
};

export const actions = {
  getCards({ commit, rootState }, { entity, entityId }) {
    CardService.getCards(rootState.user.token, entity, entityId)
      .then(response => {
        commit("SET_CARDS", response.data);
      })
      .catch(error => {
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          if (message === "No cards found.") {
            commit("SET_CARDS", []);
          }
        }
        console.log(error.message);
      });
  },

  removeCard({ commit, rootState, dispatch }, { entity, entityId, cardId }) {
    return CardService.removeCard(
      rootState.user.token,
      entity,
      entityId,
      cardId
    )
      .then(response => {
        commit("DELETE_CARD", response.data);
        const notification = {
          type: "success",
          message: "Deleted Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: `Delete Failed, ${error.message}`
        };
        dispatch("notification/add", notification, {
          root: true
        });
        throw error;
      });
  }
};
