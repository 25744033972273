<template>
  <div class="content-wrapper">
    <section class="content-header">
      <a @click.prevent="routeBack">
        <i class="fa fa-caret-left"></i>
        Back
      </a>
      <h1>New PA/CPC</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form @submit.prevent="newPrangkisa" method="POST">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Case Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="caseNumber"
                    v-model.trim="prangkisa.caseNumber"
                    :disabled="noPrangkisa"
                    required="required"
                  />
                </td>
              </tr>
              <tr>
                <th>Certification Type</th>
                <td>
                  <select
                    name="certType"
                    class="form-control"
                    v-model="prangkisa.certType"
                  >
                    <option
                      v-for="(certType, index) in certTypes"
                      :key="index"
                      :value="certType"
                      >{{ certType }}</option
                    >
                  </select>
                </td>
              </tr>
              <!-- <tr>
                <th>Start Date</th>
                <td>
                  <input
                    type="date"
                    name="startDate"
                    class="form-control"
                    max="9999-12-31"
                    v-model="prangkisa.startDate"
                  />
                </td>
              </tr> -->
              <tr>
                <th></th>
                <td>
                  <span class="text-muted small"
                    >Tick the check box if there is no PA/CPC information.</span
                  >
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="noPrangkisa"
                        v-model="noPrangkisa"
                        @change="checkPrangkisa"
                      />
                      No PA/CPC
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      certTypes: ["PA", "CPC"],
      prangkisa: this.createNewPrangkisa(),
      noPrangkisa: false,
      isRouting: false,
      isSubmitting: false
    };
  },
  methods: {
    createNewPrangkisa() {
      return {
        operatorId: this.id,
        caseNumber: null,
        certType: "PA",
        startDate: null
      };
    },
    checkPrangkisa() {
      if (this.noPrangkisa) {
        this.prangkisa.caseNumber = "IN PROGRESS";
      } else {
        this.prangkisa.caseNumber = "";
      }
    },
    newPrangkisa() {
      if (this.isSubmitting) return;
      let answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("prangkisa/addPrangkisa", this.prangkisa)
        .then(() => {
          this.$router.push(
            {
              name: "operators-edit",
              params: { id: this.id }
            },
            null,
            () => {
              this.isSubmitting = false;
            }
          );
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;
      this.$router.push(
        { name: "operators-edit", params: { id: this.id } },
        null,
        () => {
          this.isRouting = false;
        }
      );
    }
  }
};
</script>

<style></style>
