<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'partners' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Partner</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div v-if="messages && messages.error" class="callout callout-danger">
          <p>{{ messages.error }}</p>
        </div>

        <form id="schedule_form" method="POST" @submit.prevent="addPartner">
          <fieldset>
            <legend>Personal Details</legend>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>First Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="firstName"
                      required
                      v-model="partner.firstName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="lastName"
                      required
                      v-model="partner.lastName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Email Address</th>
                  <td>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      required
                      v-model="partner.email"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Login Number</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="loginNumber"
                      v-model="partner.loginNumber"
                      @input="format($event, 'loginNumber')"
                      autocomplete="off"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Paymaya Number</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="paymayaNumber"
                      v-model="partner.paymayaNumber"
                      @input="format($event, 'paymayaNumber')"
                      autocomplete="off"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";

export default {
  data() {
    return {
      old: {
        firstName: "foo",
        lastName: "bar",
        email: "foo_bar@shit.com",
        loginNumber: 123,
        paymayaNumber: 456
      },
      partner: this.createNewPartner(),
      messages: {
        error: null
      },
      isSubmitting: false
    };
  },
  methods: {
    format(ev, model) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.partner[model])) {
          this.partner[model] = this.partner[model].substr(
            0,
            this.partner[model].lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.partner[model]) &&
              this.partner[model].length >= 4) ||
            (!plusBegin.test(this.partner[model]) && ev.data != 0) ||
            (!plusBegin.test(this.partner[model]) &&
              ev.data == 0 &&
              this.partner[model].length >= 2)
          ) {
            if (this.partner[model].indexOf("+0") == 0) return;
            asYouType.input(this.partner[model]);
            this.partner[model] = asYouType.getNumber().number;
          }
        }
      }
    },
    addPartner() {
      // "2019-05-16T07:42:52.401Z" date format
      if (this.isSubmitting) return;
      const answer = window.confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;

      const loginNumber = this.partner.loginNumber
        ? parsePhoneNumberFromString(this.partner.loginNumber, "PH")
        : this.partnerloginNumber;
      const paymayaNumber = this.partner.paymayaNumber
        ? parsePhoneNumberFromString(this.partner.paymayaNumber, "PH")
        : this.partner.paymayaNumber;

      if (
        (!this.partner.loginNumber ||
          (this.partner.loginNumber.length >= 5 &&
            this.partner.loginNumber.length <= 13 &&
            loginNumber.isValid())) &&
        (!this.partner.paymayaNumber ||
          (this.partner.paymayaNumber.length >= 5 &&
            this.partner.paymayaNumber.length <= 13 &&
            paymayaNumber.isValid()))
      ) {
        this.$store
          .dispatch("driver/addDriver", this.partner)
          .then(partner => {
            console.log(partner);
            this.$router.push(
              {
                name: "partners-edit",
                params: {
                  id: partner.userId
                }
              },
              () => {
                this.partner = this.createNewPartner();
              },
              () => {
                this.isSubmitting = false;
              }
            );
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = false;
        const notification = {
          type: "error",
          message: "Invalid phone format"
        };
        this.$store.dispatch("notification/add", notification);
      }
    },
    createNewPartner() {
      return {
        // partner data structure
        lastName: null,
        firstName: null,
        email: null,
        loginNumber: null,
        paymayaNumber: null
      };
    }
  }
};
</script>

<style></style>
