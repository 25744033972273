<template>
  <div class="content-wrapper">
    <section class="content">
      <h1>404 Page Not Found!</h1>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  margin: 0;
  margin-left: 40px;
  margin-top: 20px;
  font-size: 4em;
}
</style>
