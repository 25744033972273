<template>
  <div class="tab-pane" id="vehicles">
    <fieldset id="fpartnervehicle" class="form-group">
      <legend>Vehicle(s)</legend>
      <table
        id="partner_vehicle_table"
        :data-driver-id="id"
        class="table table-hover"
        width="100%"
      >
        <thead>
          <tr>
            <th>Vehicle</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      isRouting: false,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["plateNumber", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: false,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}` // propertyName ASC|DESC
              // where: {
              //   q: d.search.value,
              // }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            vm.$store.dispatch("driver/getDriverVehicles", {
              data: response.data
            }); // store data to driver module's state

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "plateNumber" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              let button = `<a id="pVlink"
                               class="datalink"
                               data-operator-id="${row.operatorId}"
                               data-vehicle-id="${row.id}">
                              <i class="fa fa-fw ${
                                row.isDefault ? "fa-check text-success" : ""
                              }"></i> ${row.plateNumber}
                            </a>`;
              return button;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "PartnerVehicle")
                ? "visible"
                : "hidden";
              let isSelected = row.isDefault ? true : false;
              let btn = isSelected ? "btn-success" : "btn-primary";
              let icon = isSelected ? "fa-trash" : "fa-car";

              button += `<button class="btn ${btn} ${visibility}"
                                 type="button"
                                 data-is-selected="${isSelected}"
                                 data-driver-id="${
                                   dataTable[0].dataset.driverId
                                 }"
                                 data-vehicle-id="${row.id}">
                            <i class="fa ${icon} fa-fw"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;
              return button;
            }
          }
        ]
      });

      $("#partner_vehicle_table").on("click", "#pVlink", e => {
        if (vm.isRouting) return;
        vm.isRouting = true;
        let { operatorId, vehicleId } = e.target.dataset;
        vm.$router.push(
          {
            name: "operators-vehicles-edit",
            params: { id: operatorId, vid: vehicleId }
          },
          null,
          () => {
            vm.isRouting = false;
          }
        );
        e.preventDefault();
      });

      $("#partner_vehicle_table").on("click", "[data-vehicle-id]", e => {
        if (vm.isSubmitting) return;
        let { vehicleId, driverId, isSelected } = e.target.dataset;
        isSelected = JSON.parse(isSelected);
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;

        if (!isSelected) {
          return axios
            .put(
              `${
                process.env.VUE_APP_BE_API_URL
              }/api/drivers/${driverId}/vehicles/${vehicleId}`,
              {
                isDefault: true
              },
              {
                headers: {
                  Authorization: vm.token,
                  "X-Owto-Request-By": vm.user.name
                }
              }
            )
            .then(() => {
              vm.driver.vehicleId = vehicleId;
              $("#partner_vehicle_table")
                .DataTable()
                .ajax.reload(() => {
                  vm.isSubmitting = false;
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        } else {
          return axios
            .delete(
              `${
                process.env.VUE_APP_BE_API_URL
              }/api/drivers/${driverId}/vehicles/${vehicleId}`,
              {
                headers: {
                  Authorization: vm.token,
                  "X-Owto-Request-By": vm.user.name
                }
              }
            )
            .then(() => {
              vm.driver.vehicleId = null;
              $("#partner_vehicle_table")
                .DataTable()
                .ajax.reload(() => {
                  vm.isSubmitting = false;
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      });
    }
  },
  mounted() {
    this.dataTable = $("#partner_vehicle_table");
    const url = `${process.env.VUE_APP_BE_USER_API_URL}/api/drivers/${
      this.id
    }/vehicles`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["user", "token"]),
    ...mapState("driver", ["driver"])
  }
};
</script>

<style></style>
