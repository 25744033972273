import Vue from "vue";
import Router from "vue-router";
import Overview from "./views/Overview/Index.vue";

import Operator from "./views/Operator/Index.vue";
import ONew from "./views/Operator/New.vue";
import OEdit from "./views/Operator/Edit.vue"; //Operator

import VNew from "./views/Vehicle/New.vue";
import VEdit from "./views/Vehicle/Edit.vue"; //Vehicle

import PrNew from "./views/Prangkisa/New.vue";
import PrEdit from "./views/Prangkisa/Edit.vue"; //Prangkisa

import Partner from "./views/Partner/Index.vue";
import PNew from "./views/Partner/New.vue";
import PEdit from "./views/Partner/Edit.vue"; //Partner/Driver

import Rider from "./views/Rider/Index.vue";
import REdit from "./views/Rider/Edit.vue"; // Rider

import Route from "./views/Route/Index.vue";
import RouteNew from "./views/Route/New.vue";
import RouteEdit from "./views/Route/Edit.vue"; // Route

import Schedule from "./views/Schedule/Index.vue";
import ScheduleNew from "./views/Schedule/New.vue";
import ScheduleEdit from "./views/Schedule/Edit.vue"; // Schedule

import VehicleModel from "./views/VehicleModel/Index.vue";
import VMNew from "./views/VehicleModel/New.vue";
import VMEdit from "./views/VehicleModel/Edit.vue"; // Vehicle Model

import Tags from "./views/Tag/Index.vue";
import TNew from "./views/Tag/New.vue";
import TEdit from "./views/Tag/Edit.vue"; // Tag

import Variants from "./views/Variant/Index.vue";
import VarNew from "./views/Variant/New.vue";
import VarEdit from "./views/Variant/Edit.vue"; // Variant

import Poi from "./views/POI/Index.vue";
import PoiNew from "./views/POI/New.vue";
import PoiEdit from "./views/POI/Edit.vue"; // Poi

import Fleet from "./views/Fleet.vue"; // Fleet

import Employer from "./views/Employer.vue"; // Employer

import Disbursement from "./views/Disbursement/Index.vue";
import DisEdit from "./views/Disbursement/Edit.vue"; // Disbursement

import LiveMap from "./views/LiveMap.vue" // LiveMap

import AdminPanel from "./views/AdminPanel.vue";

import NotFound from "./views/404.vue";

import adalAuth from "./authentication/adalAuth.js";
import store from "./store/store";
import defineAbilitiesFor from "./authentication/ability.js";

Vue.use(Router);

// let activeUser = store.state.user.user;
var activeUser = "";
var ability = "";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "overview",
      component: Overview,
      meta: {
        permission: "",
        entity: ""
      }
    },
    {
      path: "/operators",
      name: "operators",
      component: Operator,
      meta: {
        permission: "read",
        entity: "Operator"
      }
    },
    {
      path: "/operators/new",
      name: "operators-new",
      component: ONew,
      meta: {
        permission: "create",
        entity: "Operator"
      }
    },
    {
      path: "/operators/:id/edit",
      name: "operators-edit",
      component: OEdit,
      meta: {
        permission: "read",
        entity: "Operator"
      },
      props: true
    }, // Operator
    {
      path: "/operators/:id/vehicles/new",
      name: "operators-vehicles-new",
      component: VNew,
      meta: {
        permission: "create",
        entity: "OperatorVehicle"
      },
      props: true
    },
    {
      path: "/operators/:id/vehicles/:vid/edit",
      name: "operators-vehicles-edit",
      component: VEdit,
      meta: {
        permission: "update",
        entity: "OperatorVehicle"
      },
      props: true
    }, // Vehicle
    {
      path: "/operators/:id/prangkisa/new",
      name: "prangkisa-new",
      component: PrNew,
      meta: {
        permission: "create",
        entity: "OperatorPrangkisa"
      },
      props: true
    },
    {
      path: "/operators/:id/prangkisa/:prid/edit",
      name: "prangkisa-edit",
      component: PrEdit,
      meta: {
        permission: "update",
        entity: "OperatorPrangkisa"
      },
      props: true
    }, // Prangkisa
    {
      path: "/partners",
      name: "partners",
      component: Partner,
      meta: {
        permission: "read",
        entity: "Partner"
      }
    },
    {
      path: "/partners/new",
      name: "partners-new",
      component: PNew,
      meta: {
        permission: "create",
        entity: "Partner"
      }
    },
    {
      path: "/partners/:id/edit",
      name: "partners-edit",
      component: PEdit,
      meta: {
        permission: "read",
        entity: "Partner"
      },
      props: true
    }, // Partners
    {
      path: "/riders",
      name: "riders",
      component: Rider,
      meta: {
        permission: "read",
        entity: "Rider"
      }
    },
    {
      path: "/riders/:id/edit",
      name: "riders-edit",
      component: REdit,
      meta: {
        permission: "read",
        entity: "Rider"
      },
      props: true
    }, // Rider
    {
      path: "/routes",
      name: "routes",
      component: Route,
      meta: {
        permission: "read",
        entity: "Route"
      }
    },
    {
      path: "/routes/new",
      name: "routes-new",
      component: RouteNew,
      meta: {
        permission: "create",
        entity: "Route"
      }
    },
    {
      path: "/routes/:id/edit",
      name: "routes-edit",
      component: RouteEdit,
      meta: {
        permission: "update",
        entity: "Route"
      },
      props: true
    }, // Route
    {
      path: "/schedules",
      name: "schedules",
      component: Schedule,
      meta: {
        permission: "read",
        entity: "Schedule"
      }
    },
    {
      path: "/schedules/new",
      name: "schedules-new",
      component: ScheduleNew,
      meta: {
        permission: "read",
        entity: "Schedule"
      }
    },
    {
      path: "/schedules/:id/edit",
      name: "schedules-edit",
      component: ScheduleEdit,
      meta: {
        permission: "read",
        entity: "Schedule"
      },
      props: true
    }, // Schedule
    {
      path: "/vehicle-models",
      name: "vehicle-models",
      component: VehicleModel,
      meta: {
        permission: "read",
        entity: "VehicleModel"
      }
    },
    {
      path: "/vehicle-models/new",
      name: "vehicle-models-new",
      component: VMNew,
      meta: {
        permission: "create",
        entity: "VehicleModel"
      }
    },
    {
      path: "/vehicle-models/:id/edit",
      name: "vehicle-models-edit",
      component: VMEdit,
      meta: {
        permission: "update",
        entity: "VehicleModel"
      },
      props: true
    }, // Vehicle Model
    {
      path: "/tags",
      name: "tags",
      component: Tags,
      meta: {
        permission: "read",
        entity: "Tag"
      }
    },
    // {
    //   path: "/tags/new",
    //   name: "tags-new",
    //   component: TNew,
    //   meta: {
    //     permission: "create",
    //     entity: "Tag"
    //   }
    // },
    // {
    //   path: "/tags/:id/edit",
    //   name: "tags-edit",
    //   component: TEdit,
    //   meta: {
    //     permission: "update",
    //     entity: "Tag"
    //   },
    //   props: true
    // }, // Tag
    {
      path: "/variants",
      name: "variants",
      component: Variants,
      meta: {
        permission: "read",
        entity: "Variant"
      }
    },
    {
      path: "/variants/new",
      name: "variants-new",
      component: VarNew,
      meta: {
        permission: "create",
        entity: "Variant"
      }
    },
    {
      path: "/variants/:id/edit",
      name: "variants-edit",
      component: VarEdit,
      meta: {
        permission: "read",
        entity: "Variant"
      },
      props: true
    }, // Variant
    {
      path: "/pois",
      name: "pois",
      component: Poi,
      meta: {
        permission: "read",
        entity: "Poi"
      }
    },
    // {
    //   path: "/pois/new",
    //   name: "pois-new",
    //   component: PoiNew,
    //   meta: {
    //     permission: "create",
    //     entity: "Poi"
    //   }
    // },
    // {
    //   path: "/pois/:id/edit",
    //   name: "pois-edit",
    //   component: PoiEdit,
    //   meta: {
    //     permission: "update",
    //     entity: "Poi"
    //   },
    //   props: true
    // }, // Poi
    {
      path: "/fleets",
      name: "fleets",
      component: Fleet,
      meta: {
        permission: "read",
        entity: "Fleet"
      },
      props: true
    }, // Fleet
    {
      path: "/employers",
      name: "employers",
      component: Employer,
      meta: {
        permission: "read",
        entity: "Employer"
      }
    }, // Employer
    {
      path: "/disbursements",
      name: "disbursements",
      component: Disbursement,
      meta: {
        permission: "read",
        entity: "Disbursement"
      }
    },
    {
      path: "/disbursements/:id/reports",
      name: "disbursements-edit",
      component: DisEdit,
      meta: {
        permission: "read",
        entity: "Disbursement"
      },
      props: true
    }, // Employer
    {
      path: "/livemap",
      name: "livemap",
      component: LiveMap,
      meta: {
        permission: "read",
        entity: "Livemap"
      }
    },
    {
      path: "/admin-panel",
      name: "admin-panel",
      component: AdminPanel,
      meta: {
        permission: "manage",
        entity: "all"
      }
    },
    {
      path: "*",
      name: "not-found",
      component: NotFound
    }
  ],
  ini() {
    return new Promise((resolve, reject) => {
      adalAuth.initialize().then(_ => {
        activeUser = store.state.user.user;
        ability = defineAbilitiesFor(
          activeUser.role,
          activeUser.entities ? activeUser.entities : [""]
        );
        resolve(ability);
      });
    });
  }
});

router.beforeEach((routeTo, routeFrom, next) => {
  if (
    routeTo.matched.some(record => record.meta.permission || record.meta.entity)
  ) {
    routeTo.matched.some(record => {
      if (ability.can(record.meta.permission, record.meta.entity)) {
        next();
      } else {
        next(routeFrom.path || routeTo.name);
      }
    });
  } else {
    if (routeTo.matched[0].name === "overview") {
      next();
    } else {
      if (routeTo.matched[0].name === "not-found") {
        next();
      } else {
        next("/");
      }
    }
  }
});

export default router;
