<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li
        v-for="(pTab, index) in partnerTabs"
        :key="index"
        @click="selectedPartnerTab = pTab[0]"
        :class="{ active: selectedPartnerTab === pTab[0] }"
        data-toggle="tab"
      >
        <a
          v-if="
            pTab[1].constructor == Array
              ? $can('read', pTab[1][0]) || $can('read', pTab[1][1])
              : $can('read', pTab[1])
          "
          >{{ pTab[0] }}</a
        >
      </li>
    </ul>

    <div class="tab-content">
      <ProfileTab
        :class="{ active: selectedPartnerTab == 'Profile' }"
        view="Partner"
        :id="id"
      />

      <Operator
        :class="{ active: selectedPartnerTab == 'Operators' }"
        :id="id"
      />

      <PartnerVehicle
        :class="{ active: selectedPartnerTab == 'Vehicles' }"
        :id="id"
      />

      <TripHistory
        :class="{ active: selectedPartnerTab == 'Trip History' }"
        :id="id"
        view="Partner"
      />

      <LocationHistory
        :class="{ active: selectedPartnerTab == 'Location History' }"
        :id="id"
      />

      <WalletTab :class="{ active: selectedPartnerTab == 'Wallet' }" :id="id" />

      <Card
        :class="{ active: selectedPartnerTab == 'Card' }"
        :id="id"
        view="Drivers"
      />
    </div>
  </div>
</template>

<script>
import ProfileTab from "./tabs/ProfileTab.vue";
import Operator from "./tabs/Operator.vue";
import PartnerVehicle from "./tabs/PartnerVehicle.vue";
import TripHistory from "./tabs/TripHistory.vue";
import LocationHistory from "./tabs/LocationHistory.vue";
import WalletTab from "./tabs/WalletTab.vue";
import Card from "./tabs/Card.vue";

export default {
  components: {
    LocationHistory,
    Operator,
    TripHistory,
    PartnerVehicle,
    ProfileTab,
    WalletTab,
    Card
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      partnerTabs: [
        ["Profile", "Partner"],
        ["Operators", "PartnerOperator"],
        ["Vehicles", "PartnerVehicle"],
        ["Trip History", "PartnerTripHistory"],
        ["Location History", "PartnerLocationHistor"],
        ["Wallet", ["TopUp", "WalletTransaction"]],
        ["Card", "PartnerCard"]
      ],
      selectedPartnerTab: "Profile"
    };
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
