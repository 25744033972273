import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getProvinces(token) {
    return apiClient.get("/api/provinces", {
      headers: {
        Authorization: token
      }
    });
  },
  getProvincesByRegion(token, rId) {
    return apiClient.get("/api/provinces?filter[where][regionCode]=" + rId, {
      headers: {
        Authorization: token
      }
    });
  }
};
