<template>
  <div class="tab-pane" id="contact">
    <fieldset>
      <legend>Contact Details</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>Email</th>
            <td>
              <input
                type="email"
                class="form-control"
                name="email"
                v-model.trim="module.email"
              />
            </td>
          </tr>
          <tr>
            <th>Mobile Number</th>
            <td>
              <!-- pattern="(\+?\d{2}?\s?\d{3}\s?\d{3}\s?\d{4})|([0]\d{3}\s?\d{3}\s?\d{4})" -->
              <input
                type="text"
                class="form-control"
                name="mobileNumber"
                v-model="module.mobileNumber"
                autocomplete="off"
                @input="format($event)"
              />
            </td>
          </tr>
          <template v-if="view != 'Rider'">
            <tr>
              <th>Landline Number</th>
              <td>
                <input
                  type="number"
                  class="form-control"
                  name="landlineNumber"
                  v-model.number="module.landlineNumber"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { AsYouType } from "libphonenumber-js";
export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    view: String,
    permission: Boolean
  },
  methods: {
    format(ev) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.module.mobileNumber)) {
          this.module.mobileNumber = this.module.mobileNumber.substr(
            0,
            this.module.mobileNumber.lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.module.mobileNumber) &&
              this.module.mobileNumber.length >= 4) ||
            (!plusBegin.test(this.module.mobileNumber) && ev.data != 0) ||
            (!plusBegin.test(this.module.mobileNumber) &&
              ev.data == 0 &&
              this.module.mobileNumber.length >= 2)
          ) {
            if (this.module.mobileNumber.indexOf("+0") == 0) return;
            asYouType.input(this.module.mobileNumber);
            this.module.mobileNumber = asYouType.getNumber().number;
          }
        }
      }
    }
  },
  mounted() {
    $("#contact :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
