<template>
  <div class="tab-pane" id="cards">
    <fieldset id="foperator" class="form-group">
      <legend>Cards</legend>
      <table id="rider_card_table" class="table table-hover" width="100%">
        <tbody>
          <tr>
            <th>Card</th>
            <th>State</th>
            <th>Last 4</th>
            <th></th>
          </tr>
          <tr v-for="(card, index) in cards" :key="index">
            <td>
              <img :src="myCardSrc(card.cardType)" :alt="card.cardType" />
            </td>
            <td>{{ card.state }}</td>
            <td>{{ card.last4 }}</td>
            <td>
              <button
                type="button"
                class="btn btn-primary"
                @click="removeCard(card.cardTokenId)"
              >
                <i
                  class="fa fa-trash-o"
                  aria-hidden="true"
                  style="pointer-events: none"
                ></i>
              </button>
            </td>
          </tr>
          <tr v-if="!cards.length">
            <td colspan="4">No cards found</td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";
import masterCard from "@/assets/images/master-card.png";
import visa from "@/assets/images/visa.png";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false
    };
  },
  created() {
    this.$store.dispatch("card/getCards", {
      entity: this.view.toLowerCase(),
      entityId: this.id
    });
    console.log(this.view);
  },
  methods: {
    myCardSrc(ct) {
      return ct === "master-card" ? masterCard : visa;
    },
    removeCard(cardId) {
      if (this.isSubmitting) return;
      const answer = window.confirm("are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("card/removeCard", {
          entity: this.view.toLowerCase(),
          entityId: this.id,
          cardId: cardId
        })
        .then(() => {
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  },
  computed: {
    ...mapState("card", ["cards"])
  }
};
</script>

<style></style>
