import RiderService from "@/services/RiderService.js";
import { root } from "postcss-selector-parser";

export const namespaced = true;

export const state = {
  rider: null,
  riders: [],
  riderEmployers: []
};

export const mutations = {
  SET_RIDERS(state, riders) {
    state.riders = riders;
  },
  SET_RIDER(state, rider) {
    state.rider = rider;
  },
  EDIT_RIDER(state, rider) {
    state.rider = rider;
  },
  SET_RIDER_EMPLOYERS(state, employers) {
    state.riderEmployers = employers;
  },
  ADD_RIDER_EMPLOYER(state, employer) {
    state.riderEmployers.push(employer);
  },
  REMOVE_RIDER_EMPLOYER(state, employerId) {
    state.riderEmployers = state.riderEmployers.filter(rE => {
      return rE.id != employerId;
    });
  }
};

export const actions = {
  getRider({ commit, rootState, dispatch }, id) {
    return RiderService.getRider(rootState.user.token, id)
      .then(response => {
        commit("SET_RIDER", response.data);
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editRider({ commit, rootState, dispatch }, rider) {
    return RiderService.editRider(
      rootState.user.token,
      rootState.user.user.name,
      rider.userId,
      rider
    )
      .then(response => {
        commit("EDIT_RIDER", response.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getRiderEmployers({ commit, rootState, dispatch }, id) {
    return RiderService.getRiderEmployers(rootState.user.token, id)
      .then(response => {
        commit("SET_RIDER_EMPLOYERS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignRiderEmployer(
    { commit, rootState, dispatch },
    { riderId, employerId }
  ) {
    return RiderService.assignRiderEmployer(
      rootState.user.token,
      riderId,
      employerId
    )
      .then(response => {
        let employer = rootState.employer.employers.find(e => {
          return e.id === response.data.employerId;
        });
        commit("ADD_RIDER_EMPLOYER", employer);
        const notification = {
          type: "success",
          message: "Assigned Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  deleteRiderEmployer(
    { commit, rootState, dispatch },
    { riderId, employerId }
  ) {
    return RiderService.deleteRiderEmployer(
      rootState.user.token,
      riderId,
      employerId
    )
      .then(() => {
        commit("REMOVE_RIDER_EMPLOYER", employerId);
        const notification = {
          type: "success",
          message: "Remove Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  sendEmail({ rootState, dispatch }, bookingId) {
    return RiderService.sendEmail(rootState.user.token, bookingId)
      .then(() => {
        const notification = {
          type: "success",
          message: "Send Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Send Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};

export const getters = {
  govDiscount(state) {
    if (state.rider) {
      return state.rider.entitlements.find(e => e.type === "GOV_DISCOUNT");
    }
  }
};
