<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'pois' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>P.O.I.</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form id="pois_form" method="POST" @submit.prevent="addPoi">
          <div class="row">
            <div class="col-md-12">
              <fieldset>
                <legend>Details</legend>
                <table class="table table-settings">
                  <tbody>
                    <tr>
                      <th>Label</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="label"
                          v-model="poi.label"
                          required="required"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>
                        <textarea
                          rows="5"
                          class="form-control"
                          name="description"
                          v-model="poi.description"
                          required="required"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <th>Coordinates (longitude)</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="coordinates"
                          v-model="poi.coordinates.lng"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Coordinates (latitude)</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="coordinates"
                          v-model="poi.coordinates.lat"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Parent</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="parent"
                          v-model="poi.parent"
                          placeholder="Input POI ID"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>
                        <select
                          class="form-control"
                          name="type"
                          required="required"
                          v-model="poi.type"
                        >
                          <option
                            v-for="(type, index) in types"
                            :key="index"
                            :value="type"
                            >{{ type }}</option
                          >
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      types: ["BUILDING"],
      poi: this.createNewPoi()
    };
  },
  methods: {
    createNewPoi() {
      return {
        label: "",
        description: "",
        coordinates: {
          lng: "",
          lat: ""
        },
        parent: "",
        type: "BUILDING"
      };
    },
    addPoi() {
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.$store.dispatch("poi/addPoi", this.poi).then(() => {
        this.$router.push({ name: "pois" });
      });
    }
  }
};
</script>

<style></style>
