import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getFleets(token) {
    // get fleets
    return apiClient.get("/api/fleets", {
      headers: {
        Authorization: token
      }
    });
  },

  getFleet(token, id) {
    // get fleet
    return apiClient.get("/api/fleets/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  addFleet(token, fleet) {
    // add fleet
    return apiClient.post("/api/fleets", fleet, {
      headers: {
        Authorization: token
      }
    });
  },

  editFleet(token, id, fleet) {
    // edit fleet
    return apiClient.patch("/api/fleets/" + id, fleet, {
      headers: {
        Authorization: token
      }
    });
  },

  getFleetOperators(token, id) {
    // get fleet operators
    return apiClient.get("/api/fleets/" + id + "/operators", {
      headers: {
        Authorization: token
      }
    });
  },

  assignFleetOperator(token, fleetId, operatorId) {
    // assign fleet operator
    return apiClient.post(
      `/api/fleets/${fleetId}/operators`,
      {
        operatorId: operatorId
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  removeFleetOperator(token, fleetId, operatorId) {
    // remove fleet operator
    return apiClient.delete(`/api/fleets/${fleetId}/operators/${operatorId}`, {
      headers: {
        Authorization: token
      }
    });
  },

  getFleetsByPartner(token, id) {
    // get fleets by partner
    return apiClient.get(`/api/drivers/${id}/fleets`, {
      headers: {
        Authorization: token
      }
    });
  }
};
