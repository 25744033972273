<template>
  <div id="app" class="wrapper">
    <NotificationContainer />
    <Header />

    <Sidebar />

    <!-- <Content />-->
    <router-view />

    <Footer />

    <!-- </div>
    <! ./wrapper-->

    <!-- {% block modal %}
      {% endblock %}

    {% include "Partial/foot.njk" %}-->
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.min.js";
import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar";
// import Content from "@/components/Content";
import Footer from "@/components/Footer";
import NotificationContainer from "@/components/NotificationContainer.vue";

export default {
  components: {
    Header,
    Sidebar,
    Footer,
    NotificationContainer
  },
  created() {
    console.log(window);
  }
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.css";
/* #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
