<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <h1>
          Employers
          <button
            v-if="$can('create', 'Employer')"
            class="btn btn-primary btn-sm"
            @click="manage(null)"
          >
            Add
          </button>
        </h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <div class="col-md-6">
            <div class="box box-primary">
              <div class="box-body">
                <table
                  id="employer_table"
                  class="table table-hover"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div class="col-md-6" v-if="managing">
            <div class="box box-primary">
              <div class="box-body">
                <form id="employer_form" @submit.prevent="submit">
                  <fieldset>
                    <legend>
                      Details
                      <span
                        @click="managing = !managing"
                        class="tag-details-close"
                        >&times;</span
                      >
                    </legend>
                    <table class="table table-settings">
                      <tbody>
                        <tr>
                          <th>
                            <a
                              id="employer-rider-btn"
                              class="btn btn-primary btn-sm"
                              data-toggle="modal"
                              data-target="#employerRider"
                              v-show="id"
                              >Riders</a
                            >
                          </th>
                        </tr>
                        <tr>
                          <th>Company Name</th>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model.trim="myEmployer.companyName"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>
                            <input
                              type="email"
                              class="form-control"
                              name="name"
                              v-model.trim="myEmployer.email"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>First Name</th>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model.trim="myEmployer.repFirstName"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Middle Name</th>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model.trim="myEmployer.repMiddleName"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Last Name</th>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              v-model.trim="myEmployer.repLastName"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>

                  <div class="form-group">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="employerRider"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Assign Rider</h4>
          </div>
          <div class="modal-body">
            <table
              id="employer_rider_table"
              :data-rider-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store.js";
import axios from "axios";
import { mapState } from "vuex";

export default {
  beforeRouteEnter(to, from, next) {
    axios
      .all([
        store.dispatch("employer/getEmployers"),
        store.dispatch("rider/getRiders")
      ])
      .then(
        axios.spread(() => {
          next();
        })
      )
      .catch(() => {
        next(false);
      });
  },
  computed: {
    ...mapState("employer", ["employers", "employer"]),
    ...mapState("rider", ["riders"]),
    ...mapState("user", ["token"])
  },
  data() {
    return {
      myEmployer: this.createNewEmployer(),
      eDataTable: null,
      rDataTable: null,
      managing: false,
      selectedId: null,
      isSelecting: false,
      isSubmitting: false
    };
  },
  methods: {
    manage(id) {
      if (this.isSelecting) return;
      this.isSelecting = true;
      this.selectedId = id;
      this.managing = false;
      if (id) {
        this.$store
          .dispatch("employer/getEmployer", id)
          .then(() => {
            this.myEmployer = { ...this.employer };
            //this.rDataTable.DataTable().ajax.reload(() => {});
            this.isSelecting = false;
            this.managing = true;
          })
          .catch(() => {
            this.isSelecting = false;
          });
      } else {
        this.myEmployer = { ...this.createNewEmployer() };
        this.isSelecting = false;
        this.managing = true;
      }
    },
    submit() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;

      if (!this.selectedId) {
        this.$store
          .dispatch("employer/addEmployer", this.myEmployer)
          .then(() => {
            this.eDataTable.DataTable().ajax.reload(() => {
              this.isSubmitting = false;
              this.managing = false;
            });
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      } else {
        this.$store
          .dispatch("employer/editEmployer", this.myEmployer)
          .then(() => {
            this.eDataTable.DataTable().ajax.reload(() => {
              this.isSubmitting = false;
              this.managing = false;
            });
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      }
    },
    createNewEmployer() {
      return {
        companyName: null,
        email: null,
        repFirstName: null,
        repMiddleName: null,
        repLastName: null
      };
    },
    employerTable(dataTable, url) {
      let dtColumns = ["repLastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.length,
              recordsFiltered: response.length,
              data: response
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "repLastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.repLastName}, ${
                row.repFirstName
              } ${row.repMiddleName || ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "Employer")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-employer-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              return button;
            }
          }
        ]
      });

      $("#employer_table").on("click", "[data-employer-id]", e => {
        let { employerId } = e.target.dataset;
        vm.manage(employerId);
        e.preventDefault();
      });
    },
    riderTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let selected = vm.employerRiders.find(fO => {
                return fO.id === row.id;
              })
                ? true
                : false;
              let icon = selected ? "fa-check" : "fa-ban";
              let btn = selected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              id="aeFO"
                              type="button"
                              data-is-selected="${selected}"
                              data-fleet-operator-id="${row.id}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#fleet_operator_table").on("click", "#aeFO", e => {
        if (vm.isAssigning) return;
        let { isSelected, fleetOperatorId } = e.target.dataset;
        isSelected = JSON.parse(isSelected);
        const answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isAssigning = true;
        if (!isSelected) {
          store
            .dispatch("fleet/assignFleetOperator", {
              fleetId: vm.id,
              operatorId: fleetOperatorId
            })
            .then(() => {
              vm.dataTableF.DataTable().ajax.reload(() => {
                vm.isAssigning = false;
              });
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        } else {
          store
            .dispatch("fleet/removeFleetOperator", {
              fleetId: vm.id,
              operatorId: fleetOperatorId
            })
            .then(() => {
              vm.dataTableF.DataTable().ajax.reload(() => {
                vm.isAssigning = false;
              });
            })
            .catch(() => {
              vm.isAssigning = false;
            });
        }
      });
    }
  },
  mounted() {
    this.eDataTable = $("#employer_table");
    const eUrl = `${process.env.VUE_APP_BE_API_URL}/api/employers`;
    this.employerTable(this.eDataTable, eUrl);

    // this.rDataTable = $("#employer_rider_table");
    // const rUrl = `${process.env.VUE_APP_BE_API_URL}/api/riders`;
    // this.riderTable(this.rDataTable, rUrl);
  }
};
</script>
