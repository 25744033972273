<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'variants' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Variant</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form id="schedule_form" method="POST" @submit.prevent="addVariant">
          <fieldset>
            <legend>Description</legend>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>Variant Code</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="variantCode"
                      required="required"
                      v-model.trim="variant.variantCode"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Variant Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="variantName"
                      required="required"
                      v-model.trim="variant.variantName"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Fare Matrix</legend>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>Base Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="base"
                      required="required"
                      v-model.number="variant.base"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Per Kilometre Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="perKm"
                      required="required"
                      v-model.number="variant.perKm"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Per Minute Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="perMinute"
                      required="required"
                      v-model.number="variant.perMinute"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      variant: this.createNewVariant(),
      isSubmitting: false
    };
  },
  methods: {
    createNewVariant() {
      return {
        variantCode: "",
        variantName: "",
        base: 0,
        perKm: 0,
        perMinute: 0
      };
    },
    addVariant() {
      if (this.isSubmitting) return;
      const answer = window.confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("variant/addVariant", this.variant)
        .then(() => {
          this.$router.push({ name: "variants" }, null, () => {
            this.isSubmitting = false;
          });
          this.variant = this.createNewVariant();
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style></style>
