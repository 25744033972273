<template>
  <div class="tab-pane" id="locations">
    <div class="form-group">
      <input type="text" name="datetimes" class="form-control">
      <div id="location_history_map" style="width: 100%;height:500px;" :data-driver-id="id"></div>
    </div>
  </div>
</template>

<script>
import ol from "ol";
import Map from "ol/Map.js";
import View from "ol/View.js";
import TileLayer from "ol/layer/Tile.js";
import OSM from "ol/source/OSM";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        {
          icon: "bubble_chart",
          title: "Inspire"
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js"
    };
  },
  methods: {
    initMap() {
      var myMap = new ol.Map({
        layers: [
          new ol.layer.Tile({
            source: new ol.source.OSM()
          })
        ],
        target: "map",
        view: new ol.View({
          center: [0, 0],
          zoom: 2
        })
      });
    }
  },
  mounted() {
    let map = new Map({
      target: "location_history_map",
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        center: [0, 0],
        zoom: 2
      })
    });
  },
  created() {
    console.log(Map);
    console.log(View);
    console.log(TileLayer);
  }
};
</script>

<style></style>
