import DriverService from "@/services/DriverService.js";

export const namespaced = true;

export const state = {
  driver: null,
  drivers: [],
  driverOperators: [],
  driverTags: [],
  driverStatus: null,
  driverStatusRequest: "ok",
  transactionDescriptions: [],
  driverVehicles: []
};

export const mutations = {
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers;
  },
  SET_DRIVER(state, driver) {
    state.driver = driver;
  },
  ADD_DRIVER(state, driver) {
    state.drivers.push(driver);
  },
  EDIT_DRIVER(state, driver) {
    state.driver = { ...state.driver, ...driver };
  },
  SET_DRIVER_OPERATORS(state, driverOperators) {
    state.driverOperators = driverOperators;
  },
  ASSIGN_DRIVER_OPERATOR(state, driverOperator) {
    state.driverOperators.push(driverOperator);
  },
  REMOVE_DRIVER_OPERATOR(state, driverOperatorId) {
    state.driverOperators = state.driverOperators.filter(dO => {
      return dO.id != driverOperatorId;
    });
  },
  SET_DRIVER_TAGS(state, driverTags) {
    state.driverTags = driverTags;
  },
  ADD_DRIVER_TAG(state, driverTag) {
    state.driverTags.push(driverTag);
  },
  REMOVE_DRIVER_TAG(state, driverTag) {
    state.driverTags = state.driverTags.filter(dTag => {
      return dTag.id !== driverTag;
    });
  },
  SET_DRIVER_STATUS(state, driverStatus) {
    state.driverStatus = driverStatus.isOnline;
  },
  SET_DRIVER_STATUS_REQUEST(state, driverStatusRequest) {
    state.driverStatusRequest = driverStatusRequest;
  },
  SET_TRANSACTION_DESCRIPTIONS(state, descriptions) {
    state.transactionDescriptions = descriptions.descriptions;
  },
  SET_DRIVER_VEHICLES(state, vehicles) {
    state.driverVehicles = vehicles;
  }
};

export const actions = {
  getDrivers({ commit, rootState }) {
    //make an API call - fetch and commit datas
    return DriverService.getDrivers(rootState.user.token).then(data => {
      commit("SET_DRIVERS", data.data);
    });
  },

  getD({ commit }, data) {
    //API call done by the datatable - commit datas
    commit("SET_DRIVERS", data.data);
  },

  getDriver({ commit, rootState, dispatch }, id) {
    return DriverService.getDriver(rootState.user.token, id)
      .then(response => {
        commit("SET_DRIVER", response.data);
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addDriver({ commit, rootState, dispatch }, driver) {
    return DriverService.addDriver(
      rootState.user.token,
      rootState.user.user.name,
      driver
    )
      .then(data => {
        commit("ADD_DRIVER", data.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
        return data.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editDriver({ commit, rootState, dispatch }, driver) {
    return DriverService.editDriver(
      rootState.user.token,
      rootState.user.user.name,
      driver.userId,
      driver
    )
      .then(data => {
        commit("EDIT_DRIVER", data.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getDriverOperators({ commit, rootState, dispatch }, id) {
    return DriverService.getDriverOperators(rootState.user.token, id)
      .then(response => {
        commit("SET_DRIVER_OPERATORS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignDriverOperator(
    { commit, rootState, dispatch },
    { driverId, operator }
  ) {
    return DriverService.assignDriverOperator(
      rootState.user.token,
      driverId,
      operator.id
    )
      .then(() => {
        commit("ASSIGN_DRIVER_OPERATOR", operator);
        const notification = {
          type: "success",
          message: "Assign Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Assign Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  removeDriverOperator(
    { commit, rootState, dispatch },
    { driverId, operatorId }
  ) {
    return DriverService.removeDriverOperator(
      rootState.user.token,
      driverId,
      operatorId
    )
      .then(() => {
        commit("REMOVE_DRIVER_OPERATOR", operatorId);
        const notification = {
          type: "success",
          message: "Remove Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getDriverTags({ commit, rootState }, id) {
    DriverService.getDriverTags(rootState.user.token, id)
      .then(response => {
        let checkNull = response.data.filter(d => {
          return d;
        });
        commit("SET_DRIVER_TAGS", checkNull);
      })
      .catch(error => {
        console.log(error);
      });
  },

  addDriverTag({ commit, rootState, dispatch }, { id, tagId }) {
    return DriverService.addDriverTag(rootState.user.token, id, tagId)
      .then(response => {
        let getTag = null;
        getTag = rootState.tag.tags.find(tag => {
          return tag.id === response.data.tagId;
        });
        commit("ADD_DRIVER_TAG", getTag);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  },

  removeDriverTag({ commit, rootState, dispatch }, { id, tagId }) {
    return DriverService.removeDriverTag(rootState.user.token, id, tagId)
      .then(response => {
        commit("REMOVE_DRIVER_TAG", tagId);
        const notification = {
          type: "success",
          message: "Remove Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getDriverStatus({ commit, rootState, dispatch }, id) {
    commit("SET_DRIVER_STATUS_REQUEST", "pending");
    return DriverService.getDriverStatus(rootState.user.token, id)
      .then(response => {
        commit("SET_DRIVER_STATUS", response.data);
        commit("SET_DRIVER_STATUS_REQUEST", "ok");
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        commit("SET_DRIVER_STATUS_REQUEST", "error");
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getTransactionDescriptions({ commit, rootState }, id) {
    return DriverService.getTransactionDescriptions(
      rootState.user.token,
      id
    ).then(response => {
      commit("SET_TRANSACTION_DESCRIPTIONS", response.data);
    });
  },

  getDriverVehicles({ commit }, data) {
    //API call done by the datatable - commit datas
    commit("SET_DRIVER_VEHICLES", data.data);
  }
};

export const getters = {
  getLastId(state) {
    console.log("partner last id");
    if (state.drivers.length) {
      console.log(state.drivers[state.drivers.length - 1].userId);
      return state.drivers[state.drivers.length - 1].userId;
    }
  }
};
