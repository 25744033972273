const url = `${process.env.VUE_APP_BE_USER_API_URL}/api/upload`;

const upload = (file, options) => {
  const formData = new FormData();

  formData.append("document", file);
  formData.append("folder", options.folder);
  formData.append("publicId", options.publicId);

  return fetch(url, {
    method: "POST",
    body: formData
  });
};

export default upload;
