<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>Riders</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <!-- <div class="form-group">
          <ul class="list-inline">
            <li><a href="/schedules">PENDING</a></li>
            <li><a href="/schedules/others" class="text-muted">OTHERS</a></li>
          </ul>
        </div>-->

        <div class="box box-primary">
          <div class="box-body">
            <table id="riders_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataTable: null,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            //vm.$store.dispatch("operator/getO", { data: response.data }); // store data to operator module's state

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        // createdRow: ( row, data, dataIndex ) => {
        //   $(row).find('td:eq(0)')
        //     .attr('data-schedule-id', data.id);
        // },
        columns: [
          // {
          //   className:      'details-control text-center',
          //   orderable:      false,
          //   data:           null,
          //   defaultContent: ''
          // },
          { data: "lastName" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "Rider") ? "visible" : "hidden";

              button = `<a data-rider-edit-id="${row.userId}"
                           class="btn btn-primary ${visibility}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;
              //  button += `<btn class="btn btn-primary" type="button" data-toggle="modal" data-target="#myModal" data-cancel-schedule-id="${row.id}"><i class="fa fa-ban" aria-hidden="true" style="pointer-events: none"></i></btn>`;
              //  button += `<btn class="btn btn-primary" type="button" data-toggle="modal" data-target="#myModal" data-delete-schedule-id="${row.id}"><i class="fa fa-trash-o" aria-hidden="true" style="pointer-events: none"></i></btn>`;
              return button;
            }
          }
        ]
      });

      $("#riders_table").on("click", "[data-rider-edit-id]", e => {
        if (vm.isSubmitting) return;
        vm.isSubmitting = true;
        let { riderEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "riders-edit",
            params: { id: riderEditId }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });
    }
  },
  mounted() {
    this.dataTable = $("#riders_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/riders`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["token"])
  }
};
</script>

<style></style>
