<template>
  <div class="content-wrapper">
    <section class="content-header">
      <a @click.prevent="routeBack">
        <i class="fa fa-caret-left"></i>
        Back
      </a>
      <h1>Edit Vehicle</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <!-- {% if messages %}
        {% if messages.success %}
        <div class="callout callout-success">
          <p>{{ messages.success }}</p>
        </div>
         {% endif %}
        {% endif %}-->

        <form method="POST" @submit.prevent="editVehicle">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Case Number</th>
                <td>
                  <select
                    name="caseNumber"
                    class="form-control"
                    required="required"
                    v-model="vehicle.caseNumber"
                  >
                    <option
                      v-for="prangkisa in prangkisasByOperator"
                      :key="prangkisa.id"
                      :value="prangkisa.id"
                      >{{ prangkisa.caseNumber }}</option
                    >
                  </select>
                  <span class="text-muted small"
                    >PA/CPC ID: {{ vehicle.caseNumber }}</span
                  >
                </td>
              </tr>
              <tr>
                <th>Plate Number</th>
                <td>
                  <input
                    required="required"
                    type="text"
                    class="form-control"
                    name="plateNumber"
                    v-model.trim="vehicle.plateNumber"
                  />
                </td>
              </tr>
              <tr>
                <th>Conduction Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="conductionNumber"
                    v-model.trim="vehicle.conductionNumber"
                  />
                </td>
              </tr>
              <tr>
                <th>Engine Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="engineNumber"
                    v-model.trim="vehicle.engineNo"
                  />
                </td>
              </tr>
              <tr>
                <th>Chassis Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="chassisNumber"
                    v-model.trim="vehicle.chassisNo"
                  />
                </td>
              </tr>
              <tr>
                <th>Lon Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="lon"
                    v-model.trim="vehicle.lon"
                  />
                </td>
              </tr>
              <tr>
                <th>Car Color</th>
                <td>
                  <select
                    class="form-control"
                    name="carColor"
                    v-model="vehicle.carColor"
                    required="required"
                  >
                    <option
                      v-for="color in colors"
                      :key="color.id"
                      :value="color.color"
                      >{{ color.color }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <th>Car Model</th>
                <td>
                  <select
                    class="form-control"
                    name="carModel"
                    v-model="vehicle.carModel"
                    required="required"
                  >
                    <option
                      v-for="model in vehicleModels"
                      :key="model.id"
                      :value="model.id"
                    >
                      {{ model.modelManufacturer }} {{ model.modelMake }}
                      {{ model.modelYear }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Activated Product</th>
                <td>
                  <div
                    class="checkbox"
                    v-for="variant in variants"
                    :key="variant.id"
                  >
                    <label>
                      <input
                        type="checkbox"
                        name="activatedProduct"
                        :value="variant.id"
                        v-model="vehicle.activatedProduct"
                      />
                      {{ variant.variantName }}
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Remarks</th>
                <td>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    v-model.trim="vehicle.remarks"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>OR/CR</th>
                <td>
                  <FileUpload
                    :link="vehicle.vehicleTitle"
                    type="vehicleTitle"
                    @save="save"
                  />
                </td>
              </tr>
              <tr>
                <th>Insurance - TPL</th>
                <td>
                  <FileUpload
                    :link="vehicle.insuranceTpl"
                    type="insuranceTpl"
                    @save="save"
                  />
                </td>
              </tr>
              <tr>
                <th>Insurance - Comprehensive</th>
                <td>
                  <FileUpload
                    :link="vehicle.insuranceComprehensive"
                    type="insuranceComprehensive"
                    @save="save"
                  />
                </td>
              </tr>
              <tr>
                <th>Insurance - PAMI</th>
                <td>
                  <FileUpload
                    :link="vehicle.pami"
                    type="pami"
                    @save="save"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </section>
  </div>

  <!-- <script>
    beforeSubmit = function(e) {

      if(document.querySelector('input[name="activatedProduct"]:checked') == null) {
        window.alert("Choose at least 1 option for activated product!");
        return false;
      }

      return true;
    }
  </script>-->
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/store";

import FileUpload from "@/components/FileUpload";
import cloudinaryUpload from "../../utils/upload";

let lastRoute = {
  name: null,
  fullPath: null
};
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    vid: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    FileUpload
  },
  data() {
    return {
      isSubmitting: false,
      isRouting: false,
      tempFileData: []
    };
  },
  methods: {
    save({ value, type }, action) {
      if (action == "upload") {
        cloudinaryUpload(value, {
          folder: `vehicle-files/${this.vehicle.id}`,
          publicId: type
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.tempFileData = { ...this.tempFileData, [type]: this.vehicle[type] };
            this.vehicle[type] = data.secureUrl;
            this.editVehicle(type);
          });
      } else {
        this.tempFileData = { ...this.tempFileData, [type]: this.vehicle[type] };
        this.vehicle[type] = value;
        this.editVehicle(type);
      }
    },
    editVehicle(type) {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      if (!this.vehicle.activatedProduct.length) {
        const notification = {
          type: "error",
          message: "Required Input, Choose atleast 1 activated product"
        };
        this.$store.dispatch("notification/add", notification, { root: true });
        return;
      }
      for (let v in this.vehicle) {
        if (this.vehicle[v] === "") {
          this.vehicle[v] = null;
        }
      }

      this.$store
        .dispatch("vehicle/editVehicle", this.vehicle)
        .then(() => {
          if (type) {
            this.tempFileData = [];
            this.isSubmitting = false;
          } else {
            this.$router.push(
              {
                name: "operators-edit",
                params: { id: this.id }
              },
              null,
              () => {
                this.isSubmitting = false;
              }
            );
          }
          console.log("success edit vehicle");
        })
        .catch(() => {
          this.vehicle[type] = this.tempFileData[type];
          this.isSubmitting = false;
        });
    },
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;

      if (lastRoute.name) {
        this.$router.go(-1);
        if (!navigator.onLine) this.isRouting = false;
      } else {
        this.$router.replace({
          name: "operators-edit",
          params: { id: this.id }
        });
      }
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    lastRoute.name = routeFrom.name;
    lastRoute.fullPath = routeFrom.fullPath;
    console.log("edit vehicle loading...");
    store
      .dispatch("vehicle/getVehicle", routeTo.params.vid)
      .then(() => {
        next();
        console.log("edit vehicle done loading...");
      })
      .catch(() => {
        if (navigator.onLine) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  created() {
    this.$store.dispatch("prangkisa/getPrangkisasByOperator", this.id);
    this.$store.dispatch("color/getColors");
    this.$store.dispatch("vehicleModel/getVehicleModels");
    this.$store.dispatch("variant/getVariants");
  },
  computed: {
    ...mapState("vehicle", ["vehicle"]),
    ...mapState("prangkisa", ["prangkisasByOperator"]),
    ...mapState("color", ["colors"]),
    ...mapState("vehicleModel", ["vehicleModels"]),
    ...mapState("variant", ["variants"]),
    ...mapState("user", ["user", "token"])
  }
};
</script>

<style>
a {
  cursor: pointer !important;
}
</style>
