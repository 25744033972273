import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getDrivers(token) {
    // fetch all drivers
    return apiClient.get("/api/drivers", {
      headers: {
        Authorization: token
      }
    });
  },

  getDriver(token, id) {
    // fetch driver by id
    return apiClient.get("/api/drivers/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  addDriver(token, username, driver) {
    // add driver
    return apiClient.post("/api/drivers/register", driver, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  editDriver(token, username, id, driver) {
    // update driver
    return apiClient.patch("/api/drivers/" + id, driver, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  getDriverOperators(token, id) {
    // fetch driver operators
    return apiClient.get("/api/drivers/" + id + "/operators", {
      headers: {
        Authorization: token
      }
    });
  },

  assignDriverOperator(token, driverId, operatorId) {
    // add driver operator
    return apiClient.post(
      `/api/drivers/${driverId}/operators`,
      {
        operatorId: operatorId
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  removeDriverOperator(token, driverId, operatorId) {
    // remove driver operator
    return apiClient.delete(
      `/api/drivers/${driverId}/operators/${operatorId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  getDriverTags(token, id) {
    // get driver tags
    return apiClient.get("/api/drivers/" + id + "/tags", {
      headers: {
        Authorization: token
      }
    });
  },

  addDriverTag(token, id, tagId) {
    // add driver tag
    return apiClient.post(
      "/api/drivers/" + id + "/tags",
      { tagId: tagId },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  removeDriverTag(token, id, tagId) {
    // add driver tag
    return apiClient.delete("/api/drivers/" + id + "/tags/" + tagId, {
      headers: {
        Authorization: token
      }
    });
  },

  getDriverStatus(token, id) {
    // get driver status
    return apiClient.get(
      "/api/driver-status/findOne?filter[where][userId]=" + id,
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  getTransactionDescriptions(token, id) {
    // get driver transaction descriptions
    return apiClient.get(`/api/drivers/${id}/transactions/descriptions`, {
      headers: {
        Authorization: token
      }
    });
  }
};
