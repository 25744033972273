<template>
  <div class="tab-pane" id="address">
    <template v-if="view != 'Rider'">
      <fieldset class="address">
        <legend>Address Details</legend>
        <table class="table table-settings">
          <tbody>
            <tr>
              <th>Region</th>
              <td>
                <select
                  class="form-control region"
                  name="state"
                  v-model="module.state"
                  @change="getProvinceByRegion()"
                >
                  <option
                    v-for="(region, index) in regions"
                    :key="index"
                    :value="region.psgcCode"
                    >{{ region.description }}</option
                  >
                </select>
              </td>
            </tr>
            <tr>
              <th>Province</th>
              <td>
                <select
                  class="form-control province"
                  name="province"
                  v-model="module.province"
                  @change="getCitiesByProvince()"
                >
                  <template v-if="module.state">
                    <option
                      v-for="(province, index) in provincesByRegion"
                      :key="index"
                      :value="province.psgcCode"
                      >{{ province.description }}</option
                    >
                  </template>
                </select>
              </td>
            </tr>
            <tr>
              <th>City</th>
              <td>
                <select
                  class="form-control city"
                  name="city"
                  v-model="module.city"
                  @change="getBarangaysByCity()"
                >
                  <template v-if="module.province">
                    <option
                      v-for="(city, index) in citiesByProvince"
                      :key="index"
                      :value="city.psgcCode"
                      >{{ city.description }}</option
                    >
                  </template>
                </select>
              </td>
            </tr>
            <tr>
              <th>Barangay</th>
              <td>
                <select
                  class="form-control barangay"
                  name="barangay"
                  v-model="module.barangay"
                >
                  <template v-if="module.city">
                    <option
                      v-for="(barangay, index) in barangaysByCity"
                      :key="index"
                      :value="barangay.psgcCode"
                      >{{ barangay.description }}</option
                    >
                  </template>
                </select>
              </td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                <textarea
                  rows="3"
                  class="form-control"
                  name="address"
                  v-model.trim="module.address"
                ></textarea>
              </td>
            </tr>
            <tr>
              <th>Zip Code</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  name="zipCode"
                  v-model.trim="module.zipCode"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    view: String,
    permission: Boolean
  },
  methods: {
    getProvinceByRegion() {
      this.$store.dispatch(
        "province/getProvincesByRegion",
        this.module.state.slice(0, 2)
      );
      this.module.province = null;
      this.module.city = null;
      this.module.barangay = null;
    },
    getCitiesByProvince() {
      if (this.module.province) {
        this.$store.dispatch(
          "city/getCitiesByProvince",
          this.module.province.slice(0, 4)
        );
        this.module.city = null;
        this.module.barangay = null;
      }
    },
    getBarangaysByCity() {
      if (this.module.city) {
        this.$store.dispatch(
          "barangay/getBarangaysByCity",
          this.module.city.slice(0, 6)
        );
        this.module.barangay = null;
      }
    }
  },
  computed: {
    ...mapState("region", ["regions"]),
    ...mapState("province", ["provincesByRegion"]),
    ...mapState("city", ["citiesByProvince"]),
    ...mapState("barangay", ["barangaysByCity"])
  },
  mounted() {
    $("#address :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
