<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>Disbursements</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table
              id="disbursement_table"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>GCash Account Number</th>
                  <th>Login Number</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataTable: null,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("user", ["token"])
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["full_name", "gcash_number", "login_number", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],

        columns: [
          { data: "full_name" },
          { data: "gcash_number" },
          { data: "login_number" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true
          },
          {
            targets: [3],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";

              button = `<a class="btn btn-primary"
                           data-driver-id="${row.user_id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;
              return button;
            }
          }
        ]
      });

      $("#disbursement_table").on("click", "[data-driver-id]", e => {
        if (vm.isSubmitting) return;
        vm.isSubmitting = true;
        let { driverId } = e.target.dataset;
        vm.$router.push(
          {
            name: "disbursements-edit",
            params: { id: driverId }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });
    }
  },
  mounted() {
    this.dataTable = $("#disbursement_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/disbursement-tx/reports`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>
