<template>
  <div class="tab-pane" id="operators">
    <fieldset id="foperator" class="form-group">
      <legend>
        Operator(s)
        <button
          v-if="$can('create', 'PartnerOperator')"
          type="button"
          class="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#enrollToOperatorModal"
        >
          <i class="fa fa-plus"></i>
        </button>
      </legend>
      <table
        id="partner_operator_table"
        :data-driver-id="id"
        class="table table-hover"
        width="100%"
      >
        <thead>
          <tr>
            <th>Operator</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      isRouting: false,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("driver", ["driverOperators"]),
    ...mapState("user", ["user", "token"])
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: false,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}` // propertyName ASC|DESC
              // where: {
              //   q: d.search.value,
              // }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.length,
              recordsFiltered: response.length,
              data: response
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],

        columns: [{ data: "lastName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              let button = `<a class="datalink"
                           data-operator-id="${row.id}">
                          ${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}
                        </a>`;
              if (vm.$can("read", "Operator")) {
                return button;
              }
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "Operator")
                ? "visible"
                : "hidden";
              let dVisibility = vm.$can("delete", "PartnerOperator")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-operator-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              button += `<button class="btn btn-primary ${dVisibility}"
                                  type="button"
                                  data-delete-operator-id="${row.id}">
                            <i class="fa fa-trash-o"
                                aria-hidden="true"
                                style="pointer-events: none">
                            </i>
                          </button>`;
              return button;
            }
          }
        ]
      });

      $("#partner_operator_table").on("click", "[data-operator-id]", e => {
        if (vm.isSubmitting) return;
        let { operatorId } = e.target.dataset;
        vm.isSubmitting = true;
        vm.$router.push(
          {
            name: "operators-edit",
            params: {
              id: operatorId
            }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });

      $("#partner_operator_table").on(
        "click",
        "[data-delete-operator-id]",
        e => {
          if (vm.isSubmitting) return;
          let { deleteOperatorId } = e.target.dataset;
          let answer = confirm("Are you sure?");
          if (!answer) return;
          vm.isSubmitting = true;
          vm.$store
            .dispatch("driver/removeDriverOperator", {
              driverId: this.id,
              operatorId: deleteOperatorId
            })
            .then(() => {
              $("#partner_vehicle_table")
                .DataTable()
                .ajax.reload(() => {
                  $("#operator_enroll_table")
                    .DataTable()
                    .ajax.reload(() => {
                      vm.dataTable.DataTable().ajax.reload(() => {
                        vm.isSubmitting = false;
                      });
                    });
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      );
    }
  },
  mounted() {
    this.dataTable = $("#partner_operator_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/drivers/${
      this.id
    }/operators`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
