<template>
  <div class="tab-pane" id="accounts">
    <fieldset>
      <legend>KYC</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>Social Security Number (SSN)</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="ssn"
                v-model="partner.ssn"
              />
            </td>
          </tr>
          <tr>
            <th>Tax Identification Number (TIN)</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="tin"
                v-model="partner.tin"
              />
            </td>
          </tr>
          <tr>
            <th>Work Nature</th>
            <td>
              <select
                class="form-control"
                name="workNature"
                v-model="partner.workNature"
              >
                <option
                  v-for="(workNature, index) in workNatures"
                  :key="index"
                  :value="workNature"
                  >{{ workNature }}</option
                >
              </select>
            </td>
          </tr>
          <tr>
            <th>Income Source</th>
            <td>
              <select
                class="form-control"
                name="incomeSource"
                v-model="partner.incomeSource"
              >
                <option
                  v-for="(workNature, index) in incomeSources"
                  :key="index"
                  :value="workNature"
                  >{{ workNature }}</option
                >
              </select>
            </td>
          </tr>
          <tr>
            <th>Employment Detail</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="employmentDetails"
                v-model="partner.employmentDetails"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({ partner: state => state.driver.driver }),
    ...mapState(["workNatures", "incomeSources"])
  }
};
</script>

<style></style>
