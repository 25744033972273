var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"main-sidebar"},[_c('section',{staticClass:"sidebar"},[_c('UserPanel'),_c('ul',{staticClass:"sidebar-menu",attrs:{"data-widget":"tree"}},[(
          _vm.$can('read', 'Operator') ||
            _vm.$can('read', 'Partner') ||
            _vm.$can('read', 'Rider')
        )?_c('li',{staticClass:"header"},[_vm._v(" Main Navigation ")]):_vm._e(),(_vm.$can('read', 'Operator'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'operators' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Operators")])])],1):_vm._e(),(_vm.$can('read', 'Partner'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'partners' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Partners")])])],1):_vm._e(),(_vm.$can('read', 'Rider'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'riders' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Riders")])])],1):_vm._e(),(_vm.$can('read', 'Route') || _vm.$can('read', 'Schedule'))?_c('li',{staticClass:"header"},[_vm._v(" Spade ")]):_vm._e(),(_vm.$can('read', 'Route'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'routes' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Routes")])])],1):_vm._e(),(_vm.$can('read', 'Schedule'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'schedules' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Schedules")])])],1):_vm._e(),(
          _vm.$can('read', 'VehicleModel') ||
          _vm.$can('read', 'Tag') ||
          _vm.$can('read', 'Variant') ||
          _vm.$can('read', 'Poi') ||
          _vm.$can('read', 'Fleet') ||
          _vm.$can('read', 'Employer') ||
          _vm.$can('read', 'Disbursement') ||
          _vm.$can('read', 'Livemap')
        )?_c('li',{staticClass:"header"},[_vm._v(" Others ")]):_vm._e(),(_vm.$can('read', 'VehicleModel'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'vehicle-models' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Vehicle Models")])])],1):_vm._e(),(_vm.$can('read', 'Tag'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'tags' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Tags")])])],1):_vm._e(),(_vm.$can('read', 'Variant'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'variants' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Variants")])])],1):_vm._e(),(_vm.$can('read', 'Poi'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'pois' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("POIs")])])],1):_vm._e(),(_vm.$can('read', 'Fleet'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'fleets' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Fleets")])])],1):_vm._e(),(_vm.$can('read', 'Employer'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'employers' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Employers")])])],1):_vm._e(),(_vm.$can('read', 'Disbursement'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'disbursements' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Disbursements")])])],1):_vm._e(),(_vm.$can('read', 'Livemap'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'livemap' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("LiveMap")])])],1):_vm._e(),(_vm.$can('manage', 'all'))?_c('li',{staticClass:"header"},[_vm._v("Permission")]):_vm._e(),(_vm.$can('manage', 'all'))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'admin-panel' }}},[_c('i',{staticClass:"fa fa-user fa-fw"}),_c('span',[_vm._v("Admin Panel")])])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }