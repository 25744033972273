<template>
  <div class="user-panel">
    <div class="pull-left image">
      <img
        src="@/assets/images/icon.png"
        class="img-responsive"
        alt="User Image"
      />
    </div>
    <div class="pull-left info">
      <p>{{ user.name }}</p>
      <!-- <small>{{ user.upn }}</small> -->
    </div>
  </div>
</template>

<script>
import adalAuth from "@/authentication/adalAuth.js";

export default {
  data() {
    return { user: null };
  },
  created() {
    this.user = adalAuth.getUserProfile();
  }
};
</script>

<style></style>
