// adal-angular authentication library include
import AuthenticationContext from "adal-angular/lib/adal.js";
import store from "@/store/store.js";

// setting up configurable options
const config = {
  tenant: `${process.env.VUE_APP_AD_TENANT_ID}`,
  clientId: `${process.env.VUE_APP_AD_CLIENT_ID}`,
  redirectUri: `${process.env.VUE_APP_APP_URL}/auth/openid/return`,
  cacheLocation: "localStorage"
};

export default {
  authenticationContext: null,

  // initialize before creating vue app
  initialize() {
    // Authentication Context represents a connection to Azure AD
    // create Authentication Context instance with config options
    this.authenticationContext = new AuthenticationContext(config);

    return new Promise((resolve, reject) => {
      if (
        this.authenticationContext.isCallback(location.hash) ||
        self !== top
      ) {
        // redirect to the location specified in the url params.
        this.authenticationContext.handleWindowCallback();
      } else {
        // try pull the user out of local storage
        let user = this.authenticationContext.getCachedUser();

        if (user) {
          // user.profile.oid
          console.log(user.profile);
          store.dispatch("user/getToken");
          store.dispatch("user/setUserProfile", user.profile).then(() => {
            resolve();
          });
        } else {
          // no user at all - go sign in.
          this.signIn();
        }
      }
    });
  },

  acquireToken() {
    // acquire token with azure resource id or client id as parameter
    return new Promise((resolve, reject) => {
      this.authenticationContext.acquireToken(
        config.clientId,
        (error, token) => {
          if (error) {
            reject(error);
          } else {
            resolve(token);
          }
        }
      );
    });
  },

  // Issue an interactive authentication request for the
  // current user and the api resource.
  acquireRedirectToken() {
    this.authenticationContext.acquireTokenRedirect(config.clientId);
  },

  isAuthenticated() {
    // check authenticity
    if (this.authenticationContext.getCachedToken(config.clientId)) {
      return true;
    }
    return false;
  },

  getUserProfile() {
    return this.authenticationContext.getCachedUser().profile;
  },

  signIn() {
    this.authenticationContext.login();
  },

  signOut() {
    this.authenticationContext.logOut();
  }
};
