<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Point of Interest
        <!-- <router-link :to="{ name: 'pois-new' }" class="btn btn-primary btn-sm"
          >Add New</router-link
        >-->
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="box box-primary">
              <div class="box-body">
                <!-- <button disabled>Toggle Details</button>
                <button id="pan">try</button>-->
                <div class="map-wrapper">
                  <div class="poi-data-wrapper">
                    <div class="poi-list">
                      <div class="poi-box box box-primary">
                        <div class="box-body">
                          <div class="row poi-list-header">
                            <div class="col-sm-6">
                              <div
                                id="operators_table_filter"
                                class="dataTables_filter"
                              >
                                <input
                                  type="search"
                                  class="form-control input-sm"
                                  placeholder="Search"
                                  aria-controls="operators_table"
                                  v-model="query"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <button
                                class="btn btn-primary btn-sm"
                                @click="manage(null, null)"
                              >
                                Add Parent Poi
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-12">
                              <table
                                id="pois_table"
                                class="table table-hover"
                                width="100%"
                              >
                                <thead>
                                  <tr role="row">
                                    <th class="sorting_asc">Name</th>
                                    <th></th>
                                  </tr>
                                  <template v-for="(pPoi, i) in parentPois">
                                    <tr :key="pPoi.label" :data-index="i">
                                      <td>
                                        <i
                                          class="fa fa-map-marker parent-poi-icon"
                                          aria-hidden="true"
                                          @click="
                                            locate(
                                              pPoi.coordinates.lng,
                                              pPoi.coordinates.lat
                                            )
                                          "
                                        ></i>
                                        <a
                                          class="datalink"
                                          @click.prevent="manage(pPoi.id, null)"
                                          >{{ pPoi.label }}</a
                                        >
                                      </td>
                                      <td>
                                        <a
                                          class="btn btn-primary"
                                          @click.prevent="manage(null, pPoi.id)"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                            style="pointer-events: none"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                    <tr
                                      v-for="(cPoi, i) in pPoi.childs"
                                      :key="cPoi.label"
                                      :data-index="i"
                                      class="child-poi"
                                    >
                                      <td colspan="2">
                                        <i
                                          class="fa fa-map-marker child-poi-icon"
                                          aria-hidden="true"
                                          @click="
                                            locate(
                                              cPoi.coordinates.lng,
                                              cPoi.coordinates.lat
                                            )
                                          "
                                        ></i>
                                        <a
                                          class="datalink"
                                          @click.prevent="
                                            manage(cPoi.id, pPoi.id)
                                          "
                                          >{{ cPoi.label }}</a
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </thead>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="poi-details" v-if="managing">
                      <div class="box box-primary">
                        <div class="box-body">
                          <fieldset>
                            <legend style="text-align:center; margin-bottom: 0">
                              Details
                              <span
                                @click="managing = !managing"
                                class="poi-details-close"
                                >&times;</span
                              >
                            </legend>
                            <table class="table table-settings">
                              <tbody>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="parent"
                                      v-model.trim="myPoi.parent"
                                      readonly="readonly"
                                      placeholder="Parent"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="label"
                                      v-model.trim="myPoi.label"
                                      required="required"
                                      placeholder="Label"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <textarea
                                      rows="5"
                                      class="form-control"
                                      name="description"
                                      v-model.trim="myPoi.description"
                                      required="required"
                                      placeholder="Description"
                                    ></textarea>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="number"
                                      class="form-control"
                                      name="coordinates"
                                      v-model.number="myPoi.coordinates.lng"
                                      placeholder="Longitude"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="number"
                                      class="form-control"
                                      name="coordinates"
                                      v-model.number="myPoi.coordinates.lat"
                                      placeholder="Latitude"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <select
                                      class="form-control"
                                      name="type"
                                      required="required"
                                      v-model="myPoi.type"
                                      placeholder="Type"
                                    >
                                      <option
                                        v-for="(type, index) in types"
                                        :key="index"
                                        :value="type"
                                        >{{ type }}</option
                                      >
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>

                          <div class="form-group">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              @click="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="geotag"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/store.js";

import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as proj from "ol/proj";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Fill from "ol/style/Fill";
import * as easing from "ol/easing";
import tag from "@/assets/images/dot.png";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("poi/getPois")
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  },
  computed: {
    ...mapState("poi", ["pois", "poi"]),
    parentPois() {
      let vm = this;
      vm.pois.forEach((poi, i) => {
        if (vm.pois[i].childs) {
          delete vm.pois[i].childs;
        }
      });
      child = null;
      let parent = this.pois.filter(poi => {
        return !poi.parent;
      });

      let child = this.pois.filter(poi => {
        return poi.parent;
      });

      child.forEach(c => {
        var pi;
        parent.find((p, i) => {
          if (p.id === c.parent) {
            pi = i;
            return true;
          }
        });
        if (parent[pi].childs) {
          parent[pi].childs.push(c);
        } else {
          parent[pi].childs = [c];
        }
      });

      return parent.filter(item => {
        return (
          item.label.toLowerCase().indexOf(this.query.toLowerCase()) !== -1
        );
      });
    }
  },
  data() {
    return {
      view: null,
      dataTable: null,
      types: ["BUILDING"],
      myPoi: this.createNewPoi(),
      managing: false,
      id: null,
      parent: null,
      query: "",
      isFetching: false,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["label", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            console.log(response.data);

            let parentPois = response.data.filter(poi => {
              return !poi.parent;
            });
            let childPois = response.data.filter(poi => {
              return poi.parent;
            });

            let formatted = {
              recordsTotal: parentPois.length,
              recordsFiltered: parentPois.length,
              data: parentPois
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],

        columns: [{ data: "label" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `<a class="datalink"
                         data-poi-edit-id="${row.id}">
                        ${row.label}
                      </a>`;
            }
          },
          {
            targets: [1],
            width: "40px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";

              button = `<a class="btn btn-primary"
                           data-poi-child-add="${row.id}">
                          <i class="fa fa-plus"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              return button;
            }
          }
        ]
      });

      // $(document).on("click", "[data-poi-edit-id]", e => {
      //   let { poiEditId } = e.target.dataset;
      //   vm.$router.push({
      //     name: "pois-edit",
      //     params: { id: poiEditId }
      //   });
      //   e.preventDefault();s
      // });

      vm.dataTable
        .DataTable()
        .rows()
        .every(function() {
          console.log("realsale");
        });

      $(document).on("click", "[data-poi-child-add]", e => {
        console.log("add child testing");
        let { poiChildAdd } = e.target.dataset;
        vm.manage(null, poiChildAdd);
        e.preventDefault();
      });

      $(document).on("click", "[data-poi-edit-id]", e => {
        console.log("edit testing");
        let { poiEditId } = e.target.dataset;
        vm.manage(poiEditId, null);
        e.preventDefault();
      });
    },
    createNewPoi() {
      return {
        label: "",
        description: "",
        coordinates: {
          lng: "",
          lat: ""
        },
        parent: null,
        type: "BUILDING"
      };
    },
    manage(id, parentId) {
      if (this.isFetching) return;
      this.isFetching = true;
      this.managing = false;
      this.id = id;
      this.parent = parentId;
      this.myPoi = this.createNewPoi();
      if (id) {
        this.$store
          .dispatch("poi/getPoi", id)
          .then(() => {
            this.myPoi = { ...this.poi };
            this.managing = true;
            this.isFetching = false;
          })
          .catch(() => {
            this.isFetching = false;
          });
      } else {
        if (this.parent) {
          this.myPoi = { ...this.createNewPoi(), parent: this.parent };
        }
        this.managing = true;
        this.isFetching = false;
      }
    },
    submit() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      if (this.id) {
        this.$store
          .dispatch("poi/editPoi", this.myPoi)
          .then(() => {
            this.managing = false;
            this.isSubmitting = false;
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      } else {
        this.$store
          .dispatch("poi/addPoi", this.myPoi)
          .then(() => {
            this.managing = false;
            this.isSubmitting = false;
          })
          .catch(() => {
            this.isSubmitting = false;
          });
      }
    },
    locate(lon, lat) {
      let center = proj.fromLonLat([lon, lat]);
      this.view.animate({
        duration: 1000,
        easing: easing.inAndOut,
        center: center
      });
    }
  },
  mounted() {
    // this.dataTable = $("#pois_table");
    // const url = `${process.env.VUE_APP_BE_API_URL}/api/poi`;
    // this.setupDtTable(this.dataTable, url);

    // openlayer
    let map = new Map({
      target: "geotag"
    });

    let baseLayer = new TileLayer({
      source: new OSM()
    });

    this.view = new View({
      center: proj.fromLonLat([121.02592, 14.638247]),
      zoom: 18,
      maxResolution: 78271.52,
      maxZoom: 20,
      zoomFactor: 2
    });

    let poisMarker = [];
    this.pois.forEach((poi, i) => {
      poisMarker.push(
        new Feature({
          id: poi.id,
          geometry: new Point(
            proj.fromLonLat([poi.coordinates.lng, poi.coordinates.lat])
          )
        })
      );
      poisMarker[i].setId(poi.id);
    });

    let poisStyle = new Style({
      image: new Icon({
        color: "#4365FF",
        src: tag
      })
    });

    let poisSource = new VectorSource({
      features: poisMarker
    });

    let poisVector = new VectorLayer({
      source: poisSource,
      style: poisStyle
    });

    map.addLayer(baseLayer);
    map.addLayer(poisVector);
    map.setView(this.view);

    // document.querySelector("#pan").addEventListener("click", function() {
    //   view.animate({
    //     duration: 1000,
    //     easing: easing.inAndOut,
    //     center: center
    //   });
    // }); var center = view.getCenter();
  }
};
</script>

<style>
a.datalink {
  font-weight: 700;
  cursor: pointer;
}

.sorting_asc,
.sorting_desc {
  padding-right: 30px;
  cursor: pointer;
  position: relative;
}

.sorting_asc:after {
  content: "\e155";
}

.sorting_desc:after {
  content: "\e156";
}

.sorting_asc:after,
.sorting_desc:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: "Glyphicons Halflings";
  opacity: 0.5;
}

.parent-poi-icon,
.child-poi-icon {
  padding-right: 10px;
  cursor: pointer;
}

.parent-poi-icon:hover,
.child-poi-icon:hover {
  color: #aaa;
}

.child-poi td {
  padding-left: 40px !important;
}

.child-poi td a {
  color: #337ab7;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
}

.map-wrapper .poi-data-wrapper,
.map-wrapper #geotag {
  float: left;
}

.map-wrapper .poi-data-wrapper {
  position: relative;
  width: 30%;
  height: 100%;
}

.map-wrapper #geotag {
  width: 70%;
  height: inherit;
}

.map-wrapper .poi-data-wrapper .poi-list {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: inherit;
}

.map-wrapper .poi-data-wrapper .poi-list .poi-list-header {
  position: sticky;
  width: 100%;
  height: auto;
}

.map-wrapper .poi-data-wrapper .poi-details {
  position: absolute;
  width: 100%;
  height: inherit;
  top: 0;
  left: 100%;
  z-index: 1;
  overflow-y: hidden;
}

.map-wrapper .poi-data-wrapper .poi-details::before,
.map-wrapper .poi-data-wrapper .poi-details::after {
  content: "";
  display: table;
  clear: both;
}

.map-wrapper .poi-data-wrapper .poi-details .poi-details-close {
  font-size: 1em;
  font-weight: 600;
  font-variant: small-caps;
  padding: 0 10px 0 5px;
  float: right;
  cursor: pointer;
}

.map-wrapper .poi-data-wrapper .poi-details .poi-details-close:hover {
  color: #dd4b39;
}

.map-wrapper .poi-data-wrapper .poi-list .poi-box {
  margin: 0;
}
</style>
