<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <!--   {% include "Partial/user-panel.njk" %}
      user-panel component-->
      <UserPanel />

      <ul class="sidebar-menu" data-widget="tree">
        <li
          v-if="
            $can('read', 'Operator') ||
              $can('read', 'Partner') ||
              $can('read', 'Rider')
          "
          class="header"
        >
          Main Navigation
        </li>
        <li v-if="$can('read', 'Operator')">
          <router-link :to="{ name: 'operators' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Operators</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Partner')">
          <router-link :to="{ name: 'partners' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Partners</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Rider')">
          <router-link :to="{ name: 'riders' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Riders</span>
          </router-link>
        </li>
        <li
          v-if="$can('read', 'Route') || $can('read', 'Schedule')"
          class="header"
        >
          Spade
        </li>
        <li v-if="$can('read', 'Route')">
          <router-link :to="{ name: 'routes' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Routes</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Schedule')">
          <router-link :to="{ name: 'schedules' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Schedules</span>
          </router-link>
        </li>
        <li
          v-if="
            $can('read', 'VehicleModel') ||
            $can('read', 'Tag') ||
            $can('read', 'Variant') ||
            $can('read', 'Poi') ||
            $can('read', 'Fleet') ||
            $can('read', 'Employer') ||
            $can('read', 'Disbursement') ||
            $can('read', 'Livemap')
          "
          class="header"
        >
          Others
        </li>
        <li v-if="$can('read', 'VehicleModel')">
          <router-link :to="{ name: 'vehicle-models' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Vehicle Models</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Tag')">
          <router-link :to="{ name: 'tags' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Tags</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Variant')">
          <router-link :to="{ name: 'variants' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Variants</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Poi')">
          <router-link :to="{ name: 'pois' }">
            <i class="fa fa-user fa-fw"></i>
            <span>POIs</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Fleet')">
          <router-link :to="{ name: 'fleets' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Fleets</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Employer')">
          <router-link :to="{ name: 'employers' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Employers</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Disbursement')">
          <router-link :to="{ name: 'disbursements' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Disbursements</span>
          </router-link>
        </li>
        <li v-if="$can('read', 'Livemap')">
          <router-link :to="{ name: 'livemap' }">
            <i class="fa fa-user fa-fw"></i>
            <span>LiveMap</span>
          </router-link>
        </li>
        <li v-if="$can('manage', 'all')" class="header">Permission</li>
        <li v-if="$can('manage', 'all')">
          <router-link :to="{ name: 'admin-panel' }">
            <i class="fa fa-user fa-fw"></i>
            <span>Admin Panel</span>
          </router-link>
        </li>
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import UserPanel from "./UserPanel";
export default {
  components: {
    UserPanel
  }
};
</script>

<style></style>
