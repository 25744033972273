<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Partners
        <router-link :to="{ name: 'partners-new' }">
          <button
            v-if="$can('create', 'Partner')"
            class="btn btn-primary btn-sm"
          >
            Register
          </button>
        </router-link>
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table id="partners_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dataTable: null,
      isRouting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            //vm.$store.dispatch("operator/getO", { data: response.data }); // store data to operator module's state

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        // createdRow: ( row, data, dataIndex ) => {
        //   $(row).find('td:eq(0)')
        //     .attr('data-schedule-id', data.id);
        // },
        columns: [
          // {
          //   className:      'details-control text-center',
          //   orderable:      false,
          //   data:           null,
          //   defaultContent: ''
          // },
          { data: "lastName" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";

              if (vm.$can("read", "Partner")) {
                button = `<a class="btn btn-primary"
                           data-partner-edit-id="${row.userId}">
                          <i class="fa fa-pencil-square-o" aria-hidden="true" style="pointer-events: none"></i>
                        </a>`;
              } else {
                button = `<a class="btn btn-primary" style="visibility:hidden"
                           data-partner-edit-id="${row.userId}">
                          <i class="fa fa-pencil-square-o" aria-hidden="true" style="pointer-events: none"></i>
                        </a>`;
              }
              //  button += `<btn class="btn btn-primary" type="button" data-toggle="modal" data-target="#myModal" data-cancel-schedule-id="${row.id}"><i class="fa fa-ban" aria-hidden="true" style="pointer-events: none"></i></btn>`;
              //  button += `<btn class="btn btn-primary" type="button" data-toggle="modal" data-target="#myModal" data-delete-schedule-id="${row.id}"><i class="fa fa-trash-o" aria-hidden="true" style="pointer-events: none"></i></btn>`;
              return button;
            }
          }
        ]
      });

      $("#partners_table").on("click", "[data-partner-edit-id]", e => {
        if (vm.isRouting) return;
        vm.isRouting = true;
        let { partnerEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "partners-edit",
            params: { id: partnerEditId }
          },
          () => {
            vm.$store.dispatch("canBack");
          },
          () => {
            vm.isRouting = false;
          }
        );
        e.preventDefault();
      });
    }
  },
  mounted() {
    this.dataTable = $("#partners_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/drivers`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["token"]),
    permission() {
      return this.$can("create", "Partner");
    }
  }
};
</script>

<style></style>
