<template>
  <div>
    <div>
      <a
        v-if="link"
        :href="link"
        target="_blank"
        >Document Link</a
      >

      <span v-else>No document...</span>

      <button
        class="btn btn-primary margin-left"
        type="button"
        @click="edit"
      >
        Upload New
      </button>
    </div>

    <div v-show="isEditMode" class="upload-modal-wrapper" ref="modal" @click="clickAway">
      <div class="upload-modal">
        <div class="upload-modal__head">
          <h4>Upload Document</h4>
          <span class="upload-modal__close" @click="cancel">&times;</span>
        </div>

        <div class="upload-modal__body">
          <div class="img-box">
            <img :src="link ? link : ''" :alt="type" ref="preview">
          </div>

          <div>
            <input
              class="form-control"
              @change="upload"
              id="fileupload"
              type="file"
              ref="file"
              accept="image/*"
            />

            <div class="form-seperator">
              <span>or</span>
              <hr />
            </div>

            <input
              class="form-control"
              type="text"
              placeholder="Input existing link..."
              v-model="url"
            />

            <button
              type="button"
              class="btn btn-primary form-btn"
              :disabled="!hasChange && isEditMode"
              @click="save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["link", "type"],
  data() {
    return {
      file: null,
      isEditMode: false,
      url: null,
      preview: null
    };
  },
  methods: {
    edit() {
      this.isEditMode = true;
      this.url = this.link;
      this.$refs.preview.src = this.link;
    },
    upload(event) {
      if (!event.target.files || !event.target.files[0]) return;

      const fileType = event.target.files[0].type;

      if (fileType !== "image/png" &&
      fileType !== "image/jpg" &&
      fileType !== "image/jpeg") {
        this.$refs.file.value = "";

        const notification = {
          type: "error",
          message: "Unsupported file type"
        };

        return this.$store.dispatch("notification/add", notification);
      }

      this.file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = e => {
        this.preview.src = e.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    },
    save() {
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (this.value.action == "input" && !urlRegex.test(this.value.value)) {
        const notification = {
          type: "error",
          message: "Invalid url"
        };

        return this.$store.dispatch("notification/add", notification);
      }

      this.$emit(
        "save",
        { value: this.value.value, type: this.type },
        this.value.action
      );

      this.cancel();
    },
    cancel() {
      this.isEditMode = false;
      this.file = null;
      this.url = null;
      this.$refs.preview.src = "";
      this.$refs.file.value = "";
    },
    clickAway(event) {
      if (event.target === this.$refs.modal) this.cancel();
    },
  },
  computed: {
    hasChange() {
      // eligibility for saving
      if ((this.url && this.url != this.link) || this.file) return true;
      return false;
    },
    value() {
      // file takes precedence over url/link if both value has changed
      if (!this.hasChange) return false;

      let action = "input";
      let value = this.url;

      if (this.file) {
        action = "upload";
        value = this.file;
      }

      return { action, value};
    }
  },
  mounted() {
    this.preview = this.$refs.preview;
  }
}
</script>

<style scoped>
.margin-left {margin-left: 1em;}

.margin-horizontal {
  margin: 10px 0;
}

.form-control-flex {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-seperator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-seperator span {
  position: absolute;
  width: 30px;
  background: white;
  display: inline-flex;
  justify-content: center;
}

.form-seperator hr {
  width: 100%;
  border-top: 2px solid #eee;
}

.form-btn {
  width: 100%;
  margin-top: 16px;
}

.upload-modal-wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-modal {
  width: 100%;
  max-width: 500px;
  background: #fff;
  margin: 10px;
  padding: 16px;
}

@media only screen and (min-width: 768px) {
  .upload-modal {
    margin: 30px auto;
  }
}

.upload-modal__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-modal__close {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 0 8px;
}

.upload-modal__close:hover {
  color: #000;
}

.img-box {
  padding: 8px;
  border: 1px solid #d2d6de;
  margin-bottom: 16px;
}

.img-box img {
  display: block;
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  height: auto;
  max-height: 450px;
  object-fit: contain;
  /* background: lightgray; */
  margin: 0 auto;
  cursor: default !important;
}
</style>
