<template>
  <div class="tab-pane" id="prangkisa">
    <fieldset id="fprangkisa">
      <legend>
        PA/CPC
        <router-link
          v-if="canCreatePrangkisa"
          :to="{ name: 'prangkisa-new', params: { id: id } }"
          class="btn btn-primary btn-sm"
        >
          <i class="fa fa-plus"></i>
        </router-link>
      </legend>
      <table
        id="operator_prangkisa_table"
        :data-operator-id="id"
        class="table table-hover"
        width="100%"
      >
        <thead>
          <tr>
            <th>Driver</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </fieldset>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      isSubmitting: false,
      prangkisaFromDT: []
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["caseNumber", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: false,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}` // propertyName ASC|DESC
              // where: {
              //   q: d.search.value,
              // }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };
            vm.prangkisaFromDT = response.data;

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "caseNumber" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.caseNumber}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "OperatorPrangkisa")
                ? "visible"
                : "hidden";
              let dVisibility = vm.$can("delete", "OperatorPrangkisa")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-operator-prangkisa-edit-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              button += `<button class="btn btn-primary ${dVisibility}"
                                 type="button"
                                 data-delete-prangkisa-id="${row.id}">
                            <i class="fa
                               fa-trash-o"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                         </button>`;
              // button = `<btn class="btn btn-primary" type="button" data-toggle="modal" data-target="#myModal" data-delete-schedule-id="${row.id}"><i class="fa fa-trash-o" aria-hidden="true" style="pointer-events: none"></i></btn>`;

              return button;
            }
          }
        ]
      });

      $("#operator_prangkisa_table").on(
        "click",
        "[data-operator-prangkisa-edit-id]",
        e => {
          if (vm.isSubmitting) return;
          this.isSubmitting = true;
          let { operatorPrangkisaEditId } = e.target.dataset;
          vm.$router.push(
            {
              name: "prangkisa-edit",
              params: {
                id: dataTable[0].dataset.operatorId,
                prid: operatorPrangkisaEditId
              }
            },
            null,
            () => {
              vm.isSubmitting = false;
            }
          );
          e.preventDefault();
        }
      );

      $("#operator_prangkisa_table").on(
        "click",
        "[data-delete-prangkisa-id]",
        e => {
          if (vm.isSubmitting) return;
          let { deletePrangkisaId } = e.target.dataset;
          const confirmed = confirm("Are you sure?");
          if (!confirmed) return;
          vm.isSubmitting = true;
          return axios
            .delete(
              `${
                process.env.VUE_APP_BE_API_URL
              }/api/prangkisa/${deletePrangkisaId}`,
              {
                headers: {
                  Authorization: vm.token,
                  "X-Owto-Request-By": vm.user.name
                }
              }
            )
            .then(() => {
              $(dataTable)
                .DataTable()
                .ajax.reload(() => {
                  vm.isSubmitting = false;
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      );
    }
  },
  mounted() {
    this.dataTable = $("#operator_prangkisa_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/operators/${
      this.id
    }/prangkisa`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["user", "token"]),
    canCreatePrangkisa() {
      if (!this.$can('create', 'OperatorPrangkisa')) return false;

      if (this.prangkisaFromDT.length == 0) return true;

      const hasCpc = this.prangkisaFromDT.find(p => {
        return p.certType === "CPC";
      })

      if (!hasCpc) return true;
      return false;
    }
  }
};
</script>

<style></style>
