<template>
  <div class="tab-pane" id="owto">
    <fieldset>
      <legend>Owto</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>Activation Status</th>
            <td>
              <select
                class="form-control"
                name="activationStatus"
                v-model="module.activationStatus"
              >
                <option
                  v-for="(actStat, index) in activationStatuses"
                  :key="index"
                  >{{ actStat }}</option
                >
              </select>
            </td>
          </tr>
          <tr>
            <th>Login Number</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="loginNumber"
                v-model.trim="module.loginNumber"
                autocomplete="off"
                @input="format($event)"
              />
            </td>
          </tr>
          <tr>
            <th>Priority</th>
            <td>
              <input
                type="number"
                class="form-control"
                name="priority"
                v-model.number="module.priority"
                min="0"
              />
            </td>
          </tr>
          <tr>
            <th>Group Affiliations</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="tags"
                data-data="tags"
                :data-driver-id="module.userId"
              />
            </td>
          </tr>
          <tr>
            <th>Remarks</th>
            <td>
              <textarea
                rows="5"
                class="form-control"
                name="remarks"
                v-model.trim="module.remarks"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { AsYouType } from "libphonenumber-js";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    permission: Boolean
  },
  data() {
    return {
      activationStatuses: ["ACTIVATED", "BLOCKED", "DEACTIVATED"]
    };
  },
  methods: {
    format(ev) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.module.loginNumber)) {
          this.module.loginNumber = this.module.loginNumber.substr(
            0,
            this.module.loginNumber.lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.module.loginNumber) &&
              this.module.loginNumber.length >= 4) ||
            (!plusBegin.test(this.module.loginNumber) && ev.data != 0) ||
            (!plusBegin.test(this.module.loginNumber) &&
              ev.data == 0 &&
              this.module.loginNumber.length >= 2)
          ) {
            if (this.module.loginNumber.indexOf("+0") == 0) return;
            asYouType.input(this.module.loginNumber);
            this.module.loginNumber = asYouType.getNumber().number;
          }
        }
      }
    }
  },
  mounted() {
    $("#owto :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
