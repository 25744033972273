<template>
  <div class="tab-pane" id="accounts">
    <fieldset>
      <legend>Account Details</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>Paymaya Number</th>
            <td>
              <input
                type="text"
                class="form-control"
                name="paymayaNumber"
                v-model.trim="module.paymayaNumber"
                @input="format($event)"
                autocomplete="off"
              />
            </td>
          </tr>
          <tr>
            <th>Paymaya Card Number</th>
            <td>
              <input
                type="number"
                class="form-control"
                name="paymayaCardNumber"
                v-model.number="module.paymayaCardNumber"
              />
            </td>
          </tr>
          <tr>
            <th>Paymaya Whitelist Status</th>
            <td>
              <input
                type="checkbox"
                id="paymayaWhitelistStatus"
                data-width="120"
                data-toggle="toggle"
                data-on="Processed"
                data-off="Unprocessed"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <component :is="KYC" />
    </fieldset>
  </div>
</template>

<script>
import KYC from "./KYC.vue";
import { AsYouType } from "libphonenumber-js";

export default {
  components: {
    KYC
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  computed: {
    KYC() {
      return this.view === "Partner" ? "KYC" : "";
    }
  },
  mounted() {
    let checked = this.module.paymayaWhitelistStatus ? "on" : "off";
    $("#paymayaWhitelistStatus").bootstrapToggle(checked);
    if (!this.$can("update", "Operator") || !this.$can("update", "Partner")) {
      $("#paymayaWhitelistStatus").bootstrapToggle("disable");
    } else {
      $("#accounts").on("click", "div.toggle", e => {
        if (e.currentTarget.className === "toggle btn btn-primary") {
          this.module.paymayaWhitelistStatus = false;
        } else {
          this.module.paymayaWhitelistStatus = true;
        }
      });
    }
  },
  methods: {
    format(ev) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.module.paymayaNumber)) {
          this.module.paymayaNumber = this.module.paymayaNumber.substr(
            0,
            this.module.paymayaNumber.lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.module.paymayaNumber) &&
              this.module.paymayaNumber.length >= 4) ||
            (!plusBegin.test(this.module.paymayaNumber) && ev.data != 0) ||
            (!plusBegin.test(this.module.paymayaNumber) &&
              ev.data == 0 &&
              this.module.paymayaNumber.length >= 2)
          ) {
            if (this.module.paymayaNumber.indexOf("+0") == 0) return;
            asYouType.input(this.module.paymayaNumber);
            this.module.paymayaNumber = asYouType.getNumber().number;
          }
        }
      }
    }
  }
};
</script>

<style></style>
