import Vue from "vue";
import Vuex from "vuex";
import * as operator from "./module/operator.js";
import * as driver from "./module/driver.js";
import * as rider from "./module/rider.js";
import * as prangkisa from "./module/prangkisa.js";
import * as note from "./module/note.js";
import * as fleet from "./module/fleet.js";
import * as variant from "./module/variant.js";
import * as vehicleModel from "./module/vehicle-model.js";
import * as region from "./module/regions.js";
import * as province from "./module/province.js";
import * as city from "./module/city.js";
import * as barangay from "./module/barangay.js";
import * as id from "./module/id.js";
import * as color from "./module/color.js";
import * as vehicle from "./module/vehicle.js";
import * as user from "./module/user.js";
import * as balance from "./module/balance.js";
import * as notification from "./module/notification.js";
import * as card from "./module/card.js";
import * as route from "./module/route.js";
import * as poi from "./module/poi.js";
import * as schedule from "./module/schedule.js";
import * as tag from "./module/tag.js";
import * as gcash from "./module/gcash.js";
import * as employer from "./module/employer.js";
import * as disbursement from "./module/disbursement.js";
import * as location from "./module/location.js";

Vue.use(Vuex);

export default new Vuex.Store({
  // data models
  modules: {
    operator,
    prangkisa,
    driver,
    rider,
    note,
    fleet,
    variant,
    vehicleModel,
    region,
    province,
    city,
    barangay,
    id,
    color,
    vehicle,
    user,
    balance,
    notification,
    card,
    route,
    poi,
    schedule,
    tag,
    gcash,
    employer,
    disbursement,
    location
  },
  state: {
    canBack: false,
    user: {
      username: "jerry.sale@owtoph.com"
    },
    genders: ["Male", "Female", "Others"],
    civilStatuses: ["Single", "Married", "Widowed", "Seperated"],
    BE_API_URL: process.env.BE_API_URL,
    NODE_ENV: process.env.NODE_ENV,
    workNatures: [
      "In Private Corp Employee (Contractual/Casual)",
      "Private Corp Employee (Top Mgmt)",
      "Private Corp Employee (Middle Mgmt)",
      "Private Corp Employee - Top 12000 Corp (Rank & File)",
      "Private Corp Employee - Non-top Corp (Rank & File)",
      "Gov't Employee (Casual/Co-terminus)",
      "Gov't Employee (Elected Officials)",
      "Gov't Employee (Top Mgmt)",
      "Gov't Employee (Rank & File)",
      "Gov't Low Ranking Uniformed Employee",
      "Owner of Business (SME) / Self Employed",
      "SME Employee",
      "OFW",
      "Religious Org and NGO Employee",
      "Religious Org Member",
      "Housewife/Househusband/Dependent",
      "Retiree",
      "Freelance",
      "Professional Practitioner",
      "Student"
    ],
    incomeSources: [
      "Investment",
      "Employment",
      "Business",
      "Allotment",
      "Remittance",
      "Inheritance",
      "Loan Proceeds",
      "Deposits"
    ]
  },
  mutations: {},
  actions: {
    canBack({ state }) {
      state.canBack = true;
    },
    cannotBack({ state }) {
      state.canBack = false;
    }
  }
});
