import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getCities(token) {
    return apiClient.get("/api/cities", {
      headers: {
        Authorization: token
      }
    });
  },
  getCitiesByProvince(token, pId) {
    return apiClient.get("/api/cities?filter[where][provinceCode]=" + pId, {
      headers: {
        Authorization: token
      }
    });
  }
};
