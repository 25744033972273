import PrangkisaService from "@/services/PrangkisaService.js";

export const namespaced = true;

export const state = {
  prangkisa: {},
  prangkisas: [],
  prangkisasByOperator: [],
  prangkisaCpcs: []
};

export const mutations = {
  SET_PRANGKISAS(state, prangkisas) {
    state.prangkisas = prangkisas;
  },
  SET_PRANGKISAS_BY_OPERATOR(state, prangkisas) {
    state.prangkisasByOperator = prangkisas;
  },
  SET_PRANGKISA(state, prangkisa) {
    state.prangkisa = prangkisa;
  },
  ADD_PRANGKISA(state, prangkisa) {
    state.prangkisas.push(prangkisa);
  },
  EDIT_PRANGKISA(state, prangkisa) {
    state.prangkisa = { ...state.prangkisa, ...prangkisa };
  },
  SET_PRANGKISA_CPCS(state, cpcs) {
    state.prangkisaCpcs = cpcs;
  },
  SET_PRANGKISA_CPC(state, cpc) {
    state.prangkisaCpcs.push(cpc);
  }
};

export const actions = {
  getPrangkisas({ commit, rootState }) {
    PrangkisaService.getPrangkisas(rootState.user.token)
      .then(response => {
        commit("SET_PRANGKISAS", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  getPrangkisasByOperator({ commit, rootState }, operatorId) {
    PrangkisaService.getPrangkisasByOperator(rootState.user.token, operatorId)
      .then(response => {
        commit("SET_PRANGKISAS_BY_OPERATOR", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  getPrangkisa({ commit, rootState, dispatch }, operatorId) {
    return PrangkisaService.getPrangkisa(rootState.user.token, operatorId)
      .then(response => {
        commit("SET_PRANGKISA", response.data.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addPrangkisa({ commit, rootState, dispatch }, newPrangkisa) {
    return PrangkisaService.addPrangkisa(
      rootState.user.token,
      rootState.user.user.name,
      newPrangkisa
    )
      .then(response => {
        commit("ADD_PRANGKISA", response.data.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editPrangkisa({ commit, rootState, dispatch }, editPrangkisa) {
    return PrangkisaService.editPrangkisa(
      rootState.user.token,
      rootState.user.user.name,
      editPrangkisa.id,
      editPrangkisa
    )
      .then(response => {
        commit("EDIT_PRANGKISA", response.data.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getPrangkisaCpcs({ commit, rootState }, prangkisaId) {
    return PrangkisaService.getCpcs(
      rootState.user.token, prangkisaId
    )
      .then(response => {
        commit("SET_PRANGKISA_CPCS", response.data.data);
      })
      .catch(e => {
        console.log(e)
      });
  },

  addCpc({ commit, rootState, dispatch }, { prangkisaId, cpc }) {
    return PrangkisaService.addCpc(
      rootState.user.token,
      prangkisaId,
      cpc
    )
      .then(response => {
        commit("SET_PRANGKISA_CPC", response.data.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(e => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },
};
