<template>
  <div class="tab-pane" id="transaction">
    <select
      @change="filter"
      id="select"
      class="form-control input-sm"
      v-model="description"
    >
      <option value>Select Description</option>
      <option v-for="(tD, i) in transactionDescriptions" :key="i" :value="tD">{{
        tD
      }}</option>
    </select>

    <fieldset class="form-group">
      <legend>Transaction(s)</legend>
      <table
        id="partner_transaction_table"
        :data-driver-id="id"
        class="table table-hover"
        width="100%"
      >
        s
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Booking ID</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Description</th>
            <th>Date</th>
          </tr>
        </thead>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      description: "",
      url: `${process.env.VUE_APP_BE_API_URL}/api/drivers/${
        this.id
      }/transactions`
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapState("driver", ["transactionDescriptions"])
  },
  methods: {
    filter() {
      let f = this.description
        ? `?filter[where][description]=${this.description}`
        : "";
      this.url = `${process.env.VUE_APP_BE_API_URL}/api/drivers/${
        this.id
      }/transactions${f}`;
      this.dataTable
        .DataTable()
        .ajax.url(this.url)
        .load();
    },
    setupDtTable(dataTable) {
      let dtColumns = [
        "transactionId",
        "bookingId",
        "debit",
        "credit",
        "description",
        "createdAt"
      ];
      const vm = this;

      $(dataTable).DataTable({
        dom:
          "<'row'<'col-sm-5'l><'col-sm-7'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: vm.url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[5, "asc"]],
        columns: [
          { data: "transactionId" },
          { data: "bookingId" },
          { data: "debit" },
          { data: "credit" },
          { data: "description" },
          { data: "createdAt" }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            width: "20%"
          },
          {
            targets: [1],
            orderable: true,
            width: "20%"
          },
          {
            targets: [2],
            orderable: false,
            width: "7.5%"
          },
          {
            targets: [3],
            orderable: false,
            width: "7.5%"
          },
          {
            targets: [4],
            orderable: false,
            width: "25%%"
          },
          {
            targets: [5],
            orderable: true,
            width: "20%",
            render: (type, data, row, meta) => {
              return moment(row.createdAt).format("LLLL");
            }
          }
        ]
      });
    }
  },
  mounted() {
    this.dataTable = $("#partner_transaction_table");
    this.setupDtTable(this.dataTable);

    $("#partner_transaction_table_wrapper .dataTables_filter").append(select);
  }
};
</script>

<style>
#partner_transaction_table tr {
  height: 45px !important;
}

#partner_transaction_table tr > td:nth-child(1) {
  width: 20% !important;
}

#partner_transaction_table tr > td:nth-child(2) {
  width: 20% !important;
}

#partner_transaction_table tr > td:nth-child(3) {
  width: 7.5% !important;
}

#partner_transaction_table tr > td:nth-child(4) {
  width: 7.5% !important;
}

#partner_transaction_table tr > td:nth-child(5) {
  width: 25% !important;
}

#partner_transaction_table tr > td:nth-child(5) {
  width: 20% !important;
}

.tt {
  position: relative;
}

.tt i.tt-t {
  cursor: pointer;
  font-weight: 900;
  color: #666;
  font-size: 1.1em;
}

.tt i.tt-t:hover {
  color: #000;
}

.tt .tt-t:hover + .tt-c {
  opacity: 1;
}

.tt .tt-c {
  position: absolute;
  left: 50%;
  top: -120%;
  transform: translateX(-50%);
  padding: 3px;
  display: inline-block;
  background: #232529;
  color: #fff;
  white-space: nowrap;
  margin-top: -5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.4s;
}

.tt .tt-c::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  border-style: solid;
  border-width: 5px;
  border-color: #232529 transparent transparent transparent;
}
</style>
