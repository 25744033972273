<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'tags' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Tag</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form id="tags_form" method="POST">
          <div class="row">
            <div class="col-md-6">
              <fieldset>
                <legend>
                  Details
                  <span @click="managing = !managing" class="tag-details-close"
                    >&times;</span
                  >
                </legend>
                <table class="table table-settings">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="name"
                          :value="tag.name"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>
                        <textarea
                          rows="5"
                          class="form-control"
                          name="description"
                          v-model="tag.description"
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
            <div class="col-md-6">
              <fieldset>
                <legend>Area Details</legend>
                <table class="table table-settings">
                  <tbody>
                    <tr>
                      <th>Center</th>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          name="center"
                          value="121.025920,14.638247"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Radius</th>
                      <td>
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            name="radius"
                            value="0"
                          />
                          <span class="input-group-addon">Km.</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>

          <div class="form-group">
            <div id="pickUpMap" style="width: 100%;height:500px;"></div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import * as proj from "ol/proj";

import Translate from "ol/interaction/Translate";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import XYZ from "ol/source/XYZ.js";
import { defaults as defaultInteractions } from "ol/interaction.js";
import { defaults as defaultControls, OverviewMap } from "ol/control.js";
import Circle from "ol/geom/Circle";
import marker from "@/assets/images/marker.png";

const updateCoordinate = (element, coordinate) => {
  element.value = proj.toLonLat(coordinate);
};

const getCoordinateOfTranslatedFeature = element => event => {
  let soleFeature = event.features
    .item(0)
    .getGeometry()
    .getCoordinates();
  updateCoordinate(element, soleFeature);
};

const geoCodePromise = address => {
  return new Promise((resolve, reject) => {
    map.geocode({ address, region: "PH" }, (err, result) => {
      resolve(result);
    });
  });
};

const fatMarkerStyle = new Style({
  image: new Icon({
    anchor: [0.5, 64],
    anchorXUnits: "fraction",
    anchorYUnits: "pixels",
    src: marker
  })
});

const urlTpl =
  "https://{1-4}.{base}.maps.api.here.com" +
  "/{type}/2.1/maptile/newest/{scheme}/{z}/{x}/{y}/256/png" +
  "?app_id={app_id}&app_code={app_code}";

const createUrl = (tpl, layerDesc) => {
  return tpl
    .replace("{base}", layerDesc.base)
    .replace("{type}", layerDesc.type)
    .replace("{scheme}", layerDesc.scheme)
    .replace("{app_id}", layerDesc.app_id)
    .replace("{app_code}", layerDesc.app_code);
};
export default {
  name: "map-openlayers",
  data() {
    return {
      tag: {
        id: "10922fba-538b-4938-b7ef-9136970771c5",
        name: "SM Fairview",
        description: "",
        shadow: {
          center: {
            lat: "14.731171977853421",
            lng: "121.0457560764047"
          },
          radius: 2000
        }
      }
    };
  },
  methods: {
    loadMarker(map, source, point) {
      let geoCoordinate = proj.fromLonLat(point.split(",").map(parseFloat));
      let pointGeom = new Point(geoCoordinate);
      let pointFeature = new Feature({
        geometry: pointGeom
      });

      pointFeature.setStyle(fatMarkerStyle);

      map.getView().setCenter(geoCoordinate);

      source.clear();
      source.addFeature(pointFeature);
      map.getView().fit(pointGeom.getExtent(), {
        size: map.getSize(),
        maxZoom: 19,
        constrainResolution: true
      });
    },
    loadRadius(map, source, point, radius) {
      let geoCoordinate = proj.fromLonLat(point.split(",").map(parseFloat));
      let radiusGeom = new Circle(geoCoordinate, radius * 1000);

      map.getView().setCenter(geoCoordinate);

      source.clear();
      source.addFeature(
        new Feature({
          geometry: radiusGeom
        })
      );
      map.getView().fit(radiusGeom.getExtent(), {
        size: map.getSize(),
        maxZoom: 19,
        constrainResolution: true
      });
    }
  },
  mounted() {
    let el = $("#tags_form");
    this.pickUpSource = new VectorSource({});
    this.pickUpLayer = new VectorLayer({
      source: this.pickUpSource
    });

    this.pickUpRadiusSource = new VectorSource({});
    this.pickUpRadiusLayer = new VectorLayer({
      source: this.pickUpRadiusSource
    });
    let pickUpTranslateInteraction = new Translate({
      layers: [this.pickUpLayer]
    });

    let oneExecutive = proj.transform(
      [121.02592, 14.638247],
      "EPSG:4326",
      "EPSG:3857"
    );
    let pickUpPointValue = document.querySelector('[name="center"]');
    let pickUpRadius = document.querySelector('[name="radius"]');

    let hereMap = new TileLayer({
      visible: true,
      preload: Infinity,
      source: new XYZ({
        url: createUrl(urlTpl, {
          base: "base",
          type: "maptile",
          scheme: "normal.day",
          app_id: "o7RlrdrTxbFB7uUwZHh9",
          app_code: "JFvW1_1PdxEVNdyrTwuRQQ"
        }),
        attributions:
          "Map Tiles &copy; " +
          new Date().getFullYear() +
          " " +
          '<a href="http://developer.here.com">HERE</a>'
      })
    });

    this.pickUpMap = new Map({
      target: "pickUpMap",
      controls: defaultControls().extend([new OverviewMap()]),
      layers: [hereMap, this.pickUpLayer, this.pickUpRadiusLayer],
      view: new View({
        center: pickUpPointValue.value
          ? proj.fromLonLat(pickUpPointValue.value.split(","))
          : oneExecutive,
        zoom: 15
      }),
      interactions: defaultInteractions().extend([pickUpTranslateInteraction])
    });

    this.pickUpMap.once("postrender", event => {
      this.pickUpMap.updateSize();
    });

    pickUpTranslateInteraction.on("translateend", e => {
      getCoordinateOfTranslatedFeature(el.querySelector('[name="center"]'))(e);
      this.loadRadius(
        this.pickUpMap,
        this.pickUpRadiusSource,
        pickUpPointValue.value,
        pickUpRadius.value
      );
    });

    if (pickUpPointValue.value) {
      this.loadMarker(
        this.pickUpMap,
        this.pickUpSource,
        pickUpPointValue.value
      );

      if (pickUpRadius.value) {
        this.loadRadius(
          this.pickUpMap,
          this.pickUpRadiusSource,
          pickUpPointValue.value,
          pickUpRadius.value
        );
      }
    }

    pickUpRadius.addEventListener("change", e => {
      this.loadRadius(
        this.pickUpMap,
        this.pickUpRadiusSource,
        pickUpPointValue.value,
        pickUpRadius.value
      );
    });
  }
};
</script>

<style>
.tag-details-close {
  font-size: 1em;
  font-weight: 600;
  font-variant: small-caps;
  padding: 0 10px 0 5px;
  float: right;
  cursor: pointer;
}

.tag-details-close:hover {
  color: #dd4b39;
}
</style>
