import UserService from "@/services/UserService.js";

export const namespaced = true;

export const state = {
  user: null,
  users: [],
  token: ""
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_TOKEN(state, token) {
    state.token = token;
    sessionStorage.setItem("owto-token", token);
    console.log(token);
    // axios.defaults.headers.common["Authorization"] = token;
  }
};

export const actions = {
  setUserProfile({ commit, getters }, { oid, name, upn }) {
    let owtoUser = { oid, name, email: upn };

    return UserService.getUsers()
      .then(response => {
        const users = response.data.users;

        let existingUser = users ?
          users.find(u => u.oid === users.oid) :
          null;

        if (!existingUser) {
          return UserService.addUser(owtoUser)
            .then(response => {
              commit("SET_USER", response.data.user);
            });
        } else {
          commit("SET_USER", existingUser);
        }
      })
      .catch(err => console.log(err.message));
  },

  getToken({ commit, getters }) {
    let token = getters.checkToken;
    if (token) {
      commit("SET_TOKEN", token);
    } else {
      UserService.getToken()
        .then(response => {
          commit("SET_TOKEN", response.data.id);
        })
        .catch(err => console.log(err.message));
    }
  }
};

export const getters = {
  checkToken() {
    return sessionStorage.getItem("owto-token");
  },
  checkUsers() {
    return id => users.find(user => user.id === id);
  }
};
