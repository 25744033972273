import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getRiders(token) {
    // fetch all riders
    return apiClient.get("/api/riders?filter[limit]=10&start=4&draw=2", {
      headers: {
        Authorization: token
      }
    });
  },

  getRider(token, id) {
    // fetch rider by id
    return apiClient.get("/api/riders/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  editRider(token, username, id, rider) {
    // edit rider by id
    return apiClient.patch("/api/riders/" + id, rider, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  getRiderEmployers(token, riderId) {
    // get rider employers
    return apiClient.get(`/api/riders/${riderId}/employers`, {
      headers: {
        Authorization: token
      }
    });
  },

  assignRiderEmployer(token, riderId, employerId) {
    // assign rider employer
    return apiClient.post(
      `/api/riders/${riderId}/employers`,
      {
        employerId: employerId
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  deleteRiderEmployer(token, riderId, employerId) {
    // delete rider employer
    return apiClient.delete(`/api/riders/${riderId}/employers/${employerId}`, {
      headers: {
        Authorization: token
      }
    });
  },

  sendEmail(token, bookingId) {
    // send receipt
    return apiClient.put(`/api/trips/${bookingId}/email`, null, {
      headers: {
        Authorization: token
      }
    });
  }
};
