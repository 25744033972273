import VehicleService from "@/services/VehicleService.js";

export const namespaced = true;

export const state = {
  vehicle: {},
  vehicles: []
};

export const mutations = {
  ADD_VEHICLE(state, vehicle) {
    state.vehicles.push(vehicle);
  },
  SET_VEHICLE(state, vehicle) {
    state.vehicle = vehicle;
  }
};

export const actions = {
  addVehicle({ commit, rootState, dispatch }, vehicle) {
    return VehicleService.addVehicle(
      rootState.user.token,
      rootState.user.user.name,
      vehicle
    )
      .then(response => {
        commit("ADD_VEHICLE", response.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getVehicle({ commit, rootState, dispatch }, vid) {
    return VehicleService.getVehicle(rootState.user.token, vid)
      .then(response => {
        commit("SET_VEHICLE", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetching Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editVehicle({ commit, rootState, dispatch }, vehicle) {
    return VehicleService.editVehicle(
      rootState.user.token,
      rootState.user.user.name,
      vehicle.id,
      vehicle
    )
      .then(response => {
        commit("SET_VEHICLE", response.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
