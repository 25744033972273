import PoiService from "@/services/PoiService.js";

export const namespaced = true;

export const state = {
  poi: null,
  pois: []
};

export const mutations = {
  SET_POIS(state, pois) {
    state.pois = pois;
  },
  SET_POI(state, poi) {
    state.poi = poi;
  },
  EDIT_POI(state, poi) {
    state.pois.forEach((p, i) => {
      if (p.id === poi.id) {
        state.pois[i] = { ...poi };
      }
    });
  },
  ADD_POI(state, poi) {
    state.pois.push(poi);
  }
};

export const actions = {
  getPois({ commit, rootState }) {
    return PoiService.getPois(rootState.user.token)
      .then(response => {
        commit("SET_POIS", response.data);
      })
      .catch(error => {
        throw error;
      });
  },

  getPoi({ commit, rootState, dispatch }, id) {
    return PoiService.getPoi(rootState.user.token, id)
      .then(response => {
        commit("SET_POI", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editPoi({ commit, rootState, dispatch }, poi) {
    return PoiService.editPoi(rootState.user.token, poi.id, poi)
      .then(response => {
        commit("EDIT_POI", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addPoi({ commit, rootState, dispatch }, poi) {
    return PoiService.addPoi(rootState.user.token, poi)
      .then(response => {
        commit("ADD_POI", response.data);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Add Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
