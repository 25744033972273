import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  addVehicle(token, username, vehicle) {
    // add vehicle
    return apiClient.post("/api/vehicles", vehicle, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  getVehicle(token, vid) {
    // get vehicle
    return apiClient.get("/api/vehicles/" + vid, {
      headers: {
        Authorization: token
      }
    });
  },

  editVehicle(token, username, vid, vehicle) {
    // edit vehicle
    return apiClient.patch("/api/vehicles/" + vid, vehicle, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  }
};
