<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'vehicle-models' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form @submit.prevent="add" method="POST">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Make</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="modelMake"
                    required="required"
                    v-model.trim="model.modelMake"
                  />
                </td>
              </tr>
              <tr>
                <th>Manufacturer</th>
                <td>
                  <select
                    class="form-control"
                    name="modelManufacturer"
                    v-model="model.modelManufacturer"
                  >
                    <option
                      v-for="(mm, i) in modelManufacturers"
                      :key="i"
                      :value="mm"
                      >{{ mm }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <th>Year</th>
                <td>
                  <input
                    type="number"
                    min="1900"
                    max="2099"
                    step="1"
                    class="form-control"
                    name="modelYear"
                    required="required"
                    v-model.number="model.modelYear"
                  />
                </td>
              </tr>
              <tr>
                <th>Product Types</th>

                <td>
                  <div v-for="(v, i) in variants" :key="i" class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="productTypes"
                        v-model="model.productTypes"
                        :value="v.id"
                      />
                      {{ v.variantName }}
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapState } from "vuex";

export default {
  data() {
    return {
      model: {
        modelMake: null,
        modelManufacturer: null,
        modelYear: 2019,
        productTypes: [],
        isSubmitting: false
      },
      modelManufacturers: [
        "Honda",
        "Hyundai",
        "Isuzu",
        "Kia",
        "Mazda",
        "Mitsubishi",
        "Nissan",
        "Suzuki",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Chevrolet",
        "Ford",
        "Subaru"
      ]
    };
  },
  methods: {
    add() {
      if (this.isSubmitting) return;
      let answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("vehicleModel/addVehicleModel", this.model)
        .then(() => {
          this.$router.push(
            {
              name: "vehicle-models"
            },
            null,
            () => {
              this.isSubmitting = false;
            }
          );
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  },
  computed: {
    ...mapState("variant", ["variants"])
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("variant/getVariants")
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
  }
};
</script>

<style></style>
