import RegionService from "@/services/RegionService.js";

export const namespaced = true;

export const state = {
  regions: []
};

export const mutations = {
  SET_REGIONS(state, regions) {
    state.regions = regions;
  }
};

export const actions = {
  getRegions({ commit, rootState, getters }) {
    if (getters.checkRegions) {
      return;
    } else {
      return RegionService.getRegions(rootState.user.token).then(response => {
        commit("SET_REGIONS", response.data);
      });
    }
  }
};

export const getters = {
  checkRegions: function(state) {
    return state.regions.length;
  }
};
