import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getTags(token) {
    // get tags
    return apiClient.get(`/api/tags`, {
      headers: {
        Authorization: token
      }
    });
  },

  addTag(token, tag) {
    // add tag
    return apiClient.post(`/api/tags/`, tag, {
      headers: {
        Authorization: token
      }
    });
  },

  getTag(token, id) {
    // get tag
    return apiClient.get("/api/tags/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  editTag(token, id, tag) {
    // get tag
    return apiClient.patch("/api/tags/" + id, tag, {
      headers: {
        Authorization: token
      }
    });
  },

  deleteTag(token, id) {
    // delete tag
    return apiClient.delete("/api/tags/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  getTagDrivers(token, id) {
    // get tag drivers
    return apiClient.get("/api/tags/" + id + "/drivers", {
      headers: {
        Authorization: token
      }
    });
  },

  addTagDriver(token, id, driverId) {
    // add tag driver
    return apiClient.post(
      "/api/tags/" + id + "/drivers",
      { driverId: driverId },
      {
        headers: {
          Authorization: token
        }
      }
    );
  },

  removeTagDriver(token, id, driverId) {
    // remove tag driver
    return apiClient.delete(`api/tags/${id}/drivers/${driverId}`, {
      headers: {
        Authorization: token
      }
    });
  }
};
