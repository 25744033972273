<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <router-link :to="{ name: 'routes' }">
          <i class="fa fa-caret-left"></i>
          Back
        </router-link>
        <h1>Edit Route</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form @submit.prevent="editRoute" method="POST">
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    <input
                      class="form-control"
                      type="text"
                      name="name"
                      v-model.trim="route.name"
                      required="required"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>
                    <textarea
                      class="form-control"
                      name="description"
                      v-model.trim="route.description"
                      required="required"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          name="isActive"
                          v-model="route.isActive"
                        />
                        Active
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Distance</th>
                  <td>
                    <input
                      class="form-control"
                      type="number"
                      name="distance"
                      v-model.number="route.distance"
                      required="required"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td>
                    <input
                      class="form-control"
                      type="number"
                      name="duration"
                      v-model.number="route.duration"
                      required="required"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>

          <table class="table table-settings">
            <tbody>
              <tr>
                <th>
                  POIs
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#routePoiModal"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </th>
                <td>
                  <form method="POST" @submit.prevent="editPoisWeight">
                    <table id="poi-order" class="table table-settings">
                      <tbody>
                        <tr>
                          <th colspan="2">
                            <draggable
                              v-model="myPois"
                              class="list-group"
                              group="people"
                              ghost-class="ghost"
                              @start="drag = true"
                              @end="drag = false"
                            >
                              <transition-group>
                                <div
                                  class="list-group-item"
                                  v-for="(poi, i) in myPois"
                                  :key="i"
                                >
                                  <i
                                    class="fa fa-map-pin fa-fw"
                                    aria-hidden="true"
                                  ></i>
                                  {{ poi.label }}
                                  <span class="text-muted small">
                                    {{ poi.description }}
                                  </span>
                                  <span class="badge">{{ poi.weight }}</span>
                                </div>
                              </transition-group>
                            </draggable>
                          </th>
                        </tr>

                        <tr>
                          <th></th>
                          <td>
                            <button type="submit" class="btn btn-primary">
                              Submit
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="routePoiModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Add POI</h4>
          </div>
          <div class="modal-body">
            <table
              id="route_poi_table"
              :data-route-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapState } from "vuex";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  beforeRouteEnter(to, from, next) {
    axios
      .all([
        store.dispatch("poi/getPois"),
        store.dispatch("route/getRoutePois", to.params.id),
        store.dispatch("route/getRoute", to.params.id)
      ])
      .then(
        axios.spread(() => {
          next();
        })
      )
      .catch(() => {
        if (navigator.onLine) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  created() {
    this.myPois = this.routePois;
  },
  computed: {
    ...mapState("route", ["route", "routePois"]),
    ...mapState("poi", ["pois"]),
    ...mapState("user", ["token"])
    // routePois() {
    //   if (this.route.pois.length) {
    //     return this.route.pois.map(poi => {
    //       return poi.id;
    //     });
    //   } else {
    //     return "";
    //   }
    // },
    // routeWeight() {
    //   return id => {
    //     return this.route.poiMeta.find(poi => {
    //       return poi.poiId === id;
    //     }).weight;
    //   };
    // }
  },
  data() {
    return {
      myPois: [],
      isSubmittingRoute: false,
      isSubmittingRoutePois: false,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["label", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [{ data: "label" }, { data: null }],
        columnDefs: [
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let selected = vm.routePois.find(poi => poi.id === row.id)
                ? true
                : false;
              let icon = selected ? "fa-check" : "fa-ban";
              let btn = selected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-selected="${selected}"
                              data-route-id="${dataTable[0].dataset.routeId}"
                              data-route-poi-id="${row.id}">
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;

              return button;
            }
          }
        ]
      });

      $("#route_poi_table").on("click", "[data-route-poi-id]", e => {
        if (vm.isSubmitting) return;
        let { selected, routeId, routePoiId } = e.target.dataset;
        selected = JSON.parse(selected); // convert string to boolean

        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;

        if (!selected) {
          axios
            .post(
              `${process.env.VUE_APP_BE_API_URL}/api/routes/${routeId}/poi`,
              { poiId: routePoiId },
              {
                headers: {
                  Authorization: vm.token
                }
              }
            )
            .then(() => {
              return axios
                .put(
                  `${
                    process.env.VUE_APP_BE_API_URL
                  }/api/routes/${routeId}/poi/${routePoiId}`,
                  {
                    weight: vm.routePois.length
                  },
                  {
                    headers: {
                      Authorization: this.token
                    }
                  }
                )
                .then(() => {
                  return store
                    .dispatch("route/getRoutePois", routeId)
                    .then(() => {
                      this.myPois = this.routePois;
                      $("#route_poi_table")
                        .DataTable()
                        .ajax.reload(() => {
                          vm.isSubmitting = false;
                        });
                    });
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        } else {
          axios
            .delete(
              `${
                process.env.VUE_APP_BE_API_URL
              }/api/routes/${routeId}/poi/${routePoiId}`,
              {
                headers: {
                  Authorization: vm.token
                }
              }
            )
            .then(() => {
              return store.dispatch("route/getRoutePois", routeId).then(() => {
                this.myPois = this.routePois;
                $("#route_poi_table")
                  .DataTable()
                  .ajax.reload(() => {
                    vm.isSubmitting = false;
                  });
              });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      });
    },
    editRoute() {
      if (this.isSubmittingRoute) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmittingRoute = true;
      for (let r in this.route) {
        if (this.route[r] === "") {
          this.route[r] = null;
        }
      }
      store
        .dispatch("route/editRoute", this.route)
        .then(() => {
          this.isSubmittingRoute = false;
        })
        .catch(() => {
          this.isSubmittingRoute = false;
        });
    },
    editPoisWeight() {
      if (this.isSubmittingRoutePois) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmittingRoutePois = true;

      let ctr = 0;
      let len = this.routePois.length;
      this.myPois.forEach((poi, i) => {
        poi.weight = i;
        axios
          .put(
            `${process.env.VUE_APP_BE_API_URL}/api/routes/${this.id}/poi/${
              poi.id
            }`,
            {
              weight: i
            },
            {
              headers: {
                Authorization: this.token
              }
            }
          )
          .then(() => {
            ctr++;
            if (ctr === len) {
              this.$store
                .dispatch("route/getRoutePois", this.id)
                .then(() => {
                  this.myPois = this.routePois;
                })
                .then(() => {
                  this.isSubmittingRoutePois = false;
                });
            }
          })
          .catch(() => {
            this.isSubmittingRoutePois = false;
          });
      });
    }
  },
  mounted() {
    this.dataTable = $("#route_poi_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/poi`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style scoped>
/* #poi-order tbody > tr:not(:last-child) > th {
  padding-left: 25px;
}

#poi-order tbody > tr:not(:last-child) > th > i {
  margin-left: -20px;
} */
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
