import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getDisbursementReports(token, driverId) {
    return apiClient.get(`/api/disbursement-tx/reports/${driverId}`, {
      headers: {
        Authorization: token
      }
    });
  }
};
