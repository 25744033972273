<template>
  <div class="box box-primary">
    <div class="box-body box-profile">
      <div class="profile-picture-widget">
        <div class="wrapper-img">
          <img
            @click="showSingle"
            :src="subModule.displayPhoto ? subModule.displayPhoto : dummyimg"
            alt="User Profile Picture"
          />
        </div>

        <div class="p-image">
          <i class="fa fa-camera upload-button"></i>
          <input class="file-upload" type="file" accept="image/*" />
        </div>
      </div>

      <h3 class="profile-username text-center" ref="user">
        {{ subModule.firstName }} {{ subModule.middleName }}
        {{ subModule.lastName }}
        {{ subModule.suffix }}
      </h3>

      <ul v-if="view === 'Partner'" class="list-group list-group-unbordered">
        <li class="list-group-item">
          <b>Activation Status</b>
          <span
            v-if="subModule.activationStatus === 'ACTIVATED'"
            class="pull-right label label-primary"
            >{{ subModule.activationStatus }}</span
          >

          <span v-else class="pull-right label label-danger">
            {{ subModule.activationStatus }}
          </span>
        </li>

        <li data-v-560fc707 class="list-group-item">
          <b data-v-560fc707>Online Status</b>
          <span data-v-560fc707 class="pull-right conStat">
            <i class="fa fa-circle" :class="conStat" aria-hidden="true"
              >&nbsp;{{ conStat }}</i
            >
            &nbsp;&nbsp;
            <i
              title="Force refresh"
              @click="getDriverStat"
              class="fa fa-refresh"
              :class="togRot"
              aria-hidden="true"
            ></i>
          </span>
        </li>

        <li class="list-group-item">
          <b>Top Up Balance</b>
          <a class="pull-right">{{ topUp.balance }}</a>
        </li>

        <li v-if="NODE_ENV === 'development'" class="list-group-item">
          <p>
            <b>Call</b>
          </p>
          <div class="clearfix">
            <select
              class="form-control pull-left"
              id="call_mode"
              style="width: 80%"
            >
              <option value="peep">PEEP</option>
              <option value="2way">2-WAY</option>
            </select>
            <button
              type="button"
              class="btn btn-primary btn-sm pull-right"
              data-toggle="modal"
              data-target="#callDriverModal"
              id="callDriverButton"
              :data-driver-id="module.userId"
              disabled
            >
              <i class="fa fa-phone"></i>
            </button>
          </div>
        </li>

        <li class="list-group-item">
          <b>Fleets</b>
          <ul class="list-group list-group-unbordered tag-list">
            <li
              v-for="(fleet, index) in fleetsByPartner"
              :key="index"
              class="list-group-item"
            >
              <span class="label label-primary exp">{{ fleet.name }}</span>
            </li>
          </ul>
        </li>

        <li class="list-group-item">
          <b>Tags</b>
          <div class="driver-group">
            <select v-model="selectedTag">
              <option disabled value>Select Tag</option>
              <option
                v-for="(tag, i) in filteredTags"
                :key="i"
                :value="tag.id"
                >{{ tag.name }}</option
              >
            </select>
            <i class="fa fa-plus" @click="assignTag"></i>
          </div>

          <ul class="list-group list-group-unbordered tag-list">
            <li
              v-for="(dT, index) in driverTags"
              :key="index"
              class="list-group-item"
            >
              <span class="label label-primary exp">
                {{ dT.name }}
                <span class="driver-tag-close" @click="removeTag(dT.id)"
                  >&times;</span
                >
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
// import VueEasyLightBox from "vue-easy-lightbox";
import { setInterval, clearInterval, setTimeout, clearTimeout } from "timers";
import EventBus from "@/event-bus.js";
import dummyimg from "@/assets/images/dummy.jpg";

export default {
  // components: {
  //   VueEasyLightBox
  // },
  props: {
    view: {
      type: String,
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    },
    module: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dummyimg: dummyimg,
      subModule: { ...this.module },
      imgs: "",
      visible: false,
      selectedTag: "",
      timeInterval: null,
      rotate: false,
      rotOut: null,
      isAssigning: false,
      isRemoving: false
    };
  },
  methods: {
    showSingle() {
      this.imgs = this.subModule.displayPhoto;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    assignTag() {
      if (this.isAssigning) return;
      if (!this.selectedTag) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isAssigning = true;

      this.$store
        .dispatch("driver/addDriverTag", {
          id: this.id,
          tagId: this.selectedTag
        })
        .then(() => {
          this.selectedTag = "";
          this.isAssigning = false;
        })
        .catch(() => {
          this.isAssigning = false;
        });
    },
    removeTag(id) {
      if (this.isRemoving) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isRemoving = true;

      this.$store
        .dispatch("driver/removeDriverTag", {
          id: this.id,
          tagId: id
        })
        .then(() => {
          this.selectedTag = "";
          this.isRemoving = false;
          console.log(this.driverTags);
        })
        .catch(() => {
          this.isRemoving = false;
        });
    },
    ...mapActions("driver", ["getDriverStatus"]),
    getDriverStat() {
      if (this.rotate) return;
      this.rotate = true;
      this.rotOut = setTimeout(() => (this.rotate = false), 700);
      this.getDriverStatus(this.id);
    }
  },
  computed: {
    imgClass() {
      return this.view === "Partner" ? "driver" : "rider";
    },
    ...mapState("fleet", ["fleetsByPartner"]),
    ...mapState("balance", ["topUp"]),
    ...mapState(["NODE_ENV"]),
    ...mapState("user", ["token", "user"]),
    ...mapState("driver", [
      "driverTags",
      "driverStatus",
      "driverStatusRequest"
    ]),
    ...mapState("tag", ["tags"]),
    filteredTags() {
      return this.tags.filter(tag => {
        let isExist = this.driverTags.find(dTag => {
          return dTag.id === tag.id;
        });
        return !isExist;
      });
    },
    conStat() {
      return this.driverStatus ? "online" : "offline";
    },
    togRot() {
      return this.rotate ? "reload-ani" : "";
    }
  },
  watch: {
    driverStatusRequest: function(newReq, oldReq) {
      if (newReq == "pending" || newReq == "error") {
        clearInterval(this.timeInterval);
      } else {
        this.timeInterval = setInterval(
          () => this.getDriverStatus(this.id),
          5000
        );
      }
    }
  },
  created() {
    this.subModule = { ...this.module };
    this.getDriverStatus(this.id);
  },
  mounted() {
    EventBus.$on("update-module", module => {
      this.subModule = { ...this.subModule, ...module };
    });
    let vm = this;
    let entity = this.imgClass;
    let uid = this.module.userId;
    var uploadButton = document.querySelector(".upload-button");
    var fileUpload = document.querySelector(".file-upload");
    var imgC = document.querySelector(`.profile-partner-img`);

    uploadButton.addEventListener("click", () => fileUpload.click());

    fileUpload.addEventListener(
      "change",
      function(event) {
        // When the control has changed, there are new files

        let file = event.target.files[0],
          imageForm = new FormData();
        if (file) {
          imageForm.append("file", file); // form data use to http request
          // api call storing file
          return axios
            .post(
              `${process.env.VUE_APP_BE_API_URL}/api/${entity}s/${uid}/image`,
              imageForm,
              {
                headers: {
                  Authorization: vm.token,
                  "X-Owto-Request-By": vm.user.name
                }
              }
            )
            .then(response => {
              // save file to the server before reading it to the browser
              vm.module.displayPhoto = response.data.displayPhoto;
              readURL(imgC)(fileUpload);
            });
        }
      },
      false
    );

    const readURL = profileImgEl => input => {
      // reading file
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = e => {
          profileImgEl.setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearTimeout(this.rotOut);
  }
};
</script>

<style scoped>
img {
  cursor: pointer !important;
}

.exp {
  width: 100%;
  text-align: initial;
  display: block;
  font-size: 12.5px !important;
}

.driver-tag-close {
  font-size: 1em;
  float: right;
  cursor: pointer;
}

.driver-tag-close:hover {
  color: #ddd;
}

.driver-group::before,
.driver-group::after {
  content: "";
  display: table;
  clear: both;
}

.driver-group select {
  float: left;
  width: 90%;
}

.driver-group i {
  float: left;
  width: 10%;
  color: #666;
  cursor: pointer;
  padding: 5px;
}

.driver-group i:hover {
  color: #999;
}

.tag-list {
  margin: 0;
}

.tag-list li {
  padding: 3px;
  border: none;
}

.conStat i:first-child {
  font-size: 0.8em;
  font-variant: small-caps;
}

.conStat i:first-child + i {
  cursor: pointer;
  font-weight: 900;
  font-size: 1.2em;
}
.conStat i:first-child + i:hover {
  color: #666;
}

.online {
  color: #3c763d;
}
.offline {
  color: #a94442;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
.reload-ani {
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

div.wrapper-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  border: 3px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}
.wrapper-img > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 194px;
  height: auto;
}
</style>
