<template>
  <div class="content-wrapper">
    <section class="content-header">
      <a @click.prevent="routeBack">
        <i class="fa fa-caret-left"></i>
        Back
      </a>
      <h1>New Vehicle</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form method="POST" @submit.prevent="newVehicle">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Case Number</th>
                <td>
                  <select
                    name="caseNumber"
                    class="form-control"
                    required="required"
                    v-model="vehicle.caseNumber"
                  >
                    <option
                      v-for="prangkisa in prangkisasByOperator"
                      :key="prangkisa.id"
                      :value="prangkisa.id"
                      >{{ prangkisa.caseNumber }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <th>Plate Number</th>
                <td>
                  <input
                    required="required"
                    type="text"
                    class="form-control"
                    name="plateNumber"
                    v-model.trim="vehicle.plateNumber"
                  />
                </td>
              </tr>
              <tr>
                <th>Conduction Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="conductionNumber"
                    v-model.trim="vehicle.conductionNumber"
                  />
                </td>
              </tr>
              <tr>
                <th>Engine Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="engineNumber"
                    v-model.trim="vehicle.engineNo"
                  />
                </td>
              </tr>
              <tr>
                <th>Chassis Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="chassisNumber"
                    v-model.trim="vehicle.chassisNo"
                  />
                </td>
              </tr>
              <tr>
                <th>Lon Number</th>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="lon"
                    v-model.trim="vehicle.lon"
                  />
                </td>
              </tr>
              <tr>
                <th>Car Color</th>
                <td>
                  <select
                    class="form-control"
                    name="carColor"
                    v-model="vehicle.carColor"
                    required="required"
                  >
                    <option
                      v-for="color in colors"
                      :key="color.id"
                      :value="color.color"
                      >{{ color.color }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <th>Car Model</th>
                <td>
                  <select
                    class="form-control"
                    name="carModel"
                    v-model="vehicle.carModel"
                    required="required"
                  >
                    <option
                      v-for="model in vehicleModels"
                      :key="model.id"
                      :value="model.id"
                    >
                      {{ model.modelManufacturer }} {{ model.modelMake }}
                      {{ model.modelYear }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Activated Product</th>
                <td>
                  <div
                    class="checkbox"
                    v-for="variant in variants"
                    :key="variant.id"
                  >
                    <label>
                      <input
                        type="checkbox"
                        name="activatedProduct"
                        :value="variant.id"
                        v-model="vehicle.activatedProduct"
                      />
                      {{ variant.variantName }}
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Remarks</th>
                <td>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    v-model.trim="vehicle.remarks"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      vehicle: this.createNewVehicle(),
      isSubmitting: false,
      isRouting: false
    };
  },
  methods: {
    createNewVehicle() {
      return {
        id: null,
        caseNumber: null,
        plateNumber: null,
        conductionNumber: null,
        carColor: null,
        carModel: null,
        documents: null,
        remarks: null,
        activatedProduct: [],
        vehicleTitle: null,
        insuranceTpl: null,
        insuranceComprehensive: null,
        engineNo: null,
        chassisNo: null,
        lon: null
      };
    },
    newVehicle() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      if (!this.vehicle.activatedProduct.length) {
        const notification = {
          type: "error",
          message: "Required Input, Choose atleast 1 activated product"
        };
        this.$store.dispatch("notification/add", notification, { root: true });
        this.isSubmitting = false;
        return;
      }
      this.$store
        .dispatch("vehicle/addVehicle", this.vehicle)
        .then(vehicle => {
          this.$store
            .dispatch("operator/addOperatorVehicle", {
              operatorId: this.id,
              vehicleId: vehicle.id
            })
            .then(() => {
              this.$router.push(
                {
                  name: "operators-edit",
                  params: { id: this.id }
                },
                null,
                () => {
                  this.isSubmitting = false;
                }
              );
              console.log("success add vehicle");
            });
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;
      this.$router.push(
        { name: "operators-edit", params: { id: this.id } },
        null,
        () => {
          this.isRouting = false;
        }
      );
    }
  },
  created() {
    this.$store.dispatch("prangkisa/getPrangkisasByOperator", this.id);
    this.$store.dispatch("color/getColors");
    this.$store.dispatch("vehicleModel/getVehicleModels");
    this.$store.dispatch("variant/getVariants");
  },
  computed: {
    ...mapState("prangkisa", ["prangkisasByOperator"]),
    ...mapState("color", ["colors"]),
    ...mapState("vehicleModel", ["vehicleModels"]),
    ...mapState("variant", ["variants"])
  }
};
</script>

<style></style>
