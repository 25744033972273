import CityService from "@/services/CityService.js";

export const namespaced = true;

export const state = {
  cities: [],
  citiesByProvince: []
};

export const mutations = {
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_CITIES_BY_PROVINCE(state, { cities, pId }) {
    state.citiesByProvince = cities;
    if (pId) {
      if (state.cities.length) {
        let isExist = state.cities.find(c => {
          return c.provinceCode == pId;
        });
        if (isExist) return;
        state.cities = state.cities.concat(cities);
      } else {
        state.cities = cities;
      }
    }
  },
  CLEAR_CITIES_BY_PROVINCE(state) {
    state.citiesByProvince = [];
  }
};

export const actions = {
  getCities({ commit, rootState, getters }) {
    if (getters.checkCities) {
      return;
    } else {
      CityService.getCities(rootState.user.token).then(data => {
        commit("SET_CITIES", data.data);
      });
    }
  },
  getCitiesByProvince({ commit, rootState, getters }, pId) {
    const cities = getters.getCitiesByProvince(pId);
    if (cities.length) {
      commit("SET_CITIES_BY_PROVINCE", { cities: cities, pId: null });
    } else {
      commit("CLEAR_CITIES_BY_PROVINCE");
      CityService.getCitiesByProvince(rootState.user.token, pId).then(
        response => {
          commit("SET_CITIES_BY_PROVINCE", { cities: response.data, pId: pId });
        }
      );
    }
  }
};

export const getters = {
  checkCities: function(state) {
    return state.cities.length;
  },
  getCitiesByProvince: state => pId => {
    return state.cities.filter(city => city.provinceCode === pId);
  }
};
