<template>
  <footer class="main-footer">
    <strong>
      Copyright &copy; 2018
      <a href="https://owtoph.com">Owto</a>.
    </strong>
    All rights reserved.
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
