import LocationService from "@/services/LocationService.js";

export const namespaced = true;

export const state = {
  driversLocation: []
};

export const mutations = {
  SET_DRIVERS_LOCATION(state, driversLocation) {
    state.driversLocation = driversLocation;
  }
};

export const actions = {
  getDriversLocation({ commit, rootState }, date) {
    return LocationService.getDriversLocation(date)
      .then(response => {
        commit("SET_DRIVERS_LOCATION", response.data);
      })
      .catch(error => console.log(error));
  }
};
