import NoteService from "@/services/NoteService.js";

export const namespaced = true;

export const state = {
  note: {
    createdAt: "2019-04-30T08:41:18.220Z",
    entity: "operator",
    id: "7ae96ddc-e9b7-4987-8566-886d3efd8b3e",
    note: "aw",
    userId: "23",
    username: "jerry.sale@owtoph.com"
  },
  notes: []
};

export const mutations = {
  ADD_NOTE(state, note) {
    state.notes.push(note);
  }
};

export const actions = {
  addNote({ commit, rootState, dispatch }, note) {
    note = { ...note, username: rootState.user.user.name };
    if (!rootState.user.user.name) {
      const notification = {
        type: "error",
        message: "username required"
      };
      dispatch("notification/add", notification, { root: true });
    }
    return NoteService.addNote(
      rootState.user.token,
      rootState.user.user.name,
      note
    )
      .then(response => {
        commit("ADD_NOTE", response.data);
        // const notification = {
        //   type: "success",
        //   message: "Added Succesfully"
        // };
        // dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        // const notification = {
        //   type: "error",
        //   message: "Add Failed, "
        // };
        // if (error.request.response) {
        //   let message = JSON.parse(error.request.response).error.message;
        //   notification.message += message;
        // } else {
        //   notification.message += error.message;
        // }
        // dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
