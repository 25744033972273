<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Vehicle Models
        <a @click.prevent="routeGo" class="btn btn-primary btn-sm">Add</a>
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <!-- <div class="form-group">
          <ul class="list-inline">
            <li><a href="/schedules">PENDING</a></li>
            <li><a href="/schedules/others" class="text-muted">OTHERS</a></li>
          </ul>
        </div>-->

        <div class="box box-primary">
          <div class="box-body">
            <table
              id="vehicle_models_table"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Make</th>
                  <th>Manufacturer</th>
                  <th>Year</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataTable: null,
      isRouting: false,
      isFetching: false
    };
  },
  computed: {
    ...mapState("user", ["token"])
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["modelMake", "modelManufacturer", "modelYear", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        columns: [
          { data: "modelMake" },
          { data: "modelManufacturer" },
          { data: "modelYear" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [3],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "VehicleModel")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-vehicle-model-edit-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;
              return button;
            }
          }
        ]
      });

      $("#vehicle_models_table").on(
        "click",
        "[data-vehicle-model-edit-id]",
        e => {
          if (vm.isFetching) return;
          vm.isFetching = true;
          let { vehicleModelEditId } = e.target.dataset;
          vm.$router.push(
            {
              name: "vehicle-models-edit",
              params: { id: vehicleModelEditId }
            },
            null,
            () => {
              vm.isFetching = false;
            }
          );
          e.preventDefault();
        }
      );
    },
    routeGo() {
      if (this.isRouting) return;
      this.isRouting = true;
      this.$router.push({ name: "vehicle-models-new" }, null, () => {
        this.isRouting = false;
      });
    }
  },
  mounted() {
    this.dataTable = $("#vehicle_models_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/vehicle-models`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
