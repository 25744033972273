import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getGcashCards(token) {
    return apiClient.get("/api/gcash-cards?filter[where][isDefective]=false", {
      headers: {
        Authorization: token
      }
    });
  },

  assignGcashCard(token, gcashId, driverId, username) {
    return apiClient.patch(
      "/api/gcash-cards/" + gcashId,
      { driverId: driverId },
      {
        headers: {
          Authorization: token,
          "X-Owto-Request-By": username
        }
      }
    );
  }
};
