<template>
  <div class="content-wrapper">
    <section class="content-header">
      <router-link :to="{ name: 'variants' }">
        <i class="fa fa-caret-left"></i>
        Back
      </router-link>
      <h1>Variant</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form id="schedule_form" method="POST" @submit.prevent="editVariant">
          <fieldset>
            <legend>Description</legend>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>Variant Code</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="variantCode"
                      required="required"
                      v-model.trim="variant.variantCode"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Variant Name</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="variantName"
                      required="required"
                      v-model.trim="variant.variantName"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Fare Matrix</legend>
            <table class="table table-settings">
              <tbody>
                <tr>
                  <th>Base Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      required="required"
                      name="base"
                      min="0"
                      v-model.number="variant.base"
                      step="0.01"
                      pattern="^\d+(?:\.\d{1,2})?$"
                      onblur="/^\d+(?:\.\d{1,2})?$/.test(this.value) ? this.parentNode.parentNode.classList.remove('has-error') : this.parentNode.parentNode.classList.add('has-error')"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Per Kilometre Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      required="required"
                      name="base"
                      min="0"
                      v-model.number="variant.perKm"
                      step="0.01"
                      pattern="^\d+(?:\.\d{1,2})?$"
                      onblur="/^\d+(?:\.\d{1,2})?$/.test(this.value) ? this.parentNode.parentNode.classList.remove('has-error') : this.parentNode.parentNode.classList.add('has-error')"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Per Minute Fare</th>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="0.00"
                      required="required"
                      name="base"
                      min="0"
                      v-model.number="variant.perMinute"
                      step="0.01"
                      pattern="^\d+(?:\.\d{1,2})?$"
                      onblur="/^\d+(?:\.\d{1,2})?$/.test(this.value) ? this.parentNode.parentNode.classList.remove('has-error') : this.parentNode.parentNode.classList.add('has-error')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapState } from "vuex";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("variant/getVariant", to.params.id)
      .then(() => {
        next();
      })
      .catch(error => {
        if (error.response.status === 404) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("variant", ["variant"])
  },
  methods: {
    editVariant() {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      store
        .dispatch("variant/editVariant", this.variant)
        .then(() => {
          this.$router.push({ name: "variants" }, null, () => {
            this.isSubmitting = false;
          });
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style></style>
