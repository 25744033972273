<template>
  <header class="main-header">
    <a href="/" class="logo">
      <span class="logo-mini">
        <b>O</b>
        <sup>2</sup>
      </span>
      <!-- <span class="logo-lg"><b>O</b>wto</span> -->
      <img
        src="@/assets/images/logo.png"
        class="logo-lg img-responsive"
        style="width:80%;padding:5px;margin:0 auto;"
        alt="Logo"
      />
    </a>

    <nav class="navbar navbar-static-top">
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <!-- <img src="/dist/img/user2-160x160.jpg" class="user-image" alt="User Image"> -->
              <span class="hidden-xs">
                <i class="fa fa-gear"></i>
              </span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <!--<img src="img/user.png" class="img-circle" alt="User Image">-->
                <p>
                  {{ user.given_name }} {{ user.family_name }}
                  <small>{{ user.upn }}</small>
                </p>
              </li>
              <!-- Menu Body -->
              <!-- <li class="user-body">
            <div class="row">
              <div class="col-xs-4 text-center">
                <a href="#">Followers</a>
              </div>
              <div class="col-xs-4 text-center">
                <a href="#">Sales</a>
              </div>
              <div class="col-xs-4 text-center">
                <a href="#">Friends</a>
              </div>
            </div>
              </li>-->
              <li class="user-footer">
                <div class="pull-left">
                  <a href="#" class="btn btn-default btn-flat">Profile</a>
                </div>
                <div class="pull-right">
                  <a @click.prevent="logout" class="btn btn-default btn-flat"
                    >Sign out</a
                  >
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import adalAuth from "@/authentication/adalAuth.js";

export default {
  data() {
    return {
      user: null
    };
  },
  methods: {
    logout() {
      adalAuth.signOut();
    }
  },
  created() {
    this.user = adalAuth.getUserProfile();
  }
};
</script>

<style></style>
