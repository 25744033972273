import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getVehicleModels(token) {
    // fetch vehicle models
    return apiClient.get("/api/vehicle-models", {
      headers: {
        Authorization: token
      }
    });
  },

  getVehicleModel(token, id) {
    // fetch vehicle model
    return apiClient.get("/api/vehicle-models/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  editVehicleModel(token, id, vehicleModel) {
    // edit vehicle model
    return apiClient.patch("/api/vehicle-models/" + id, vehicleModel, {
      headers: {
        Authorization: token
      }
    });
  },

  addVehicleModel(token, vehicleModel) {
    // add vehicle model
    return apiClient.post("/api/vehicle-models", vehicleModel, {
      headers: {
        Authorization: token
      }
    });
  }
};
