import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getRoute(token, id) {
    return apiClient.get("/api/routes/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  getRoutePois(token, id) {
    return apiClient.get("/api/routes/" + id + "/pois", {
      headers: {
        Authorization: token
      }
    });
  },

  getRoutes(token) {
    return apiClient.get("/api/routes", {
      headers: {
        Authorization: token
      }
    });
  },

  addRoute(token, username, route) {
    return apiClient.post("/api/routes", route, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  },

  deleteRoute(token, id) {
    return apiClient.delete("/api/routes/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  editRoute(token, username, id, route) {
    return apiClient.patch("/api/routes/" + id, route, {
      headers: {
        Authorization: token,
        "X-Owto-Request-By": username
      }
    });
  }
};
