<template>
  <div class="tab-pane" id="gcash">
    <form id="gcash_form" @submit.prevent>
      <fieldset>
        <legend>TopUp Details</legend>
        <table class="table table-settings">
          <tbody>
            <tr v-show="false">
              <th>Specimen Signature</th>
              <td>
                <a
                  v-if="module.specimenSignature"
                  :href="module.specimenSignature"
                  target="_blank"
                  >Link</a
                >
                <input type="file" @change="upload($event)" />
              </td>
            </tr>
            <tr>
              <th>GCash Number</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="module.gcashNumber"
                  autocomplete="off"
                  @input="format($event)"
                />
              </td>
            </tr>
            <tr>
              <th>GCash Card</th>
              <td>
                <span class="gcash-id">
                  {{
                    driverGcash
                      ? driverGcash
                      : "_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _"
                  }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Available GCash Cards</th>
              <td class="input-group">
                <select
                  id="uhm"
                  class="form-control"
                  v-model="selectedValue"
                  :disabled="driverGcash"
                >
                  <option disabled value>Please select gcash</option>
                  <option
                    v-for="(g, i) in filterGcash"
                    :key="i"
                    :value="g.id"
                    :data-sub="g.referenceNumber"
                    >{{ g.cardNumber }}</option
                  >
                </select>
                <span class="input-group-btn">
                  <button
                    id="gcBtn"
                    @click="assign"
                    type="button"
                    class="btn btn-primary"
                    :disabled="driverGcash"
                  >
                    <i class="fa fa-plus"></i>
                    Assign GCash
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { AsYouType } from "libphonenumber-js";
import { mapState } from "vuex";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    permission: Boolean
  },
  data() {
    return {
      selectedValue: "",
      gcash: {
        gcash: null
      },
      isSubmitting: false
    };
  },
  computed: {
    ...mapState("gcash", ["gcashCards"]),
    filterGcash() {
      return this.gcashCards.filter(gc => {
        return !gc.isDefective && !gc.driverId;
      });
    },
    filterGcashR() {
      return this.gcashCards.filter(gc => {
        return gc.driverId;
      });
    },
    driverGcash() {
      let gcard = this.gcashCards.find(gc => {
        if (gc.driverId === this.module.userId) {
          return true;
        }
      });
      return gcard ? gcard.cardNumber : null;
    }
  },
  methods: {
    assign() {
      if (this.isSubmitting) return;
      let selected = $("#uhm")
        .val()
        .toString()
        ? $("#uhm")
            .val()
            .toString()
        : null;
      if (!selected && !this.driverGcash) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("gcash/assignGcashCard", {
          gcashId: selected,
          driverId: this.module.userId
        })
        .then(() => {
          $("#uhm")
            .select2("destroy")
            .select2({
              matcher: this.matchCustom,
              templateResult: this.formatCustom
            });
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    upload(event) {
      let file = event.target.files[0];
      let form = new FormData();
      if (!file) return;
      form.append("type", "gcashsign");
      form.append("file", file);
      axios
        .post(
          `${process.env.VUE_APP_BE_API_URL}/api/drivers/${this.id}/upload`,
          form,
          {
            headers: {
              Authorization: this.token,
              "X-Owto-Request-By": this.user.name
            }
          }
        )
        .then(response => {
          this.id = response.data.signature;
        })
        .catch(error => {
          if (error.request.response) {
            console.log(JSON.parse(error.request.response).error.message);
          } else {
            console.log(error.message);
          }
        });
    },
    format(ev) {
      const asYouType = new AsYouType("PH");
      var mRegEx = /^(\+|\d)\d{0,}$/;
      var plusBegin = /^\+\d{0,}$/;

      if (ev.data) {
        if (!mRegEx.test(this.module.gcashNumber)) {
          this.module.gcashNumber = this.module.gcashNumber.substr(
            0,
            this.module.gcashNumber.lastIndexOf(ev.data)
          );
        } else {
          if (
            (plusBegin.test(this.module.gcashNumber) &&
              this.module.gcashNumber.length >= 4) ||
            (!plusBegin.test(this.module.gcashNumber) && ev.data != 0) ||
            (!plusBegin.test(this.module.gcashNumber) &&
              ev.data == 0 &&
              this.module.gcashNumber.length >= 2)
          ) {
            if (this.module.gcashNumber.indexOf("+0") == 0) return;
            asYouType.input(this.module.gcashNumber);
            this.module.gcashNumber = asYouType.getNumber().number;
          }
        }
      }
    },
    stringMatch(term, candidate) {
      return (
        candidate && candidate.toLowerCase().indexOf(term.toLowerCase()) >= 0
      );
    },
    matchCustom(params, data) {
      // If there are no search terms, return all of the data
      if ($.trim(params.term) === "") {
        return data;
      }
      // Do not display the item if there is no 'text' property
      if (typeof data.text === "undefined") {
        return null;
      }
      // Match text of option
      if (this.stringMatch(params.term, data.text)) {
        return data;
      }
      // Match attribute "data-foo" of option
      if (this.stringMatch(params.term, $(data.element).attr("data-sub"))) {
        return data;
      }
      // Return `null` if the term should not be displayed
      return null;
    },
    formatCustom(state) {
      return $(
        "<div><div>" +
          state.text +
          '</div><div class="sub-details">' +
          $(state.element).attr("data-sub") +
          "</div></div>"
      );
    }
  },
  mounted() {
    $("#uhm").select2({
      matcher: this.matchCustom,
      templateResult: this.formatCustom
    });

    $("#gcash :input").prop("disabled", !this.permission);
    $("#gcash #uhm").prop("disabled", !this.permission || this.driverGcash);
    $("#gcash #gcBtn").prop("disabled", !this.permission || this.driverGcash);

    // function stringMatch(term, candidate) {
    //   return (
    //     candidate && candidate.toLowerCase().indexOf(term.toLowerCase()) >= 0
    //   );
    // }

    // function matchCustom(params, data) {
    //   // If there are no search terms, return all of the data
    //   if ($.trim(params.term) === "") {

    //     return data;
    //   }
    //   // Do not display the item if there is no 'text' property
    //   if (typeof data.text === "undefined") {
    //     return null;
    //   }
    //   // Match text of option
    //   if (stringMatch(params.term, data.text)) {
    //     return data;
    //   }
    //   // Match attribute "data-foo" of option
    //   if (stringMatch(params.term, $(data.element).attr("data-sub"))) {
    //     return data;
    //   }
    //   // Return `null` if the term should not be displayed
    //   return null;
    // }

    // function formatCustom(state) {
    //   return $(
    //     "<div><div>" +
    //       state.text +
    //       '</div><div class="sub-details">' +
    //       $(state.element).attr("data-sub") +
    //       "</div></div>"
    //   );
    // }
  }
};
</script>

<style>
.gcash-id {
  font-weight: 900;
  letter-spacing: 0.3px;
  font-size: medium;
}

#uhm + span {
  width: 100% !important;
}

.sub-details {
  color: #808080;
  font-size: smaller;
}
</style>
