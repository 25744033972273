<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Operators
        <router-link :to="{ name: 'operators-new' }">
          <button
            v-if="$can('create', 'Operator')"
            class="btn btn-primary btn-sm"
          >
            Register
          </button>
        </router-link>
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table id="operators_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>

              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataTable: null
    };
  },
  computed: {
    ...mapState("operator", ["operators"]),
    ...mapState("user", ["token"])
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            vm.$store.dispatch("operator/getO", { data: response.data }); // store data to operator module's state

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        // createdRow: ( row, data, dataIndex ) => {
        //   $(row).find('td:eq(0)')
        //     .attr('data-schedule-id', data.id);
        // },
        columns: [
          // {
          //   className:      'details-control text-center',
          //   orderable:      false,
          //   data:           null,
          //   defaultContent: ''
          // },
          { data: "lastName" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "Operator")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-operator-edit-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              return button;
            }
          }
        ]
      });

      let isFetching = false;
      $("#operators_table").on("click", "[data-operator-edit-id]", e => {
        if (isFetching) return;
        isFetching = true;
        let { operatorEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "operators-edit",
            params: { id: operatorEditId }
          },
          () => {
            vm.$store.dispatch("canBack");
          },
          () => {
            isFetching = false;
          }
        );
        e.preventDefault();
      });
    }
  },
  mounted() {
    // let users = [];

    // this.dataTable = $("#operators_table").DataTable({});
    // const url = "https://jsonplaceholder.typicode.com/users";
    // fetch(url)
    //   .then(res => res.json())
    //   .then(data => {
    //     data.forEach(item => {
    //       users.push(item);
    //     });

    //     users.forEach(user => {
    //       this.dataTable.row
    //         .add([user.id, '<a href="#">' + user.name + "</a>", user.email])
    //         .draw(false);
    //     });
    //   });

    this.dataTable = $("#operators_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/operators`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
