import VariantService from "@/services/VariantService.js";

export const namespaced = true;

export const state = {
  variant: {},
  variants: []
};

export const mutations = {
  SET_VARIANTS(state, variants) {
    state.variants = variants;
  },
  SET_VARIANT(state, variant) {
    state.variant = variant;
  },
  ADD_VARIANT(state, variant) {
    state.variants.push(variant);
  },
  EDIT_VARIANT(state, variant) {
    state.variant = { ...state.variant, ...variant };
  }
};

export const actions = {
  getVariants({ commit, rootState, dispatch }) {
    return VariantService.getVariants(rootState.user.token)
      .then(response => {
        commit("SET_VARIANTS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getVariant({ commit, rootState, dispatch }, id) {
    return VariantService.getVariant(rootState.user.token, id)
      .then(response => {
        commit("SET_VARIANT", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addVariant({ commit, rootState, dispatch }, variant) {
    return VariantService.addVariant(rootState.user.token, variant)
      .then(response => {
        commit("ADD_VARIANT", response.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editVariant({ commit, rootState, dispatch }, variant) {
    return VariantService.editVariant(rootState.user.token, variant.id, variant)
      .then(response => {
        commit("EDIT_VARIANT", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
