import OperatorService from "@/services/OperatorService.js";

export const namespaced = true;

export const state = {
  operator: {},
  operators: [],
  operatorVehicles: [],
  operatorDrivers: [],
  operatorFleets: []
};

export const mutations = {
  SET_OPERATORS(state, operators) {
    state.operators = operators;
  },
  SET_OPERATOR(state, operator) {
    state.operator = operator;
  },
  SET_OPERATOR_DRIVERS(state, drivers) {
    state.operatorDrivers = drivers;
  },
  ASSIGN_OPERATOR_DRIVER(state, driver) {
    state.operatorDrivers.push(driver);
  },
  REMOVE_OPERATOR_DRIVER(state, driverId) {
    state.operatorDrivers = state.operatorDrivers.filter(oD => {
      return oD.userId != driverId;
    });
  },
  ADD_OPERATOR(state, operator) {
    state.operators.push(operator);
  },
  EDIT_OPERATOR(state, operator) {
    state.operator = { ...state.operator, ...operator };
  },
  ADD_OPERATOR_VEHICLE(state, operatorVehicle) {
    state.operatorVehicles.push(operatorVehicle);
  },
  SET_OPERATOR_FLEETS(state, operatorFleets) {
    state.operatorFleets = operatorFleets;
  },
  REMOVE_OPERATOR_FLEET(state, fleetId) {
    state.operatorFleets = state.operatorFleets.filter(oF => {
      return oF.id !== fleetId;
    });
  },
  ASSIGN_OPERATOR_FLEET(state, fleet) {
    state.operatorFleets.push(fleet);
  }
};

export const actions = {
  getOperators({ commit, rootState }) {
    //make an API call - fetch and commit datas
    OperatorService.getOperators(rootState.user.token).then(data => {
      commit("SET_OPERATORS", data.data);
    });
  },

  getO({ commit }, data) {
    //API call done by the datatable - commit datas
    commit("SET_OPERATORS", data.data);
  },

  getOperator({ commit, rootState, dispatch }, id) {
    return OperatorService.getOperator(rootState.user.token, id)
      .then(response => {
        commit("SET_OPERATOR", response.data);
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getOperatorDrivers({ commit, rootState }, id) {
    return OperatorService.getOperatorDrivers(rootState.user.token, id).then(
      response => {
        commit("SET_OPERATOR_DRIVERS", response.data);
      }
    );
  },

  assignOperatorDriver(
    { commit, rootState, dispatch },
    { operatorId, driverId }
  ) {
    return OperatorService.assignOperatorDriver(
      rootState.user.token,
      operatorId,
      driverId
    )
      .then(() => {
        let getDriver = rootState.driver.drivers.find(d => {
          return d.userId == driverId;
        });
        commit("ASSIGN_OPERATOR_DRIVER", getDriver);
        const notification = {
          type: "success",
          message: "Assign Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Assign Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  removeOperatorDriver(
    { commit, rootState, dispatch },
    { operatorId, driverId }
  ) {
    return OperatorService.removeOperatorDriver(
      rootState.user.token,
      operatorId,
      driverId
    )
      .then(() => {
        commit("REMOVE_OPERATOR_DRIVER", driverId);
        const notification = {
          type: "success",
          message: "Removed Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addOperator({ commit, rootState, dispatch }, operator) {
    return OperatorService.addOperator(
      rootState.user.token,
      rootState.user.user.name,
      operator
    )
      .then(data => {
        commit("ADD_OPERATOR", data.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
        return data.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editOperator({ commit, rootState, dispatch }, operator) {
    return OperatorService.editOperator(
      rootState.user.token,
      rootState.user.user.name,
      operator.id,
      operator
    )
      .then(data => {
        commit("EDIT_OPERATOR", data.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addOperatorVehicle(
    { commit, rootState, dispatch },
    { operatorId, vehicleId }
  ) {
    return OperatorService.addOperatorVehicle(
      rootState.user.token,
      rootState.user.user.name,
      operatorId,
      vehicleId
    )
      .then(data => {
        commit("ADD_OPERATOR_VEHICLE", data.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getOperatorFleets({ commit, rootState, dispatch }, id) {
    return OperatorService.getOperatorFleets(rootState.user.token, id)
      .then(response => {
        commit("SET_OPERATOR_FLEETS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetch Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  removeOperatorFleet(
    { commit, rootState, dispatch },
    { operatorId, fleetId }
  ) {
    return OperatorService.removeOperatorFleet(
      rootState.user.token,
      operatorId,
      fleetId
    )
      .then(response => {
        commit("REMOVE_OPERATOR_FLEET", fleetId);
        const notification = {
          type: "success",
          message: "Removed Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Remove Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignOperatorFleet(
    { commit, rootState, dispatch },
    { operatorId, fleetId }
  ) {
    return OperatorService.assignOperatorFleet(
      rootState.user.token,
      operatorId,
      fleetId
    )
      .then(response => {
        let getFleet = rootState.fleet.fleets.find(f => {
          return f.id === response.data.fleetId;
        });
        commit("ASSIGN_OPERATOR_FLEET", getFleet);
        const notification = {
          type: "success",
          message: "Assigned Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Assign Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
