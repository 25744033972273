<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>Overview</h1>
    </section>

    <section class="content">
      <div class="container-fluid"></div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
