<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
      <li
        v-for="(oTab, index) in operatorTabs"
        :key="index"
        @click="selectedOperatorTab = oTab[0]"
        :class="{ active: selectedOperatorTab === oTab[0] }"
        data-toggle="tab"
      >
        <a v-if="$can('read', oTab[1])">{{ oTab[0] }}</a>
      </li>
    </ul>

    <div class="tab-content">
      <ProfileTab
        :class="{ active: selectedOperatorTab == 'Profile' }"
        view="Operator"
        :id="id"
      />

      <Vehicle
        :class="{ active: selectedOperatorTab == 'Vehicles' }"
        :id="id"
      />

      <Driver :class="{ active: selectedOperatorTab == 'Drivers' }" :id="id" />

      <Prangkisa
        :class="{ active: selectedOperatorTab == 'PA/CPC' }"
        :id="id"
      />
    </div>
  </div>
</template>

<script>
import Prangkisa from "./tabs/Prangkisa.vue";
import Driver from "./tabs/Driver.vue";
import Vehicle from "./tabs/Vehicle.vue";
import ProfileTab from "./tabs/ProfileTab.vue";

export default {
  components: {
    Prangkisa,
    Driver,
    Vehicle,
    ProfileTab
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      operatorTabs: [
        ["Profile", "Operator"],
        ["Vehicles", "OperatorVehicle"],
        ["Drivers", "OperatorDriver"],
        ["PA/CPC", "OperatorPrangkisa"]
      ],
      selectedOperatorTab: "Profile"
    };
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
