<template>
  <div class="tab-pane" id="drivers">
    <fieldset id="fdriver">
      <legend>
        Drivers
        <button
          v-if="$can('update', 'OperatorDriver')"
          type="button"
          class="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#enrollDriverModal"
        >
          <i class="fa fa-plus"></i>
        </button>
      </legend>
      <table
        id="operator_driver_table"
        :data-operator-id="id"
        class="table table-hover"
        width="100%"
      >
        <thead>
          <tr>
            <th>Driver</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </fieldset>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: false,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}` // propertyName ASC|DESC
              // where: {
              //   q: d.search.value,
              // }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        // createdRow: ( row, data, dataIndex ) => {
        //   $(row).find('td:eq(0)')
        //     .attr('data-schedule-id', data.id);
        // },
        columns: [
          // {
          //   className:      'details-control text-center',
          //   orderable:      false,
          //   data:           null,
          //   defaultContent: ''
          // },
          { data: "lastName" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              let button = `<a class="datalink"
                           data-driver-id="${row.userId}">
                          ${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}
                        </a>`;
              if (vm.$can("read", "Partner")) {
                return button;
              }
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "Partner")
                ? "visible"
                : "hidden";
              let dVisibility = vm.$can("delete", "OperatorDriver")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-driver-id="${row.userId}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              button += `<button class="btn btn-primary ${dVisibility}"
                                  type="button"
                                  data-operator-id="${this.id}"
                                  data-delete-driver-id="${row.userId}">
                            <i class="fa fa-trash-o"
                                aria-hidden="true"
                                style="pointer-events: none">
                            </i>
                          </button>`;
              return button;
            }
          }
        ]
      });

      $("#operator_driver_table").on("click", "[data-driver-id]", e => {
        if (vm.isSubmitting) return;
        let { driverId } = e.target.dataset;
        vm.isSubmitting = true;
        vm.$router.push(
          {
            name: "partners-edit",
            params: {
              id: driverId
            }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });

      $("#operator_driver_table").on("click", "[data-delete-driver-id]", e => {
        if (vm.isSubmitting) return;
        let { deleteDriverId, operatorId } = e.target.dataset;
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        vm.$store
          .dispatch("operator/removeOperatorDriver", {
            operatorId: operatorId,
            driverId: deleteDriverId
          })
          .then(() => {
            $("#partners_enroll_table")
              .DataTable()
              .ajax.reload(() => {
                vm.dataTable.DataTable().ajax.reload(() => {
                  vm.isSubmitting = false;
                });
              });
          })
          .catch(() => {
            vm.isSubmitting = false;
          });
      });
    }
  },
  mounted() {
    this.dataTable = $("#operator_driver_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/operators/${
      this.id
    }/drivers`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["user", "token"])
  }
};
</script>

<style></style>
