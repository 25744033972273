<template>
  <div class="content-wrapper">
    <section class="content-header">
      <a @click.prevent="routeBack">
        <i class="fa fa-caret-left"></i>
        Back
      </a>
      <h1>Edit PA/CPC</h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <form @submit.prevent="editPrangkisa" method="POST">
          <table class="table table-settings">
            <tbody>
              <tr>
                <th>Case Number</th>
                <td>
                  <input
                    :disabled="isNotEditable"
                    type="text"
                    class="form-control"
                    name="caseNumber"
                    required="required"
                    v-model.trim="prangkisa.caseNumber"
                  />
                </td>
              </tr>
              <tr>
                <th>Certification Type</th>
                <td>
                  <select
                    name="certType"
                    class="form-control"
                    v-model="prangkisa.certType"
                    :disabled="true"
                  >
                    <option
                      v-for="(certType, index) in certTypes"
                      :key="index"
                      :value="certType"
                      >{{ certType }}</option
                    >
                  </select>
                </td>
              </tr>
              <template v-if="prangkisa.certType === 'PA'">
                <tr>
                  <th>PA File</th>
                  <td>
                    <FileUpload
                      v-if="!prangkisa.paFile"
                      :link="prangkisa.paFile"
                      type="paFile"
                      @save="save"
                    />
                    <a
                      v-else
                      :href="prangkisa.paFile"
                      target="_blank"
                      >Document Link</a
                    >
                  </td>
                </tr>
                <tr>
                  <th>PA Issued Date</th>
                  <td>
                    <vc-date-picker
                      v-model="paIssuedDateSub"
                      mode="dateTime"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          :disabled="isPaIssuedDateNotEditable"
                          class="form-control"
                          :value="inputValue"
                          v-on="isPaIssuedDateNotEditable ? null : inputEvents"
                        />
                      </template>
                    </vc-date-picker>
                  </td>
                </tr>
                <tr>
                  <th>PA Validity</th>
                  <td>
                    <input
                      :disabled="isPaIssuedDateNotEditable"
                      type="number"
                      class="form-control"
                      name="paValidity"
                      placeholder="90 days default"
                      v-model.trim="prangkisa.paValidity"
                    />
                  </td>
                </tr>
                <tr v-if="isNotEditable">
                  <th>Validity Status</th>
                  <td>
                    <span
                      class="status-badge"
                      :class="paValidityStatus(prangkisa.paIssuedDate, prangkisa.paValidity) ? 'status-badge--valid' : ''"
                    >
                      {{ paValidityStatus(prangkisa.paIssuedDate, prangkisa.paValidity) ? "Valid" : "Expired" }}
                    </span>
                  </td>
                </tr>
              </template>
              <!-- <tr>
                <th>Remarks</th>
                <td>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    v-model.trim="vehicle.remarks"
                  ></textarea>
                </td>
              </tr> -->
              <tr v-if="!isNotEditable">
                <th></th>
                <td>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>

      <div v-if="prangkisa.certType === 'CPC'" class="container-fluid">
        <h4>CPC Renewal History
          <button @click="show" type="submit" class="btn btn-primary">Add CPC</button>
        </h4>

        <table class="table table-settings">
          <tr>
            <th>CPC File</th>
            <th>Issued Date</th>
            <th>Status</th>
          </tr>

          <tr v-for="cpc in prangkisaCpcs" :key="cpc.id">
            <td>
              <a
                :href="cpc.file"
                target="_blank"
                >
                  Document Link
              </a>
            </td>
            <td>{{ new Date(cpc.issuedDate).toDateString() }}</td>
            <td>
              <span
                class="status-badge"
                :class="cpcValidityStatus(cpc.issuedDate) ? 'status-badge--valid' : ''"
              >
                {{ cpcValidityStatus(cpc.issuedDate) ? "Valid" : "Expired" }}
              </span>
            </td>


            <td v-if="!prangkisaCpcs.length" colspan="3">
              <p style="font-style: italic">No CPC Renewal History yet.</p>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <modal name="cpc-modal" :width="400" height="auto" @before-close="resetCpc">
      <div class="modal-content">
        <div class="modal-content__header">
          <h4>CPC Renewal</h4>
        </div>

        <div class="modal-content__body">
          <form @submit.prevent="addCpc" method="POST" class="cpc-form">
            <div class="cpc__form-control">
              <label for="cpc">CPC File</label>
              <FileUpload
                :link="cpc.file"
                name="cpc"
                required="required"
                type="cpcFile"
                @save="holdFile"
              />
            </div>

            <div class="cpc__form-control">
              <label for="cpciss">Issued Date</label>
              <vc-date-picker
                v-model="cpc.issuedDate"
                name="cpciss"
                mode="dateTime"
                required="required"
              />
            </div>

            <div class="cpc__form-control">
              <button type="submit" class="btn btn-primary">Renew CPC</button>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import store from "@/store/store";
import { mapState } from "vuex";
import moment from "moment";

import FileUpload from "@/components/FileUpload";
import cloudinaryUpload from "../../utils/upload";


export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    prid: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    FileUpload
  },
  data() {
    return {
      vehicle: {
        // fake
        remarks: ""
      },
      certTypes: ["PA", "CPC"],
      isRouting: false,
      isSubmitting: false,
      paIssuedDateSub: null,
      // cpcIssuedDateSub: null
      cpc: {
        file: "",
        issuedDate: null
      },
      holdFileData: null
    };
  },
  methods: {
    save({ value, type }, action) {
      if (action == "upload") {
        cloudinaryUpload(value, {
          folder: `prangkisa-files/${this.prangkisa.id}`,
          publicId: type
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.tempFileData = { ...this.tempFileData, [type]: this.prangkisa[type] };
            this.prangkisa[type] = data.secureUrl;
            this.editPrangkisa(type);
          });
      } else {
        this.tempFileData = { ...this.tempFileData, [type]: this.prangkisa[type] };
        this.prangkisa[type] = value;
        this.editPrangkisa(type);
      }
    },
    editPrangkisa(type) {
      if (this.isSubmitting) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isSubmitting = true;
      this.$store
        .dispatch("prangkisa/editPrangkisa",
          {
            ...this.prangkisa,
            paIssuedDate: this.paIssuedDateSub
          }
        )
        .then(() => {
         if (type) {
          this.tempFileData = [];
          this.isSubmitting = false;
         } else {
           this.$router.push(
             {
               name: "operators-edit",
               params: { id: this.id }
             },
             null,
             () => {
               this.isSubmitting = false;
             }
           );
         }
          console.log("success edit prangkisa...");
        })
        .catch(() => {
          this.prangkisa[type] = this.tempFileData[type];
          this.isSubmitting = false;
        });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;
      this.$router.push(
        { name: "operators-edit", params: { id: this.id } },
        null,
        () => {
          this.isRouting = false;
        }
      );
    },
    paValidityStatus(paIssuedDate, d) {
      if (!d) d = 90
      const mPaIssuedDate = moment(paIssuedDate).add(d, "days");
      return !moment().isAfter(mPaIssuedDate);
    },
    cpcValidityStatus(cpcIssuedDate) {
      const mCpcIssuedDate = moment(cpcIssuedDate).add(2, "years");
      return !moment().isAfter(mCpcIssuedDate);
    },
    show () {
      this.$modal.show('cpc-modal');
    },
    hide () {
      this.$modal.hide('cpc-modal');
      this.resetCpc();
    },
    resetCpc() {
      this.cpc = {
        file: "",
        issuedDate: null
      }
      this.holdFileData = null
    },
    holdFile({ value, type }, action) {
      this.holdFileData = {
        value,
        type,
        action
      }

      if (action == "upload") {
        const reader = new FileReader();

        reader.onload = e => {
          this.cpc.file = e.target.result;
        }

        reader.readAsDataURL(value);
      } else {
        this.cpc.file = value;
      }
    },
    addCpc() {
      if (this.isSubmitting) return;

      const { file, issuedDate } = this.cpc;

      if (!file || !issuedDate) {
        alert("CPC File and Issued Date are Required");
        return;
      }

      if (confirm("Are you sure?") == false) return;
      this.isSubmitting = true;

      if (this.holdFileData.action == "upload") {
        cloudinaryUpload(this.holdFileData.value, {
          folder: `prangkisa-files/${this.prangkisa.id}`,
          publicId: this.holdFileData.type
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.cpc.file = data.secureUrl;
            this.processCpc();
          });
      } else {
        this.processCpc()
      }
    },
    processCpc() {
      this.$store.dispatch("prangkisa/addCpc",
      {
        prangkisaId: this.prid,
        cpc: this.cpc
      })
        .finally(() => {
          this.isSubmitting = false;
          this.hide();
        })
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    console.log("edit prangkisa loading...");
    store.dispatch("prangkisa/getPrangkisaCpcs", routeTo.params.prid);
    store
      .dispatch("prangkisa/getPrangkisa", routeTo.params.prid)
      .then(() => {
        next();
      })
      .catch(error => {
        if (error.response.status === 404) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  created() {
    // this.prangkisa.startDate = this.formatDate(this.prangkisa.startDate);
    this.paIssuedDateSub = this.prangkisa.paIssuedDate;
    // this.cpcIssuedDateSub = this.prangkisa.cpcIssuedDate;
  },
  computed: {
    ...mapState("prangkisa", ["prangkisa", "prangkisaCpcs"]),
    ...mapState("user", ["user", "token"]),
    isNotEditable() {
      const {
        certType,
        paFile,
        paIssuedDate,
      } = this.prangkisa;

      if (certType == "PA" && paFile && paIssuedDate) return true;
      if (certType == "CPC") return true;
      return false;
    },
    isPaIssuedDateNotEditable() {
      return (this.isNotEditable || !this.prangkisa.paFile);
    }
  }
};
</script>

<style scoped>
.status-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.status-badge--valid {
  background-color: #22aa22;
}

.modal-content {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content__header {
  margin-bottom: 16px;
}

.modal-content__header h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.modal-content__body {

}

.cpc-form {

}

.cpc__form-control {
  padding: 8px 12px;
}

.cpc__form-control label {
  display: block;
  margin-bottom: 8px;
}
</style>
