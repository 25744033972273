import ScheduleService from "@/services/ScheduleService.js";

export const namespaced = true;

export const state = {
  schedule: null,
  schedules: []
};

export const mutations = {
  SET_SCHEDULE(state, schedule) {
    state.schedule = schedule;
  },
  ADD_SCHEDULE(state, schedule) {
    state.schedules.push(schedule);
  },
  CANCEL_SCHEDULE(state, id) {
    if (!state.schedules.length) return;
    state.schedules.filter(s => s.id !== id);
  },
  DELETE_SCHEDULE(state, id) {
    if (!state.schedules.length) return;
    state.schedules.filter(s => s.id !== id);
  },
  EDIT_SCHEDULE(state, schedule) {
    state.schedule = { ...state.schedule, ...schedule };
  }
};

export const actions = {
  getSchedule({ commit, rootState, dispatch }, id) {
    return ScheduleService.getSchedule(rootState.user.token, id)
      .then(response => {
        commit("SET_SCHEDULE", response.data);
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  assignDriverSchedule({ commit, rootState, dispatch }, { sid, did }) {
    return ScheduleService.assignDriverSchedule(
      rootState.user.token,
      rootState.user.user.name,
      sid,
      did
    )
      .then(response => {
        commit("SET_SCHEDULE", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addSchedule({ commit, rootState, dispatch }, schedule) {
    return ScheduleService.addSchedule(
      rootState.user.token,
      rootState.user.user.name,
      schedule
    )
      .then(response => {
        commit("ADD_SCHEDULE", response.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  cancelSchedule({ commit, rootState, dispatch }, id) {
    return ScheduleService.cancelSchedule(
      rootState.user.token,
      rootState.user.user.name,
      id
    )
      .then(response => {
        commit("CANCEL_SCHEDULE", response.data);
        const notification = {
          type: "success",
          message: "Cancel Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Cancelation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  deleteSchedule({ commit, rootState, dispatch }, id) {
    return ScheduleService.deleteSchedule(
      rootState.user.token,
      rootState.user.user.name,
      id
    )
      .then(response => {
        commit("DELETE_SCHEDULE", response.data);
        const notification = {
          type: "success",
          message: "Delete Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Deletion Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editSchedule({ commit, rootState, dispatch }, schedule) {
    return ScheduleService.editSchedule(
      rootState.user.token,
      rootState.user.user.name,
      schedule.id,
      schedule
    )
      .then(response => {
        commit("EDIT_SCHEDULE", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
