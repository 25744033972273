<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Variants
        <router-link
          :to="{ name: 'variants-new' }"
          class="btn btn-primary btn-sm"
          >Add</router-link
        >
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table id="variants_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dataTable: null,
      isFetching: false
    };
  },
  computed: {
    ...mapState("user", ["token"])
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["variantName", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],

        columns: [{ data: "variantName" }, { data: null }],
        columnDefs: [
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("read", "Variant")
                ? "visible"
                : "hidden";

              button = `<a class="btn btn-primary ${visibility}"
                           data-variant-edit-id="${row.id}">
                          <i class="fa fa-pencil-square-o"
                             aria-hidden="true"
                             style="pointer-events: none">
                          </i>
                        </a>`;

              return button;
            }
          }
        ]
      });

      $("#variants_table").on("click", "[data-variant-edit-id]", e => {
        if (vm.isFetching) return;
        vm.isFetching = true;
        let { variantEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "variants-edit",
            params: { id: variantEditId }
          },
          null,
          () => {
            vm.isFetching = false;
          }
        );
        e.preventDefault();
      });
    }
  },
  mounted() {
    this.dataTable = $("#variants_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/variants`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
