<template>
  <div class="tab-pane" id="trips">
    <table
      class="table"
      id="partner_trip_history_table"
      :data-driver-id="id"
      width="100%"
    >
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Date</th>
          <th>Status</th>
        </tr>
      </thead>
    </table>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import masterCard from "@/assets/images/master-card.png";
import visa from "@/assets/images/visa.png";
// import VueEasyLightBox from "vue-easy-lightbox";
import dummyimg from "@/assets/images/dummy.jpg";

export default {
  // components: {
  //   VueEasyLightBox
  // },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataTable: null,
      imgs: "",
      visible: false,
      isSubmitting: false
    };
  },
  methods: {
    showSingle(src) {
      this.imgs = src;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    getImgUrl(pic) {
      // var images = require.context("../assets/images/", false, /\.png$/);
      // return images("./" + pic);
      pic = "/images/" + pic;
      return require("./assets" + pic);
    },
    setupDtTable(dataTable, url) {
      //let date = this.view === "Partner" ? "tripStartDate" : "bookingDate";
      let dtColumns = ["", "", `bookingDate`, ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                `${" " + d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[2, "desc"]],
        columns: [
          {
            className: "details-control text-center",
            orderable: false,
            data: null,
            defaultContent: ""
          },
          { data: "" },
          { data: "bookingDate " },
          { data: "cancelledAt" }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: false,
            width: "20",
            render: (type, data, row, meta) => {
              return `<i class="fa fa-plus" style="pointer-events:none"></i>`;
            }
          },
          {
            class: "myrow",
            targets: [1],
            orderable: false,
            width: "364",
            render: (type, data, row, meta) => {
              return `<dl class="dl-flex">
              <dt><i class="fa fa-map-marker" aria-hidden="true"></i></dt>
              <dd>${row.pickUpAddress || "N/A"}</dd>
              <dt><i class="fa fa-map-marker" aria-hidden="true"></i></dt>
              <dd>${row.dropOffAddress || "N/A"}</dd>
            </dl>`;
            }
          },
          {
            targets: [2],
            width: "222",
            orderable: true,
            render: (type, data, row, meta) => {
              return moment(row.bookingDate).format("LLLL");
            }
          },
          {
            targets: [3],
            orderable: false,
            render: (type, data, row, meta) => {
              let {
                acceptedAt: a,
                cancelledAt: c,
                tripStartDate: tS,
                tripEndDate: tE
              } = row;
              if (a && !c && !tS && !tE) {
                return "ACCEPTED";
              } else if (a && c && !tS && !tE) {
                return "CANCELED";
              } else if (a && !c && tS && !tE) {
                return "ON-GOING";
              } else if (a && !c && tS && tE) {
                return "COMPLETED";
              }
              return "INVALID";
            }
          }
        ]
      });

      // select element that triggers an event
      $(dataTable)
        .find("tbody")
        .on("click", "td.details-control", e => {
          // select element's parent/ancestor
          // e.target instead of this, because this refers to the vue
          // component instead of html element i don't know why :(
          // maybe beacuse of ES6
          let tr = $(e.target).closest("tr");
          let row = vm.dataTable.DataTable().row(tr);
          let child = $(e.target).children();

          // eslint-disable-next-line prettier/prettier

          if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass("shown");
            child.attr("class", "fa fa-plus");
          } else {
            row.child(format(row.data())).show();
            tr.addClass("shown");
            child.attr("class", "fa fa-minus");
          }
        });

      $("#partner_trip_history_table").on("click", "[class='rider-img']", e => {
        vm.showSingle(e.target.src);
      });

      function format(d) {
        let image = vm.view === "Rider" ? d.driverImage : d.riderImage;
        let name = vm.view === "Rider" ? d.driverName : d.riderName;
        let hasCard = d.hasOwnProperty("card")
          ? Object.keys(d.card).length
            ? true
            : false
          : false;
        let card = !hasCard ? "cardless" : "credit-card";
        let cardSrc;
        if (hasCard) {
          cardSrc = d.card.cardType === "master-card" ? masterCard : visa;
        }
        let isCancelled = d.hasOwnProperty("cancelledByWhom");
        let hideClass = !isCancelled ? "hidden" : "";
        let isDriver = vm.view != "Rider" ? "hidden" : "";
        let cancelledBy = isCancelled ? d.cancelledByWhom : "";
        return `
          <div class="row">
            <div class="col-md-6">
              <div class="rider-info-container">
                <div class="img-rapper">
                  <img class="rider-img" src="${image ? image : dummyimg}" />
                </div>
                <p class="rider-name">${name}</p>
              </div>

              <div class="trip-details-container">
                <p class="trip-header">Details</p>

                <p>
                  Mode of Payment
                  <span class="tdetails upper-case">
                    ${d.modeOfPayment}
                    <img class="${card}" src="${cardSrc}" alt="card" />
                  </span>
                </p>

                <p>
                  Plate Number
                  <span class="tdetails">${d.plateNumber}</span>
                </p>

                <p>
                  Booking Code
                  <span class="tdetails">${d.bookingId}</span>
                </p>

                 <p>
                  Booking Date
                  <span class="tdetails">${moment(d.bookingDate).format(
                    "LLLL"
                  )}</span>
                </p>

                <p>
                  Pick-up Location
                  <span class="tdetails">${d.pickUpAddress}</span>
                </p>

                <p>
                  Drop-off Location
                  <span class="tdetails">${d.dropOffAddress}</span>
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="box box-primary breakdown-box">
                <p class="header">BREAKDOWN</p>
                <div class="brow">
                  <div class="bhead">
                    <p class="title">Description</p>
                    <p class="details">Amount</p>
                  </div>

                   <div class="bbody">
                    <p class="title">Gross Fare</p>
                    <p class="details">${d.grossFare.toFixed(2)}</p>
                  </div>

                  <div class="bbody">
                    <p class="title">Adjustments</p>
                    <p class="details">${d.sumOfAdjustments.toFixed(2)}</p>
                  </div>

                  <div class="bfoot">
                    <p class="title">TOTAL</p>
                    <p class="details">${d.adjustedFare.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              
              <div class="cancelled-box">
                <div class="${isDriver}">
                  <button id="sende"
                          class="btn btn-sm btn-primary"
                          type="button"
                          data-id="${d.bookingId}">Send Email</button>
                </div>
                <div class="${hideClass}">
                  Cancelled By
                  <span class="tdetails">${cancelledBy}</span>
                </div>
              </div>
            </div>
          </div>
        `;
      }

      $("#partner_trip_history_table").on("click", "#sende", e => {
        if (vm.isSubmitting) return;
        vm.isSubmitting = true;
        let { id } = e.target.dataset;
        vm.$store
          .dispatch("rider/sendEmail", id)
          .then(() => {
            vm.isSubmitting = false;
          })
          .catch(() => {
            vm.isSubmitting = false;
          });
      });
    }
  },
  mounted() {
    let entity = this.view === "Rider" ? "riders" : "drivers";
    this.dataTable = $("#partner_trip_history_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/${entity}/${
      this.id
    }/trip-history`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["token"])
  }
};
</script>

<style>
.shown + tr {
  border-radius: 4px;
  background: #f5f5f5;
  position: relative;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4) inset,
    0 0 8px 0 rgba(0, 0, 0, 0.4) inset;
}

.rider-info-container {
  width: 90%;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
}

.img-rapper {
  display: inline-block;
  width: 137px;
  height: 137px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.rider-img {
  position: absolute;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  border-radius: 9px;
  display: block;
  margin: 0 auto 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rider-name {
  font-variant: small-caps;
  font-weight: bold;
  font-size: 1.23em;
  text-align: center;
}

.trip-details-container {
  padding: 10px 30px;
}

.trip-details-container p.trip-header {
  font-size: 1.25em;
  font-weight: bolder;
}

.trip-details-container p:not(.trip-header) {
  font-size: 1em;
  font-weight: normal;
  padding-left: 10px;
}

p span.tdetails {
  display: block;
  font-weight: bolder;
  font-size: 1.1em;
}

img.credit-card {
  display: inline-block;
  width: 75px;
  height: 35px;
  /*box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px;*/
  margin-left: 10px;
}

.cardless {
  display: none;
}

.upper-case {
  text-transform: uppercase;
}

.breakdown-box {
  background: #fff;
  width: 90% !important;
  padding: 10px 40px;
  margin: 0px auto 20px;
  border-radius: 5px;
  box-shadow: 0 2px 3px -5px rgba(0, 0, 0, 0.7), 0 3px 8px 0 rgba(0, 0, 0, 0.4) !important;
}

.breakdown-box .header {
  text-align: center;
  font-size: 1.2em;
  font-weight: bolder;
  margin-bottom: 10px;
}

.breakdown-box .brow .bhead::before,
.breakdown-box .brow .bhead::after,
.breakdown-box .brow .bbody::before,
.breakdown-box .brow .bbody::after,
.breakdown-box .brow .bfoot::before,
.breakdown-box .brow .bfoot::after {
  content: "";
  display: table;
  clear: both;
}

.breakdown-box .brow .bhead {
  opacity: 0.7;
  border-style: dashed none;
  border-color: #000;
  border-top-width: 1px;
  border-bottom-width: 2px;
  margin-bottom: 10px;
}

.breakdown-box .brow .bhead p {
  margin: 5px 0;
}

.breakdown-box .brow .title {
  float: left;
}

.breakdown-box .brow .details {
  float: right;
}

.breakdown-box .brow .bbody:nth-last-of-type(2) {
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.7);
}

.breakdown-box .brow .bfoot {
  font-weight: bolder;
}

.myrow {
  width: 364px;
}

img {
  cursor: pointer !important;
}

.cancelled-box {
  width: 90%;
  margin: 30px auto 0;
}

.cancelled-box::before,
.cancelled-box::after {
  content: "";
  display: table;
  clear: both;
}

.cancelled-box div {
  float: left;
  width: 50%;
  padding: 5px;
}

.cancelled-box div span {
  font-size: 1.1em;
  font-weight: bolder;
}
/*
#partner_trip_history_table tr td:nth-child(2) {
  max-width: 364px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>
