import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  addNote(token, username, note) {
    // add note
    return new Promise((resolve, reject) => {
      if (!username) {
        return reject({});
      }

      return resolve(
        apiClient.post(`/api/notes`, note, {
          headers: {
            Authorization: token,
            "X-Owto-Request-By": username
          }
        })
      );
    });
  }
};
