<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">About</h3>
    </div>
    <!-- /.box-header -->
    <div class="box-body">
      <strong>Name</strong>
      <p class="text-muted">
        {{ subModule.firstName }} {{ subModule.middleName }}
        {{ subModule.lastName }} {{ subModule.suffix }}
      </p>

      <b>Fleets</b>
      <div class="fleet-group">
        <select v-model="selectedFleet">
          <option disabled value>Select Fleet</option>
          <option
            v-for="(fleet, i) in filteredFleets"
            :key="i"
            :value="fleet.id"
            >{{ fleet.name }}</option
          >
        </select>
        <i class="fa fa-plus" @click="assignFleet"></i>
      </div>

      <ul class="list-group list-group-unbordered fleet-list">
        <li
          v-for="(oF, index) in operatorFleets"
          :key="index"
          class="list-group-item"
        >
          <span class="label label-primary exp">
            {{ oF.name }}
            <span class="fleet-close" @click="removeFleet(oF.id)">&times;</span>
          </span>
        </li>
      </ul>
    </div>
    <!-- /.box-body -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "@/event-bus.js";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      subModule: { ...this.operator },
      selectedFleet: "",
      isAssigning: false,
      isRemoving: false
    };
  },
  created() {
    this.subModule = { ...this.operator };
  },
  computed: {
    ...mapState("operator", ["operator", "operatorFleets"]),
    ...mapState("fleet", ["fleets"]),
    filteredFleets() {
      let isExist = null;
      return this.fleets.filter(f => {
        isExist = this.operatorFleets.find(oF => {
          return oF.id === f.id;
        });
        return !isExist;
      });
    }
  },
  methods: {
    assignFleet() {
      if (this.isAssigning) return;
      if (!this.selectedFleet) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isAssigning = true;
      this.$store
        .dispatch("operator/assignOperatorFleet", {
          operatorId: this.id,
          fleetId: this.selectedFleet
        })
        .then(() => {
          this.selectedFleet = "";
          this.isAssigning = false;
        })
        .catch(() => {
          this.isAssigning = false;
        });
    },
    removeFleet(id) {
      if (this.isRemoving) return;
      const answer = confirm("Are you sure?");
      if (!answer) return;
      this.isRemoving = true;
      this.$store
        .dispatch("operator/removeOperatorFleet", {
          operatorId: this.id,
          fleetId: id
        })
        .then(() => {
          this.isRemoving = false;
        })
        .catch(() => {
          this.isRemoving = false;
        });
    }
  },
  mounted() {
    EventBus.$on("update-operator", operator => {
      this.subModule = { ...this.subModule, ...operator };
    });
  }
};
</script>

<style>
.exp {
  width: 100%;
  text-align: initial;
  display: block;
  font-size: 12.5px !important;
}

.fleet-close {
  font-size: 1em;
  float: right;
  cursor: pointer;
}

.fleet-close:hover {
  color: #ddd;
}

.fleet-group::before,
.fleet-group::after {
  content: "";
  display: table;
  clear: both;
}

.fleet-group select {
  float: left;
  width: 90%;
}

.fleet-group i {
  float: left;
  width: 10%;
  color: #666;
  cursor: pointer;
  padding: 5px;
}

.fleet-group i:hover {
  color: #999;
}

.fleet-list {
  margin: 0;
}

.fleet-list li {
  padding: 3px;
  border: none;
}
</style>
