<template>
  <div class="tab-pane" id="requirements">
    <template v-if="view != 'Rider'">
      <fieldset v-if="view == 'Operator'">
        <legend>Requirements</legend>

        <table class="table table-settings">
          <tbody>
            <tr>
              <th>ID Presented</th>
              <td>
                <select
                  name="idPresented"
                  class="form-control"
                  v-model="module.idPresented"
                >
                  <option v-for="id in ids" :key="id.id" :value="id.id">{{
                    id.name
                  }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>ID Number</th>
              <td>
                <input
                  type="number"
                  class="form-control"
                  name="idNumber"
                  v-model.number="module.idNumber"
                />
              </td>
            </tr>
            <tr>
              <th>ID Validity</th>
              <td>
                <input
                  type="date"
                  class="form-control"
                  name="idValidity"
                  max="9999-12-31"
                  v-model="module.idValidity"
                />
              </td>
            </tr>
            <tr>
              <th>Onboarding Date</th>
              <td>
                <input
                  type="date"
                  class="form-control"
                  name="onboardingDate"
                  max="9999-12-31"
                  v-model="module.onboardingDate"
                />
              </td>
            </tr>
            <tr>
              <th>ID</th>
              <td>
                <FileUpload
                  :link="module.idFile"
                  type="idFile"
                  @save="save"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <fieldset v-else>
        <legend>Requirements</legend>
        <table class="table table-settings">
          <tbody>
            <tr>
              <th>License ID No.</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  name="licenseIdNo"
                  v-model="module.licenseIdNo"
                />
              </td>
            </tr>
            <tr>
              <th>Onboarding Date</th>
              <td>
                <input
                  type="date"
                  class="form-control"
                  name="onboardingDate"
                  max="9999-12-31"
                  v-model="module.onboardingDate"
                />
              </td>
            </tr>
            <tr>
              <th>Onboarding Location</th>
              <td>
                <select
                  name="onboardingLocation"
                  class="form-control"
                  v-model="module.onboardingLocation"
                >
                  <!-- v-if="!module.onboardingLocation" -->
                  <option
                    v-for="(oL, index) in onboardingLocations"
                    :key="index"
                    :value="oL"
                    >{{ oL }}</option
                  >
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

import FileUpload from "@/components/FileUpload";
import cloudinaryUpload from "../../utils/upload";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    view: String,
    permission: Boolean
  },
  components: {
    FileUpload
  },
  data() {
    return {
      onboardingLocations: ["Others", "LTFRB", "Office", "Online"],
      APP_URL: process.env.VUE_APP_APP_URL,
      tempFileData: null
    };
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    save({ value, type }, action) {
      if (action == "upload") {
        cloudinaryUpload(value, {
          folder: `operator-files/${this.module.id}`,
          publicId: type
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.tempFileData = this.module[type]
            this.module[type] = data.secureUrl;
            this.update(type);
          });
      } else {
        this.tempFileData = this.module[type]
        this.module[type] = value;
        this.update(type);
      }
    },
    update(type) {
      this.$store.dispatch("operator/editOperator", this.module)
        .then(() => {
          this.tempFileData = null;
        })
        .catch(() => {
          this.module[type] = this.tempFileData;
        });
    }
  },
  computed: {
    ...mapState("id", ["ids"]),
    ...mapState("user", ["user", "token"])
  },
  created() {
    this.$store.dispatch("id/getIds");
  },
  mounted() {
    $("#requirements :input").prop("disabled", !this.permission);
    // jquery file upload library initialization
    // $("#fileupload").fileupload({
    //   dataType: "json",
    //   done: function(e, data) {
    //     $.each(data.result.files, function(index, file) {
    //       $("<p/>")
    //         .text(file.name)
    //         .appendTo(document.body);
    //     });
    //   }
    // });
  }
};
</script>

<style>
#img-preview {
  width: 65px;
  height: 20px;
}

.table-settings td.column-preview {
  border-top: 1px solid #ddd !important;
  background: #f9f9f9;
}
</style>
