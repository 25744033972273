<template>
  <div class="content-wrapper">
    <div>
      <section class="content-header">
        <a @click.prevent="routeBack">
          <i class="fa fa-caret-left"></i>
          Back
        </a>
        <h1>Partner</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form id="schedule_form" method="POST" @submit.prevent>
            <div class="row">
              <div class="col-md-3">
                <ProfileBox view="Partner" :id="id" :module="driver" />
              </div>

              <div class="col-md-9">
                <ActivationChecklist view="Partner" :id="id" :module="driver" />

                <PartnerTab :id="id" />

                <Note view="Partner" :id="id" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

    <div
      class="modal fade"
      id="enrollToOperatorModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Enroll Operator</h4>
          </div>
          <div class="modal-body">
            <table
              id="operator_enroll_table"
              :data-partner-id="id"
              class="table table-hover"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="callDriverModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="callDriverModal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">Call Driver</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-6" id="callDriver">
                <div class="embed-responsive embed-responsive-16by9">
                  <video
                    id="main_video"
                    class="embed-responsive-item"
                    autoplay
                    playsinline
                  ></video>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="embed-responsive embed-responsive-16by9">
                  <video
                    id="remote_video"
                    class="embed-responsive-item"
                    autoplay
                    playsinline
                  ></video>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivationChecklist from "@/components/ActivationChecklist.vue";
import ProfileBox from "@/components/ProfileBox.vue";
import PartnerTab from "@/components/PartnerTab.vue";
import Note from "@/components/Note.vue";
import { mapState } from "vuex";
import store from "@/store/store";

let lastRoute = {
  name: null,
  fullPath: null
};
export default {
  components: {
    ActivationChecklist,
    ProfileBox,
    PartnerTab,
    Note
  },
  data() {
    return {
      dataTable: null,
      isSubmitting: false,
      isRouting: false
    };
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    lastRoute.name = routeFrom.name;
    lastRoute.fullPath = routeFrom.fullPath;
    console.log("loading...");
    store.dispatch("driver/getTransactionDescriptions", routeTo.params.id);
    store.dispatch("fleet/setFleetsByPartner", routeTo.params.id);
    store.dispatch("balance/setTopUp", routeTo.params.id);
    store.dispatch("driver/getDriverTags", routeTo.params.id);
    store.dispatch("tag/getTags");
    store.dispatch("driver/getDriverStatus", routeTo.params.id);
    store.dispatch("gcash/getGcashCards");
    store.dispatch("driver/getDriverOperators", routeTo.params.id);
    store
      .dispatch("driver/getDriver", routeTo.params.id)
      .then(response => {
        return store.dispatch("region/getRegions").then(() => {
          if (!response.state) return next();
          return store
            .dispatch(
              "province/getProvincesByRegion",
              response.state.slice(0, 2)
            )
            .then(() => {
              if (!response.province) return next();
              return store
                .dispatch(
                  "city/getCitiesByProvince",
                  response.province.slice(0, 4)
                )
                .then(() => {
                  if (!response.city) return next();
                  return store
                    .dispatch(
                      "barangay/getBarangaysByCity",
                      response.city.slice(0, 6)
                    )
                    .then(() => {
                      next();
                      console.log("done loading...");
                    });
                });
            });
        });
      })
      .catch(() => {
        if (navigator.onLine) {
          next({ name: "not-found" });
        } else {
          next(false);
        }
      });
  },
  methods: {
    routeBack() {
      if (this.isRouting) return;
      this.isRouting = true;

      if (lastRoute.name) {
        if (this.canBack) {
          this.$router.go(-1);
          if (!navigator.onLine) {
            this.isRouting = false;
          } else {
            this.$store.dispatch("cannotBack");
          }
        } else {
          this.$router.replace({ name: "partners" });
        }
      } else {
        this.$router.replace({ name: "partners" });
      }
    },
    setupDtTable(dataTable, url) {
      let dtColumns = ["lastName", ""];
      const vm = this;

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };

            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],
        // createdRow: ( row, data, dataIndex ) => {
        //   $(row).find('td:eq(0)')
        //     .attr('data-schedule-id', data.id);
        // },
        columns: [
          // {
          //   className:      'details-control text-center',
          //   orderable:      false,
          //   data:           null,
          //   defaultContent: ''
          // },
          { data: "lastName" },
          { data: null }
        ],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `${row.lastName}, ${row.firstName} ${row.middleName ||
                ""}`;
            }
          },
          {
            targets: [1],
            width: "210px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let isSelected = vm.driverOperators.find(dO => dO.id === row.id)
                ? true
                : false;
              let icon = isSelected ? "fa-check" : "fa-ban";
              let btn = isSelected ? "btn-success" : "btn-primary";

              button += `<button class="btn ${btn}"
                              type="button"
                              data-is-selected="${isSelected}"
                              >
                            <i class="fa ${icon}"
                               aria-hidden="true"
                               style="pointer-events: none">
                            </i>
                          </button>`;
              return button;
            }
          }
        ]
      });

      $("#operator_enroll_table").on("click", "[data-is-selected]", e => {
        if (vm.isSubmitting) return;
        let { isSelected } = e.target.dataset;
        let tr = $(e.target).closest("tr");
        let row = vm.dataTable.DataTable().row(tr);
        isSelected = JSON.parse(isSelected);
        let confirmed = confirm("Are you sure?");
        if (!confirmed) return;
        vm.isSubmitting = true;
        if (!isSelected) {
          store
            .dispatch("driver/assignDriverOperator", {
              driverId: this.id,
              operator: row.data()
            })
            .then(() => {
              $("#partner_vehicle_table")
                .DataTable()
                .ajax.reload(() => {
                  $("#partner_operator_table")
                    .DataTable()
                    .ajax.reload(() => {
                      vm.dataTable.DataTable().ajax.reload(() => {
                        vm.isSubmitting = false;
                      }, false);
                    });
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        } else {
          store
            .dispatch("driver/removeDriverOperator", {
              driverId: this.id,
              operatorId: row.data().id
            })
            .then(() => {
              $("#partner_vehicle_table")
                .DataTable()
                .ajax.reload(() => {
                  $("#partner_operator_table")
                    .DataTable()
                    .ajax.reload(() => {
                      vm.dataTable.DataTable().ajax.reload(() => {
                        vm.isSubmitting = false;
                      }, false);
                    });
                });
            })
            .catch(() => {
              vm.isSubmitting = false;
            });
        }
      });
    }
  },
  mounted() {
    this.dataTable = $("#operator_enroll_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/operators`;
    this.setupDtTable(this.dataTable, url);
  },
  computed: {
    ...mapState("user", ["user", "token"]),
    ...mapState("driver", ["driver", "driverOperators"]),
    ...mapState(["canBack"])
  }
};
</script>

<style></style>
