import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import abilitiesPlugin from "./authentication/ability-plugin.js";

// Theme style
import "./assets/css/owto-command-centre.css";
import "./assets/css/AdminLTE.min.css";
import "./assets/css/_all-skins.min.css";

//import "./assets/css/bootstrap.min.css";
//import "~/node_modules/bootstrap/dist/bootstrap.css";

import Lightbox from "vue-easy-lightbox";
Vue.use(Lightbox);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

import VModal from 'vue-js-modal';
Vue.use(VModal);

Vue.config.productionTip = false;
router.options.ini().then(ability => {
  Vue.use(abilitiesPlugin, ability);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
