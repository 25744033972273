import { AbilityBuilder } from "casl";
const entities = [
  "Operator",
  "OperatorVehicle",
  "OperatorDriver",
  "OperatorPrangkisa",
  "Partner",
  "PartnerOperator",
  "PartnerVehicle",
  "PartnerTripHistory",
  "PartnerLocationHistory",
  "PartnerCard",
  "Rider",
  "RiderEmployer",
  "RiderTripHistory",
  "RiderCard",
  "Route",
  "Schedule",
  "VehicleModel",
  "Tag",
  "Variant",
  "Poi",
  "Fleet",
  "TopUp",
  "WalletTransaction",
  "Employer",
  "Disbursement",
  "Livemap"
];

export default function(role, entity) {
  let myEntities = [];
  if (entity.length) {
    let len = entity.length;
    for (let i = 0; i < len; i++) {
      myEntities.push(entities.find(e => e === entity[i]));
    }
  } else {
    myEntities = [""];
  }
  console.log(myEntities);
  console.log(role);
  return AbilityBuilder.define((can, cannot) => {
    switch (role) {
      case "ADMIN":
        can("manage", "all");
        break;
      case "LEVEL_1":
        can("read", myEntities);
        break;
      case "LEVEL_2":
        can(["read", "create", "update"], myEntities);
        break;
      case "LEVEL_3":
        can("manage", myEntities);
        break;
    }
  });
}
