import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getVariants(token) {
    return apiClient.get("/api/variants", {
      headers: {
        Authorization: token
      }
    });
  },

  getVariant(token, id) {
    return apiClient.get("/api/variants/" + id, {
      headers: {
        Authorization: token
      }
    });
  },

  addVariant(token, variant) {
    return apiClient.post("/api/variants", variant, {
      headers: {
        Authorization: token
      }
    });
  },

  editVariant(token, id, variant) {
    return apiClient.patch("/api/variants/" + id, variant, {
      headers: {
        Authorization: token
      }
    });
  }
};
