<template>
  <div class="tab-pane" id="accounts">
    <fieldset>
      <legend>Other Details</legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>Discounts</th>
            <td>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="module.entitlements"
                    name="gov_discount"
                    :value="govDiscount"
                  />
                  Government Discount
                </label>
              </div>
              <small class="form-text text-muted"
                >Apply for 20% government discount.</small
              >
            </td>
          </tr>
          <tr>
            <th>Verification Tier</th>
            <td>
              <input
                type="number"
                class="form-control"
                name="verificationTier"
                v-model.number="module.verificationTier"
              />
            </td>
          </tr>
          <tr>
            <th>Remarks</th>
            <td>
              <textarea
                rows="5"
                class="form-control"
                v-model.trim="module.remarks"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    permission: Boolean
  },
  data() {
    return {
      govDiscount: {
        type: "GOV_DISCOUNT",
        value: 20
      }
    };
  },
  mounted() {
    $("#accounts :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
