var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-tabs-custom"},[_vm._m(0),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"active tab-pane",attrs:{"id":"notes"}},[_c('div',{staticClass:"form-group",attrs:{"id":"notes_form"}},[_c('input',{attrs:{"type":"hidden","name":"userId"},domProps:{"value":_vm.id}}),_c('input',{attrs:{"type":"hidden","name":"entity"},domProps:{"value":_vm.view}}),_c('table',{staticClass:"table table-hover",attrs:{"id":"notes_table","width":"100%","data-user-id":_vm.id,"data-entity":_vm.view}},[_vm._m(1)]),_c('fieldset',{staticClass:"form-group"},[(
              _vm.$can('create', 'Operator') &&
                _vm.$can('create', 'Partner') &&
                _vm.$can('create', 'Rider')
            )?_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.note.note),expression:"note.note",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"name":"note","placeholder":"Note here..."},domProps:{"value":(_vm.note.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.note, "note", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e()]),(
            _vm.$can('create', 'Operator') &&
              _vm.$can('create', 'Partner') &&
              _vm.$can('create', 'Rider')
          )?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"for":"notes_table","type":"button"},on:{"click":_vm.addNote}},[_vm._v(" Submit ")]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"active"},[_c('a',{attrs:{"data-toggle":"tab"}},[_vm._v("Notes")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Note")]),_c('th',[_vm._v("Date")])])])}]

export { render, staticRenderFns }