import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BE_API_URL,
  withCredentials: false, // This is the default
  headers: {
    Authorization: ""
  }
});

export default {
  getCards(token, entity, entityId) {
    // get cards
    return apiClient.get(`/api/${entity}/${entityId}/cards`, {
      headers: {
        Authorization: token
      }
    });
  },

  removeCard(token, entity, entityId, cardId) {
    // delete card
    return apiClient.delete(`/api/${entity}/${entityId}/cards/${cardId}`, {
      headers: {
        Authorization: token
      }
    });
  }
};
