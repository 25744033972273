<template>
  <div>
    <div class="content-wrapper">
      <section class="content-header">
        <router-link :to="{ name: 'tags' }">
          <i class="fa fa-caret-left"></i>
          Back
        </router-link>
        <h1>Tag</h1>
      </section>

      <section class="content">
        <div class="container-fluid">
          <form id="tags_form" method="POST">
            <div class="row">
              <div class="col-md-6">
                <fieldset>
                  <legend>Details</legend>
                  <table class="table table-settings">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="tag.name"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>
                          <textarea
                            rows="5"
                            class="form-control"
                            name="description"
                            v-model="tag.description"
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
              <div class="col-md-6">
                <fieldset>
                  <legend>Area Details</legend>
                  <table class="table table-settings">
                    <tbody>
                      <tr>
                        <th>Center</th>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="center"
                            v-model="tag.shadow.center.lng"
                          />
                          <!-- v-model --- tag.shadow.center.lat -->
                        </td>
                      </tr>
                      <tr>
                        <th>Radius</th>
                        <td>
                          <div class="input-group">
                            <input
                              type="number"
                              class="form-control"
                              name="radius"
                              v-model="tag.shadow.radius"
                            />
                            <!-- v-model="tag.shadow.radius / 1000" -->
                            <span class="input-group-addon">Km.</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>

            <div class="form-group">
              <div id="pickUpMap" style="width: 100%;height:500px;"></div>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>

          <div class="row">
            <div class="col-md-12">
              <div class="box box-primary">
                <div class="box-header">
                  Drivers
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#enrollDriverModal"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="box-body">
                  <table
                    id="tag_driver_table"
                    :data-tag-id="tag.id"
                    class="table table-hover"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Driver</th>
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="enrollDriverModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title" id="myModalLabel">Enroll Driver</h4>
                </div>
                <div class="modal-body">
                  <table
                    id="tags_partners_enroll_table"
                    :data-tag-id="tag.id"
                    class="table table-hover"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tag: {
        id: "10922fba-538b-4938-b7ef-9136970771c5",
        name: "SM Fairview",
        description: "",
        shadow: {
          center: {
            lat: "14.731171977853421",
            lng: "121.0457560764047"
          },
          radius: 2000
        }
      }
    };
  }
};
</script>

<style></style>
