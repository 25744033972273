<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        Routes
        <router-link
          :to="{ name: 'routes-new' }"
          v-if="$can('create', 'Route')"
          class="btn btn-primary btn-sm"
          >Add New</router-link
        >
      </h1>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="box box-primary">
          <div class="box-body">
            <table id="routes_table" class="table table-hover" width="100%">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataTable: null,
      isSubmitting: false
    };
  },
  methods: {
    setupDtTable(dataTable, url) {
      let dtColumns = ["name", "isActive", ""];
      var vm = this; // passing this

      $(dataTable).DataTable({
        searching: true,
        processing: true,
        serverSide: true,
        ajax: {
          url: url,
          data: function(d) {
            d.filter = {
              limit: d.length,
              skip: d.start,
              order:
                dtColumns[d.order[0]["column"]] +
                ` ${d.order[0]["dir"].toUpperCase()}`, // propertyName ASC|DESC
              where: {
                q: d.search.value
              }
            };
            d.access_token = vm.token;
          },
          dataFilter: function(response) {
            response = JSON.parse(response);

            let formatted = {
              recordsTotal: response.total,
              recordsFiltered: response.total,
              data: response.data
            };

            return JSON.stringify(formatted);
          }
        },
        order: [[0, "asc"]],

        columns: [{ data: "variantName" }, { data: null }],
        columnDefs: [
          {
            targets: [0],
            orderable: true,
            render: (type, data, row, meta) => {
              return `<p>${row.name}</p>
                <p class="small text-muted">${row.description}</p>`;
            }
          },
          {
            targets: [1],
            width: "100px",
            orderable: true,
            render: (type, data, row, meta) => {
              let isActive = row.isActive ? "text-success" : "text-danger";
              return `<i class="fa fa-circle ${isActive}" aria-hidden="true"></i>`;
            }
          },
          {
            targets: [2],
            width: "140px",
            orderable: false,
            render: (type, data, row, meta) => {
              let button = "";
              let visibility = vm.$can("update", "Route")
                ? "visible"
                : "hidden";
              let dVisibility = vm.$can("delete", "Route")
                ? "visible"
                : "hidden";

              button += `<a class="btn btn-primary ${visibility}"
                            data-route-edit-id="${row.id}">
                           <i class="fa fa-pencil-square-o fa-fw"
                              style="pointer-events: none"
                              aria-hidden="true">
                           </i>
                         </a>
                         
                         `;

              button += `<a class="btn btn-primary ${dVisibility}"
                            data-route-delete-id="${row.id}">
                           <i class="fa fa-trash fa-fw"
                              style="pointer-events: none"
                              aria-hidden="true">
                           </i>
                         </a>`;

              return button;
            }
          }
        ]
      });

      $("#routes_table").on("click", "[data-route-edit-id]", e => {
        if (vm.isSubmitting) return;
        vm.isSubmitting = true;
        let { routeEditId } = e.target.dataset;
        vm.$router.push(
          {
            name: "routes-edit",
            params: { id: routeEditId }
          },
          null,
          () => {
            vm.isSubmitting = false;
          }
        );
        e.preventDefault();
      });

      $("#routes_table").on("click", "[data-route-delete-id]", e => {
        if (vm.isSubmitting) return;
        let { routeDeleteId } = e.target.dataset;
        let answer = confirm("Are you sure?");
        if (!answer) return;
        vm.isSubmitting = true;
        vm.$store
          .dispatch("route/deleteRoute", routeDeleteId)
          .then(() => {
            $(dataTable)
              .DataTable()
              .ajax.reload(() => {
                vm.isSubmitting = false;
              });
          })
          .catch(() => {
            vm.isSubmitting = false;
          });
      });
    }
  },
  mounted() {
    this.dataTable = $("#routes_table");
    const url = `${process.env.VUE_APP_BE_API_URL}/api/routes`;
    this.setupDtTable(this.dataTable, url);
  }
};
</script>

<style></style>
