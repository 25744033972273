<template>
  <div class="tab-pane" id="profile">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li
          v-for="(wTab, index) in walletTabs"
          :key="index"
          @click="selectedWalletTab = wTab[0]"
          :class="{ active: selectedWalletTab === wTab[0] }"
          data-toggle="tab"
        >
          <a v-if="$can('read', wTab[1])">{{ wTab[0] }}</a>
        </li>
      </ul>

      <div class="tab-content">
        <TopUp :class="{ active: selectedWalletTab == 'TopUp' }" :id="id" />

        <Transaction
          :class="{ active: selectedWalletTab == 'Transaction' }"
          :id="id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TopUp from "./TopUp.vue";
import Transaction from "./Transaction.vue";
import { mapState } from "vuex";

export default {
  components: {
    TopUp,
    Transaction
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      walletTabs: [["TopUp", "TopUp"], ["Transaction", "WalletTransaction"]],
      selectedWalletTab: "TopUp"
    };
  },
  computed: {
    ...mapState("user", ["users", "user"])
  },
  created() {
    this.selectedWalletTab = this.user.entities.includes("TopUp")
      ? "TopUp"
      : "Transaction";
  }
};
</script>
