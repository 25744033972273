import TagService from "@/services/TagService.js";

export const namespaced = true;

export const state = {
  tags: [],
  tag: null,
  tagDrivers: []
};

export const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
  ADD_TAG(state, tag) {
    state.tags.push(tag);
  },
  SET_TAG(state, tag) {
    state.tag = tag;
  },
  EDIT_TAG(state, tag) {
    state.tags.forEach((t, i) => {
      if (t.id === tag.id) {
        state.tags[i] = { ...tag };
      }
    });
  },
  DELETE_TAG(state, tagId) {
    state.tags = state.tags.filter(t => {
      return t.id === tagId;
    });
  },
  SET_TAG_DRIVERS(state, tagDrivers) {
    state.tagDrivers = tagDrivers;
  },
  ADD_TAG_DRIVER(state, tagDriver) {
    state.tagDrivers.push(tagDriver);
  },
  REMOVE_TAG_DRIVER(state, tagDriver) {
    state.tagDrivers = state.tagDrivers.filter(tD => {
      return tD.userId !== tagDriver;
    });
  }
};

export const actions = {
  getTags({ commit, rootState, dispatch }) {
    return TagService.getTags(rootState.user.token)
      .then(response => {
        commit("SET_TAGS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addTag({ commit, rootState, dispatch }, tag) {
    return TagService.addTag(rootState.user.token, tag)
      .then(response => {
        commit("ADD_TAG", response.data);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getTag({ commit, rootState, dispatch }, id) {
    return TagService.getTag(rootState.user.token, id)
      .then(response => {
        commit("SET_TAG", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  editTag({ commit, rootState, dispatch }, tag) {
    return TagService.editTag(rootState.user.token, tag.id, tag)
      .then(response => {
        commit("EDIT_TAG", response.data);
        const notification = {
          type: "success",
          message: "Edit Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  deleteTag({ commit, rootState, dispatch }, id) {
    return TagService.deleteTag(rootState.user.token, id)
      .then(() => {
        commit("DELETE_TAG", id);
        const notification = {
          type: "success",
          message: "Delete Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getTagDrivers({ commit, rootState, dispatch }, id) {
    return TagService.getTagDrivers(rootState.user.token, id)
      .then(response => {
        commit("SET_TAG_DRIVERS", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addTagDriver({ commit, rootState, dispatch }, { id, driverId }) {
    return TagService.addTagDriver(rootState.user.token, id, driverId)
      .then(response => {
        let getTagDriver = rootState.driver.drivers.find(driver => {
          return driver.userId === response.data.driverId;
        });
        commit("ADD_TAG_DRIVER", getTagDriver);
        const notification = {
          type: "success",
          message: "Add Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  removeTagDriver({ commit, rootState, dispatch }, { id, driverId }) {
    return TagService.removeTagDriver(rootState.user.token, id, driverId)
      .then(response => {
        commit("REMOVE_TAG_DRIVER", driverId);
        const notification = {
          type: "success",
          message: "Remove Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Response Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
