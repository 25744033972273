import BalanceService from "@/services/BalanceService.js";

export const namespaced = true;

export const state = {
  topUp: {}
};

export const mutations = {
  SET_TOPUP(state, topUp) {
    state.topUp = topUp;
  },
  ADD_TOPUP(state, topUp) {
    state.topUp.balance = state.topUp.balance + topUp;
  }
};

export const actions = {
  setTopUp({ commit, rootState }, driverId) {
    return BalanceService.getTopUp(rootState.user.token, driverId)
      .then(response => {
        commit("SET_TOPUP", response.data);
      })
      .catch(error => console.log(error));
  },

  addTopUp({ commit, rootState, dispatch }, { id, topup, note }) {
    return BalanceService.addTopUp(rootState.user.token, id, topup)
      .then(response => {
        // commit("ADD_TOPUP", response.data.amount);
        const notification = {
          type: "success",
          message: "Added Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
        return dispatch("note/addNote", note, { root: true }).then(response => {
          dispatch("setTopUp", id).then(() => {});
        });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: `Added Failed, ${error.message}`
        };
        dispatch("notification/add", notification, {
          root: true
        });
        throw error;
      });
  }
};
