import VehicleModelService from "@/services/VehicleModelService.js";

export const namespaced = true;

export const state = {
  vehicleModel: null,
  vehicleModels: []
};

export const mutations = {
  SET_VEHICLE_MODELS(state, vehicleModels) {
    state.vehicleModels = vehicleModels;
  },
  SET_VEHICLE_MODEL(state, vehicleModel) {
    state.vehicleModel = vehicleModel;
  },
  EDIT_VEHICLE_MODEL(state, vehicleModel) {
    state.vehicleModel = { ...state.vehicleModel, ...vehicleModel };
  },
  ADD_VEHICLE_MODEL(state, vehicleModel) {
    state.vehicleModels.push(vehicleModel);
  }
};

export const actions = {
  getVehicleModel({ commit, rootState, dispatch }, id) {
    return VehicleModelService.getVehicleModel(rootState.user.token, id)
      .then(response => {
        commit("SET_VEHICLE_MODEL", response.data);
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Fetching Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  getVehicleModels({ commit, rootState }) {
    VehicleModelService.getVehicleModels(rootState.user.token)
      .then(response => {
        commit("SET_VEHICLE_MODELS", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  editVehicleModel({ commit, rootState, dispatch }, vehicleModel) {
    return VehicleModelService.editVehicleModel(
      rootState.user.token,
      vehicleModel.id,
      vehicleModel
    )
      .then(response => {
        commit("EDIT_VEHICLE_MODEL", response.data);
        const notification = {
          type: "success",
          message: "Edited Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Edit Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },

  addVehicleModel({ commit, rootState, dispatch }, vehicleModel) {
    return VehicleModelService.addVehicleModel(
      rootState.user.token,
      vehicleModel
    )
      .then(response => {
        commit("ADD_VEHICLE_MODEL", response.data);
        const notification = {
          type: "success",
          message: "Created Succesfully"
        };
        dispatch("notification/add", notification, { root: true });
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "Creation Failed, "
        };
        if (error.request.response) {
          let message = JSON.parse(error.request.response).error.message;
          notification.message += message;
        } else {
          notification.message += error.message;
        }
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  }
};
