<template>
  <div class="tab-pane" id="files">
    <fieldset>
      <legend></legend>
      <table class="table table-settings">
        <tbody>
          <tr>
            <th>License ID</th>
            <td>
              <FileUpload
                :link="module.licenseId"
                type="licenseId"
                @save="save"
              />
            </td>
          </tr>
          <tr>
            <th>NBI Clearance</th>
            <td>
              <FileUpload
                :link="module.nbiClearance"
                type="nbiClearance"
                @save="save"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>

<script>
import { mapState } from "vuex";

import FileUpload from "@/components/FileUpload";
import cloudinaryUpload from "../../utils/upload";

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    permission: Boolean
  },
  components: {
    FileUpload
  },
  data() {
    return {
      tempFileData: []
    }
  },
  methods: {
    save({ value, type }, action) {
      if (action == "upload") {
        cloudinaryUpload(value, {
          folder: `driver-files/${this.module.userId}`,
          publicId: type
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.tempFileData = { ...this.tempFileData, [type]: this.module[type] };
            this.module[type] = data.secureUrl;
            this.update(type);
          });
      } else {
        this.tempFileData = { ...this.tempFileData, [type]: this.module[type] };
        this.module[type] = value;
        this.update(type);
      }
    },
    update(type) {
      this.$store.dispatch("driver/editDriver", this.module)
        .then(() => {
          this.tempFileData = [];
        })
        .catch(() => {
          this.module[type] = this.tempFileData[type];
        });
    }
  },
  computed: {
    // ...mapState({ partner: state => state.driver.driver }),
    ...mapState(["BE_API_URL"]),
    ...mapState("user", ["user", "token"])
  },
  mounted() {
    $("#files :input").prop("disabled", !this.permission);
  }
};
</script>

<style></style>
